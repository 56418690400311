import React from 'react';
import { Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { ErrorOutline } from '@mui/icons-material';

export const ErrorPanel = ({ message, error }: { message: String; error?: any }) => {
  return (
    <Typography variant="h6" gutterBottom align="center">
      <ErrorOutline style={{ color: red[500], marginRight: '0.5rem', verticalAlign: 'middle' }} />
      {message}
      <br />
      <code>{error && JSON.stringify(error)}</code>
    </Typography>
  );
};
