import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import AppointmentWidget from '../patient/AppointmentWidget';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Chip, makeStyles, Typography } from '@mui/material';
import { Cancel, CloudDownloadRounded, Done } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  Maybe,
  PatientAppointment,
  ReminderPayLoad,
  useCancelAppointmentMutation,
} from '../../generated/graphql';
import PatientDetails from './PatientDetails';
import { Alert, AlertTitle } from '@mui/lab';
import CommonUtils from '../../util/CommonUtils';

export default function AppointmentDetailModal({
  onHide,
  appointment,
  refreshAppointments,
}: {
  onHide: Function;
  appointment?: PatientAppointment;
  refreshAppointments?: Function;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [cancelAppointment] = useCancelAppointmentMutation();
  const { enqueueSnackbar } = useSnackbar();

  if (!appointment) {
    return null;
  }

  const handleClose = () => {
    onHide();
  };

  const { patient, reminders, patientAppointmentResponse, responseReason, status, covidScreening } =
    appointment;

  let remindersGrid = null;

  if (reminders?.length) {
    const columns: GridColDef[] = [
      { field: 'patientAppointmentId', headerName: 'Appointment Id', hide: true, flex: 1 },
      { field: 'sentDate', headerName: 'Date/Time', width: 175 },
      { field: 'panelName', headerName: 'Reminder Name', flex: 1 },
      { field: 'deliveryState', headerName: 'Delivery Status', width: 200 },
      { field: 'channel', headerName: 'Email/SMS', flex: 1 },
    ];
    let rows =
      reminders?.map((r: Maybe<ReminderPayLoad>) => {
        const { id, sentDate, panelName, deliveryState, channel, patientAppointmentId } = r!;
        return {
          id,
          patientAppointmentId,
          sentDate: sentDate && format(new Date(sentDate), 'yyyy-MM-dd HH:mm a'),
          panelName,
          deliveryState,
          channel,
        };
      }) || [];
    remindersGrid = (
      <>
        <br />
        <br />
        <Typography variant="h5" gutterBottom>
          Reminders sent:
        </Typography>
        <DataGrid rows={rows} columns={columns} hideFooterPagination hideFooter autoHeight />
      </>
    );
  }

  let patientResponse = null;
  if (patientAppointmentResponse) {
    if (patientAppointmentResponse === 'CONFIRMED') {
      patientResponse = (
        <Chip sx={{ mr: '5px' }} color="primary" label="Patient has Confirmed" icon={<Done />} />
      );
    } else if (patientAppointmentResponse === 'DECLINED') {
      patientResponse = (
        <Chip sx={{ mr: '5px' }} color="secondary" label="Patient has Declined" icon={<Cancel />} />
      );
    }
  }

  const emrStatus = status && <Chip sx={{ mr: '5px' }} label={`EMR Status: ${status}`} />;

  let patientDetails = <PatientDetails patient={patient} />;

  let covidScreningResult = null;

  let canNotify = null;

  if (!patient?.email && !patient?.sms) {
    canNotify = <Chip sx={{ mr: '5px' }} color={'secondary'} label={`Cannot Notify`} />;
  }

  if (covidScreening?.form) {
    const { result, reportUrl, submissionDate } = covidScreening?.form;
    if (result) {
      covidScreningResult = (
        <Chip
          color={Number(result) === 0 ? 'primary' : 'secondary'}
          sx={{ mr: '5px' }}
          label={`Covid Screening: ${Number(result) === 0 ? 'Passed' : 'Failed'} - ${format(
            new Date(submissionDate),
            'yyyy-MM-dd',
          )}`}
          onDelete={() => CommonUtils.downloadUrl(reportUrl!)}
          deleteIcon={<CloudDownloadRounded />}
        />
      );
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="lg"
        open={!!appointment}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Box display="flex">
            <Box mr={1}>
              {patient?.firstName} {patient?.lastName}
            </Box>
            {emrStatus}
            {patientResponse}
            {covidScreningResult}
            {canNotify}
          </Box>
          {patientDetails}
        </DialogTitle>

        <DialogContent>
          {responseReason && patientAppointmentResponse === 'DECLINED' && (
            <Box mb={2}>
              <Alert severity="info">
                <AlertTitle>Patient&apos;s Response :</AlertTitle>
                {responseReason}
              </Alert>
            </Box>
          )}
          <AppointmentWidget appointment={appointment} />
          {remindersGrid}
        </DialogContent>
        <DialogActions>
          {patientAppointmentResponse === 'DECLINED' && status !== 'Cancelled' && (
            <Button
              autoFocus
              onClick={() => {
                cancelAppointment({
                  variables: {
                    id: appointment.id!,
                  },
                })
                  .then(() => {
                    refreshAppointments && refreshAppointments();
                    handleClose();
                  })
                  .catch(() => {
                    enqueueSnackbar(
                      'There was an error while submitting response, please try again or contact support.',
                      {
                        variant: 'error',
                      },
                    );
                  });
              }}
              variant="contained"
              color="primary"
            >
              Cancel Appointment in EMR
            </Button>
          )}
          <Button autoFocus onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
