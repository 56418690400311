import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};

export type ApplicationDateTime = {
  __typename?: 'ApplicationDateTime';
  date?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
};

export type AppointmentPanel = {
  __typename?: 'AppointmentPanel';
  appointmentConfirmation?: Maybe<Scalars['Boolean']>;
  appointmentSource?: Maybe<AppointmentSource>;
  appointmentTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachments?: Maybe<Array<Attachment>>;
  confirmEmailMessageTemplate?: Maybe<MessageTemplate>;
  confirmSmsMessageTemplate?: Maybe<MessageTemplate>;
  covidScreening?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailMessageTemplate?: Maybe<MessageTemplate>;
  id?: Maybe<Scalars['ID']>;
  immediateReminder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationPriority?: Maybe<NotificationChannel>;
  providerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  reminderInDays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  securedMessageTemplate?: Maybe<MessageTemplate>;
  smsMessageTemplate?: Maybe<MessageTemplate>;
  state?: Maybe<AppointmentPanelState>;
};

export enum AppointmentPanelState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AppointmentSource {
  Emd = 'EMD',
  TelusEmr = 'TELUS_EMR',
}

export enum AppointmentState {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
}

export type AppointmentStatus = {
  __typename?: 'AppointmentStatus';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  descriptor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  description?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type AttachmentInput = {
  description?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type AttachmentLink = {
  __typename?: 'AttachmentLink';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type AuditTrail = {
  __typename?: 'AuditTrail';
  createdAt?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type BillingPeriodPayLoad = {
  __typename?: 'BillingPeriodPayLoad';
  currentUsage?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  usageLimit?: Maybe<Scalars['Int']>;
};

export type BulkCampaign = {
  __typename?: 'BulkCampaign';
  attachments?: Maybe<Array<Attachment>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  formTemplates?: Maybe<Array<Maybe<FormTemplate>>>;
  hasSecuredContent?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  patientList?: Maybe<PanelImport>;
  scheduledExecution?: Maybe<Scalars['DateTime']>;
  securedContent?: Maybe<Scalars['String']>;
  securedContentSubject?: Maybe<Scalars['String']>;
  smsContent?: Maybe<Scalars['String']>;
  status?: Maybe<BulkCampaignStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BulkCampaignFooterTemplatePayload = {
  __typename?: 'BulkCampaignFooterTemplatePayload';
  emailFooterContent?: Maybe<Scalars['String']>;
  emailLinksFooterContent?: Maybe<Scalars['String']>;
  smsLinksFooterContent?: Maybe<Scalars['String']>;
};

export type BulkCampaignInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description: Scalars['String'];
  emailContent: Scalars['String'];
  emailSubject: Scalars['String'];
  formTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hasSecuredContent: Scalars['Boolean'];
  name: Scalars['String'];
  patientListId?: InputMaybe<Scalars['ID']>;
  scheduledExecution?: InputMaybe<Scalars['DateTime']>;
  securedContent?: InputMaybe<Scalars['String']>;
  securedContentSubject?: InputMaybe<Scalars['String']>;
  smsContent: Scalars['String'];
};

export type BulkCampaignPaymentInvoice = {
  __typename?: 'BulkCampaignPaymentInvoice';
  invoiceUrl?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentTotal?: Maybe<Scalars['Int']>;
};

export enum BulkCampaignStatus {
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS',
  New = 'NEW',
  Scheduled = 'SCHEDULED',
}

export type BulkCampaignTemplate = {
  __typename?: 'BulkCampaignTemplate';
  attachments?: Maybe<Array<TemplateAttachment>>;
  emailContent?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  hasSecuredContent?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  securedContent?: Maybe<Scalars['String']>;
  securedContentSubject?: Maybe<Scalars['String']>;
  smsContent?: Maybe<Scalars['String']>;
  type?: Maybe<BulkCampaignType>;
};

export enum BulkCampaignType {
  HpvPrevention = 'HPV_PREVENTION',
  MentalHealth = 'MENTAL_HEALTH',
}

export type BulkCampaignValidationPayLoad = {
  __typename?: 'BulkCampaignValidationPayLoad';
  estimatedCost?: Maybe<Scalars['Float']>;
  patientsIgnored?: Maybe<Scalars['Int']>;
  patientsTargetted?: Maybe<Scalars['Int']>;
  patientsTotal?: Maybe<Scalars['Int']>;
};

export type BulkCampaignsPayLoad = {
  __typename?: 'BulkCampaignsPayLoad';
  bulkCampaigns?: Maybe<Array<Maybe<BulkCampaign>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BulkMessagingSummaryPayLoad = {
  __typename?: 'BulkMessagingSummaryPayLoad';
  countDeliveryFailed?: Maybe<Scalars['Int']>;
  countDeliverySuccess?: Maybe<Scalars['Int']>;
  countNotificationOpened?: Maybe<Scalars['Int']>;
  countSecuredContentOpened?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<BulkPatientNotificationMessage>>>;
};

export type BulkPatientExecution = {
  __typename?: 'BulkPatientExecution';
  attachments?: Maybe<Array<Attachment>>;
  content?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  patientForms?: Maybe<Array<Maybe<PatientForm>>>;
  sms?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
};

export type BulkPatientExecutionAccess = {
  __typename?: 'BulkPatientExecutionAccess';
  bulkPatientExecution?: Maybe<BulkPatientExecution>;
  clinic?: Maybe<Clinic>;
  id: Scalars['ID'];
  isLocked?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['DateTime']>;
};

export type BulkPatientImportsPayLoad = {
  __typename?: 'BulkPatientImportsPayLoad';
  patientImports?: Maybe<Array<Maybe<PatientImport>>>;
  totalPatientImports?: Maybe<Scalars['ID']>;
};

export type BulkPatientNotificationMessage = {
  __typename?: 'BulkPatientNotificationMessage';
  bulkPatientExecution?: Maybe<BulkPatientExecution>;
  deliveryState?: Maybe<NotificationMessageDeliveryState>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  notificationChannel?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  securedContent?: Maybe<SecuredContent>;
  sentDateTime?: Maybe<Scalars['DateTime']>;
  sms?: Maybe<Scalars['String']>;
};

export type BulkSecureMailContent = {
  __typename?: 'BulkSecureMailContent';
  openedAt?: Maybe<Scalars['DateTime']>;
  recipientId?: Maybe<Scalars['ID']>;
};

export type Clinic = {
  __typename?: 'Clinic';
  addresses?: Maybe<Array<Maybe<ClinicAddress>>>;
  appointmentSource?: Maybe<AppointmentSource>;
  emrInstance?: Maybe<EmrInstance>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  secureMailClinicId?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<ZoneId>;
};

export type ClinicAddress = {
  __typename?: 'ClinicAddress';
  address: Scalars['String'];
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ColumnImport = {
  __typename?: 'ColumnImport';
  columnAlias?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  columnPattern?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type ConfigurationPayLoad = {
  __typename?: 'ConfigurationPayLoad';
  emrDeclineAppointmentConfiguration?: Maybe<EmrDeclineAppointmentConfiguration>;
  providersPayLoad?: Maybe<ProvidersPayLoad>;
};

export type CreateAppointmentPanelInput = {
  appointmentConfirmation?: InputMaybe<Scalars['Boolean']>;
  appointmentSource?: InputMaybe<AppointmentSource>;
  appointmentTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  confirmEmailMessageTemplateId?: InputMaybe<Scalars['ID']>;
  confirmSmsMessageTemplateId?: InputMaybe<Scalars['ID']>;
  covidScreening?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emailMessageTemplateId?: InputMaybe<Scalars['ID']>;
  immediateReminder?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationPriority?: InputMaybe<NotificationChannel>;
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reminderInDays?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  securedMessageTemplateId?: InputMaybe<Scalars['ID']>;
  smsMessageTemplateId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<AppointmentPanelState>;
};

export type CreateAppointmentPanelPayLoad = {
  __typename?: 'CreateAppointmentPanelPayLoad';
  panel?: Maybe<AppointmentPanel>;
};

export type CreateMessageTemplateInput = {
  content?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MessageTemplateType>;
};

export type CreatePanelInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePanelPayLoad = {
  __typename?: 'CreatePanelPayLoad';
  panel?: Maybe<Panel>;
};

export type CreatePatientAppointmentInput = {
  appointmentDateTime?: InputMaybe<Scalars['DateTime']>;
  appointmentType?: InputMaybe<HpvAppointmentType>;
  clinicAddressId?: InputMaybe<Scalars['ID']>;
  patientExecutionUuid: Scalars['String'];
};

export type CreatePatientAppointmentPayLoad = {
  __typename?: 'CreatePatientAppointmentPayLoad';
  patientAppointment?: Maybe<PatientAppointment>;
};

export type CreatePatientFormInput = {
  content: Scalars['String'];
};

export type EmrDeclineAppointmentConfiguration = {
  __typename?: 'EmrDeclineAppointmentConfiguration';
  isPatientDeclineEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<EmrDeclineAppointmentType>;
};

export enum EmrDeclineAppointmentType {
  CancelInEmr = 'CANCEL_IN_EMR',
  NoCancel = 'NO_CANCEL',
  UpdateStateInEmr = 'UPDATE_STATE_IN_EMR',
}

export type EmrInstance = {
  __typename?: 'EmrInstance';
  instanceType?: Maybe<EmrInstanceType>;
};

export enum EmrInstanceType {
  Ma = 'MA',
  Pss = 'PSS',
}

export type FileImport = {
  __typename?: 'FileImport';
  columns?: Maybe<Array<Maybe<ColumnImport>>>;
  name?: Maybe<Scalars['String']>;
};

export type FileImportPayload = {
  __typename?: 'FileImportPayload';
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type FutureReminderPayLoad = {
  __typename?: 'FutureReminderPayLoad';
  channel?: Maybe<Scalars['String']>;
  panelName?: Maybe<Scalars['String']>;
  scheduledDate?: Maybe<ApplicationDateTime>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED',
}

export enum HpvAlertType {
  CommunicationFailed = 'COMMUNICATION_FAILED',
  ConsultationDeclined = 'CONSULTATION_DECLINED',
  ConsultationRequested = 'CONSULTATION_REQUESTED',
  Locked = 'LOCKED',
  NotConfirmed = 'NOT_CONFIRMED',
  PlanExpired = 'PLAN_EXPIRED',
  RequiresAttention = 'REQUIRES_ATTENTION',
}

export enum HpvAppointmentType {
  Consultation = 'CONSULTATION',
  HpvDose1 = 'HPV_DOSE1',
  HpvDose2 = 'HPV_DOSE2',
  HpvDose3 = 'HPV_DOSE3',
}

export enum HpvCancellationReason {
  NotInterested = 'NOT_INTERESTED',
  NotInClinic = 'NOT_IN_CLINIC',
  NotWithPhysician = 'NOT_WITH_PHYSICIAN',
  Other = 'OTHER',
  Vaccinated = 'VACCINATED',
}

export enum HpvEventType {
  Completed = 'COMPLETED',
  ConsultationConfirmed = 'CONSULTATION_CONFIRMED',
  ConsultationDeleted = 'CONSULTATION_DELETED',
  ConsultationRequested = 'CONSULTATION_REQUESTED',
  ConsultationRescheduled = 'CONSULTATION_RESCHEDULED',
  ConsultationScheduled = 'CONSULTATION_SCHEDULED',
  Expired = 'EXPIRED',
  HpvCancelledMoa = 'HPV_CANCELLED_MOA',
  HpvDeclined = 'HPV_DECLINED',
  HpvDose1Confirmed = 'HPV_DOSE1_CONFIRMED',
  HpvDose1Deleted = 'HPV_DOSE1_DELETED',
  HpvDose1Rescheduled = 'HPV_DOSE1_RESCHEDULED',
  HpvDose1Scheduled = 'HPV_DOSE1_SCHEDULED',
  HpvDose2Confirmed = 'HPV_DOSE2_CONFIRMED',
  HpvDose2Deleted = 'HPV_DOSE2_DELETED',
  HpvDose2Rescheduled = 'HPV_DOSE2_RESCHEDULED',
  HpvDose2Scheduled = 'HPV_DOSE2_SCHEDULED',
  HpvDose3Confirmed = 'HPV_DOSE3_CONFIRMED',
  HpvDose3Deleted = 'HPV_DOSE3_DELETED',
  HpvDose3Rescheduled = 'HPV_DOSE3_RESCHEDULED',
  HpvDose3Scheduled = 'HPV_DOSE3_SCHEDULED',
  HpvRxDeclined = 'HPV_RX_DECLINED',
  HpvRxPending = 'HPV_RX_PENDING',
  HpvRxProvided = 'HPV_RX_PROVIDED',
  QuestionnaireSent = 'QUESTIONNAIRE_SENT',
  Started = 'STARTED',
}

export type HpvVaccinationDose = {
  __typename?: 'HPVVaccinationDose';
  doseDate?: Maybe<Scalars['Date']>;
  doseType?: Maybe<HpvVaccinationDoseType>;
};

export type HpvVaccinationDoseInput = {
  doseDate?: InputMaybe<Scalars['Date']>;
  doseType?: InputMaybe<HpvVaccinationDoseType>;
};

export enum HpvVaccinationDoseType {
  Dose_1 = 'DOSE_1',
  Dose_2 = 'DOSE_2',
  Dose_3 = 'DOSE_3',
}

export type HpvVaccinationInfo = {
  __typename?: 'HPVVaccinationInfo';
  doses?: Maybe<Array<Maybe<HpvVaccinationDose>>>;
  patientResponse?: Maybe<HpvVaccinationInfoPatientResponse>;
  willNotCompleteReason?: Maybe<HpvVaccinationInfoWontCompleteReason>;
};

export enum HpvVaccinationInfoPatientResponse {
  Completed = 'COMPLETED',
  WillComplete = 'WILL_COMPLETE',
  WillNotComplete = 'WILL_NOT_COMPLETE',
}

export type HpvVaccinationInfoSecuredContent = {
  __typename?: 'HPVVaccinationInfoSecuredContent';
  clinic?: Maybe<Clinic>;
  hpvVaccinationInfo?: Maybe<HpvVaccinationInfo>;
  id: Scalars['ID'];
  patientExecution?: Maybe<PatientExecution>;
};

export enum HpvVaccinationInfoWontCompleteReason {
  Financial = 'FINANCIAL',
  Health = 'HEALTH',
  Personal = 'PERSONAL',
}

export type ImportPatientsPayLoad = {
  __typename?: 'ImportPatientsPayLoad';
  error?: Maybe<Scalars['String']>;
  panelImport?: Maybe<PanelImport>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePayLoad = {
  __typename?: 'KeyValuePayLoad';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  content?: Maybe<Scalars['String']>;
  global?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<MessageTemplateType>;
};

export enum MessageTemplateType {
  Email = 'EMAIL',
  SecureContent = 'SECURE_CONTENT',
  SecureMail = 'SECURE_MAIL',
  Sms = 'SMS',
}

export type Mutation = {
  __typename?: 'Mutation';
  addNoteToPatientExecution?: Maybe<Scalars['ID']>;
  addPatientImport?: Maybe<Scalars['ID']>;
  cancelAppointment?: Maybe<Scalars['ID']>;
  cancelBulkCampaignExecution?: Maybe<Scalars['ID']>;
  cancelPatientExecution?: Maybe<Scalars['ID']>;
  confirmHPVAppointment?: Maybe<Scalars['ID']>;
  copyBulkCampaign?: Maybe<Scalars['ID']>;
  copyPanelImport?: Maybe<Scalars['ID']>;
  createAppointmentPanel?: Maybe<CreateAppointmentPanelPayLoad>;
  createBulkCampaign?: Maybe<Scalars['ID']>;
  createMessageTemplate?: Maybe<Scalars['ID']>;
  createPanel?: Maybe<CreatePanelPayLoad>;
  createPanelImport?: Maybe<ImportPatientsPayLoad>;
  createPatientAppoinment?: Maybe<CreatePatientAppointmentPayLoad>;
  createPatientForm?: Maybe<Scalars['ID']>;
  deleteAppointmentPanel?: Maybe<Scalars['ID']>;
  deleteBulkCampaign?: Maybe<Scalars['ID']>;
  deleteHPVAppointment?: Maybe<Scalars['ID']>;
  deletePanelImport?: Maybe<Scalars['ID']>;
  deletePatientImport?: Maybe<Scalars['ID']>;
  executePanel?: Maybe<Scalars['String']>;
  requestInvite?: Maybe<Scalars['ID']>;
  resendNotificationForPatientAppointment?: Maybe<Scalars['ID']>;
  resendNotificationForPatientExecution?: Maybe<Scalars['ID']>;
  rxDeclinedForPatientExecution?: Maybe<Scalars['ID']>;
  rxPendingForPatientExecution?: Maybe<Scalars['ID']>;
  rxProvidedForPatientExecution?: Maybe<Scalars['ID']>;
  setActiveClinic?: Maybe<Scalars['ID']>;
  setFormsDownloaded?: Maybe<Scalars['ID']>;
  startBulkCampaignExecution?: Maybe<Scalars['ID']>;
  submitPatientForm?: Maybe<Scalars['ID']>;
  unlockPatientExecution?: Maybe<Scalars['ID']>;
  updateAppointmentPanel?: Maybe<UpdateAppointmentPanelPayLoad>;
  updateBulkCampaign?: Maybe<Scalars['ID']>;
  updateConfiguration?: Maybe<Scalars['ID']>;
  updateHPVVaccinationInfo?: Maybe<Scalars['ID']>;
  updateMessageTemplate?: Maybe<Scalars['ID']>;
  updatePanel?: Maybe<UpdatePanelPayLoad>;
  updatePanelImport?: Maybe<Scalars['ID']>;
  updatePatientAppointment?: Maybe<UpdatePatientAppointmentPayLoad>;
  updatePatientAppointmentResponse?: Maybe<Scalars['ID']>;
  updatePatientExecutionPatientDetails?: Maybe<Scalars['ID']>;
  updatePatientForm?: Maybe<Scalars['ID']>;
  updatePatientImport?: Maybe<Scalars['ID']>;
  updateRemindersEnabled?: Maybe<Scalars['ID']>;
  uploadPatients?: Maybe<ImportPatientsPayLoad>;
};

export type MutationAddNoteToPatientExecutionArgs = {
  patientExecutionUuid: Scalars['String'];
  text: Scalars['String'];
};

export type MutationAddPatientImportArgs = {
  panelImportId: Scalars['ID'];
  patientImportInput: PatientImportInput;
};

export type MutationCancelAppointmentArgs = {
  id: Scalars['ID'];
};

export type MutationCancelBulkCampaignExecutionArgs = {
  id: Scalars['ID'];
};

export type MutationCancelPatientExecutionArgs = {
  id: Scalars['ID'];
  reason: HpvCancellationReason;
  reasonNote?: InputMaybe<Scalars['String']>;
};

export type MutationConfirmHpvAppointmentArgs = {
  patientAppointmentId: Scalars['ID'];
};

export type MutationCopyBulkCampaignArgs = {
  id: Scalars['ID'];
};

export type MutationCopyPanelImportArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAppointmentPanelArgs = {
  input: CreateAppointmentPanelInput;
};

export type MutationCreateBulkCampaignArgs = {
  input?: InputMaybe<BulkCampaignInput>;
};

export type MutationCreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};

export type MutationCreatePanelArgs = {
  input: CreatePanelInput;
};

export type MutationCreatePanelImportArgs = {
  description: Scalars['String'];
  file: Scalars['Upload'];
  importName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationCreatePatientAppoinmentArgs = {
  input: CreatePatientAppointmentInput;
};

export type MutationCreatePatientFormArgs = {
  input: CreatePatientFormInput;
};

export type MutationDeleteAppointmentPanelArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBulkCampaignArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteHpvAppointmentArgs = {
  patientAppointmentId: Scalars['ID'];
};

export type MutationDeletePanelImportArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePatientImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationExecutePanelArgs = {
  input?: InputMaybe<PanelExecutionInput>;
};

export type MutationRequestInviteArgs = {
  accessToken: Scalars['String'];
  email: Scalars['String'];
};

export type MutationResendNotificationForPatientAppointmentArgs = {
  patientAppointmentId: Scalars['ID'];
};

export type MutationResendNotificationForPatientExecutionArgs = {
  patientExecutionUuid: Scalars['String'];
};

export type MutationRxDeclinedForPatientExecutionArgs = {
  id: Scalars['ID'];
};

export type MutationRxPendingForPatientExecutionArgs = {
  id: Scalars['ID'];
};

export type MutationRxProvidedForPatientExecutionArgs = {
  id: Scalars['ID'];
};

export type MutationSetActiveClinicArgs = {
  clinicId: Scalars['ID'];
};

export type MutationSetFormsDownloadedArgs = {
  patientExecutionUuid: Scalars['String'];
};

export type MutationStartBulkCampaignExecutionArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitPatientFormArgs = {
  id: Scalars['ID'];
};

export type MutationUnlockPatientExecutionArgs = {
  patientExecutionUuid: Scalars['String'];
};

export type MutationUpdateAppointmentPanelArgs = {
  input: UpdateAppointmentPanelInput;
};

export type MutationUpdateBulkCampaignArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BulkCampaignInput>;
};

export type MutationUpdateConfigurationArgs = {
  emrDeclineAppointmentType?: InputMaybe<EmrDeclineAppointmentType>;
  isPatientDeclineEnabled?: InputMaybe<Scalars['Boolean']>;
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateHpvVaccinationInfoArgs = {
  accessToken: Scalars['String'];
  updateHPVVaccinationInfoInput: UpdateHpvVaccinationInfoInput;
};

export type MutationUpdateMessageTemplateArgs = {
  input: UpdateMessageTemplateInput;
};

export type MutationUpdatePanelArgs = {
  id: Scalars['ID'];
  input: UpdatePanelInput;
};

export type MutationUpdatePanelImportArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationUpdatePatientAppointmentArgs = {
  input: UpdatePatientAppointmentInput;
};

export type MutationUpdatePatientAppointmentResponseArgs = {
  appointmentResponse?: InputMaybe<PatientAppointmentResponse>;
  patientAppointmentId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePatientExecutionPatientDetailsArgs = {
  input: UpdatePatientExecutionPatientDetailsInput;
};

export type MutationUpdatePatientFormArgs = {
  input: UpdatePatientFormInput;
};

export type MutationUpdatePatientImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  patientImportInput: PatientImportInput;
};

export type MutationUpdateRemindersEnabledArgs = {
  enabled: Scalars['Boolean'];
  patientExecutionUuid: Scalars['String'];
};

export type MutationUploadPatientsArgs = {
  file: Scalars['Upload'];
  importName?: InputMaybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

export enum NotificationChannel {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  deliveryState?: Maybe<NotificationMessageDeliveryState>;
  email?: Maybe<Scalars['String']>;
  notificationStatus?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export enum NotificationMessageDeliveryState {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export type Options = {
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
};

export type Panel = {
  __typename?: 'Panel';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PanelExecutionInput = {
  panelImportId?: InputMaybe<Scalars['ID']>;
  patientInvitationInput?: InputMaybe<PatientInvitationInput>;
};

export type PanelImport = {
  __typename?: 'PanelImport';
  createdAt?: Maybe<Scalars['DateTime']>;
  customColumnNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  errorCounts?: Maybe<Scalars['ID']>;
  errorRecords?: Maybe<Array<Maybe<PatientImport>>>;
  id: Scalars['ID'];
  matchedCounts?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  newCounts?: Maybe<Scalars['ID']>;
  panelImportState?: Maybe<PanelImportState>;
  patientImportsPayLoad?: Maybe<BulkPatientImportsPayLoad>;
  totalCounts?: Maybe<Scalars['ID']>;
};

export type PanelImportPatientImportsPayLoadArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  state?: InputMaybe<PatientImportState>;
};

export enum PanelImportState {
  Cancelled = 'CANCELLED',
  Executed = 'EXECUTED',
  Ready = 'READY',
}

export type PanelImportsPayLoad = {
  __typename?: 'PanelImportsPayLoad';
  panelImports?: Maybe<Array<Maybe<PanelImport>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PanelSummaryPayLoad = {
  __typename?: 'PanelSummaryPayLoad';
  countAccountLocked?: Maybe<Scalars['Int']>;
  countConsultationDeclined?: Maybe<Scalars['Int']>;
  countConsultationRequested?: Maybe<Scalars['Int']>;
  countEmailSmsFailed?: Maybe<Scalars['Int']>;
  countExpired?: Maybe<Scalars['Int']>;
  countNotConfirmedAppointments?: Maybe<Scalars['Int']>;
  countRequiresAttention?: Maybe<Scalars['Int']>;
  patientExecutions?: Maybe<Array<Maybe<PatientExecution>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Patient = {
  __typename?: 'Patient';
  birthDate?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
};

export type PatientAppointment = {
  __typename?: 'PatientAppointment';
  appointmentId?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentType>;
  attachments?: Maybe<Array<Attachment>>;
  clinic?: Maybe<Clinic>;
  clinicAddress?: Maybe<ClinicAddress>;
  concerns?: Maybe<Scalars['String']>;
  covidScreening?: Maybe<PatientFormPayload>;
  emrDeclineAppointmentType?: Maybe<EmrDeclineAppointmentType>;
  futureReminders?: Maybe<Array<Maybe<FutureReminderPayLoad>>>;
  id?: Maybe<Scalars['ID']>;
  isPatientDeclineEnabled?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patient>;
  patientAppointmentResponse?: Maybe<PatientAppointmentResponse>;
  provider?: Maybe<Provider>;
  reminders?: Maybe<Array<Maybe<ReminderPayLoad>>>;
  responseDateTime?: Maybe<Scalars['DateTime']>;
  responseReason?: Maybe<Scalars['String']>;
  securedContent?: Maybe<Scalars['String']>;
  startDate?: Maybe<ApplicationDateTime>;
  status?: Maybe<Scalars['String']>;
  viewedDateTime?: Maybe<Scalars['DateTime']>;
};

export enum PatientAppointmentResponse {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
}

export type PatientExecution = {
  __typename?: 'PatientExecution';
  attachmentLinks?: Maybe<Array<Maybe<AttachmentLink>>>;
  auditTrails?: Maybe<Array<Maybe<AuditTrail>>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formsDownloadedDateTime?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  homePhone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  lastAuditTrail?: Maybe<AuditTrail>;
  lastName?: Maybe<Scalars['String']>;
  nextAppointment?: Maybe<PatientAppointment>;
  note?: Maybe<Scalars['String']>;
  patientAppointments?: Maybe<Array<Maybe<PatientAppointment>>>;
  patientExecutionAccess?: Maybe<PatientExecutionAccess>;
  patientExecutionState?: Maybe<PatientExecutionState>;
  patientForms?: Maybe<Array<Maybe<PatientForm>>>;
  phn?: Maybe<Scalars['String']>;
  physicianName?: Maybe<Scalars['String']>;
  physicianPhoneNumber?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
};

export type PatientExecutionAccess = {
  __typename?: 'PatientExecutionAccess';
  clinic?: Maybe<Clinic>;
  id: Scalars['ID'];
  isLocked?: Maybe<Scalars['Boolean']>;
  patientExecution?: Maybe<PatientExecution>;
};

export type PatientExecutionFilter = {
  lastAuditTrailEventType?: InputMaybe<Scalars['String']>;
  patientSearch?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};

export enum PatientExecutionState {
  Active = 'ACTIVE',
  Done = 'DONE',
  Failed = 'FAILED',
  Ready = 'READY',
}

export type PatientForm = {
  __typename?: 'PatientForm';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplate?: Maybe<FormTemplate>;
  id?: Maybe<Scalars['ID']>;
  reportFilename?: Maybe<Scalars['String']>;
  reportUrl?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Int']>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
};

export type PatientFormPayload = {
  __typename?: 'PatientFormPayload';
  form?: Maybe<PatientForm>;
};

export type PatientImport = {
  __typename?: 'PatientImport';
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  errorDetail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  homePhone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  keyValueMap?: Maybe<Array<Maybe<KeyValuePayLoad>>>;
  lastName?: Maybe<Scalars['String']>;
  panelImport?: Maybe<PanelImport>;
  patientImportState?: Maybe<PatientImportState>;
  physicianName?: Maybe<Scalars['String']>;
  physicianPhoneNumber?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  sourceRecord?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientImportInput = {
  dateOfBirth: Scalars['Date'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  keyValueMap?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  lastName: Scalars['String'];
  sms?: InputMaybe<Scalars['String']>;
};

export enum PatientImportState {
  Error = 'ERROR',
  Matched = 'MATCHED',
  New = 'NEW',
}

export type PatientInvitationInput = {
  securedMessageTemplateId?: InputMaybe<Scalars['ID']>;
  unsecureMessageTemplateId: Scalars['ID'];
};

export type PaymentInvoice = {
  __typename?: 'PaymentInvoice';
  invoiceUrl?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentTotal?: Maybe<Scalars['Int']>;
  remindersFailed?: Maybe<Scalars['Int']>;
  remindersSuccess?: Maybe<Scalars['Int']>;
};

export type Provider = {
  __typename?: 'Provider';
  descriptor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ProvidersPayLoad = {
  __typename?: 'ProvidersPayLoad';
  allProviders?: Maybe<Array<Maybe<Provider>>>;
  configuredProviders?: Maybe<Array<Maybe<Provider>>>;
};

export type Query = {
  __typename?: 'Query';
  appointmentById?: Maybe<PatientAppointment>;
  appointmentByReminderId?: Maybe<PatientAppointment>;
  appointmentPanelById?: Maybe<AppointmentPanel>;
  appointmentPanels?: Maybe<Array<Maybe<AppointmentPanel>>>;
  appointmentStatuses?: Maybe<Array<Maybe<AppointmentStatus>>>;
  appointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>;
  billingPeriod?: Maybe<BillingPeriodPayLoad>;
  bulkCampaign?: Maybe<BulkCampaign>;
  bulkCampaignFooterTemplates?: Maybe<BulkCampaignFooterTemplatePayload>;
  bulkCampaignPaymentInvoice?: Maybe<BulkCampaignPaymentInvoice>;
  bulkCampaignTemplateByType?: Maybe<BulkCampaignTemplate>;
  bulkCampaignTypes?: Maybe<Array<Maybe<BulkCampaignType>>>;
  bulkCampaigns?: Maybe<BulkCampaignsPayLoad>;
  bulkMessagingSummary?: Maybe<BulkMessagingSummaryPayLoad>;
  bulkPatientExecutionAccessByAccessToken?: Maybe<BulkPatientExecutionAccess>;
  configuration?: Maybe<ConfigurationPayLoad>;
  currentUser?: Maybe<User>;
  downloadUrl?: Maybe<Scalars['String']>;
  fileImports?: Maybe<Array<Maybe<FileImport>>>;
  formTemplates?: Maybe<Array<Maybe<FormTemplate>>>;
  getAttachmentLinkUrl?: Maybe<Scalars['String']>;
  getPatientFormUrl?: Maybe<Scalars['String']>;
  hpvVaccinationInfoSecuredContentByAccessToken?: Maybe<HpvVaccinationInfoSecuredContent>;
  messageTemplateById?: Maybe<MessageTemplate>;
  messageTemplates?: Maybe<Array<Maybe<MessageTemplate>>>;
  panel?: Maybe<Panel>;
  panelById?: Maybe<Panel>;
  panelImport?: Maybe<PanelImport>;
  panelImports?: Maybe<PanelImportsPayLoad>;
  panelSummary?: Maybe<PanelSummaryPayLoad>;
  panelSummaryAlert?: Maybe<PanelSummaryPayLoad>;
  patientAppointmentByAccessToken?: Maybe<PatientAppointment>;
  patientAppointmentsByRange?: Maybe<Array<Maybe<PatientAppointment>>>;
  patientExecutionAccessByAccessToken?: Maybe<PatientExecutionAccess>;
  patientExecutionDetails?: Maybe<PatientExecution>;
  patientFormById?: Maybe<PatientForm>;
  patientForms?: Maybe<Array<Maybe<PatientForm>>>;
  patientImport?: Maybe<PatientImport>;
  paymentInvoices?: Maybe<Array<Maybe<PaymentInvoice>>>;
  providerNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  providers?: Maybe<ProvidersPayLoad>;
  remindersByAppointmentId?: Maybe<Array<Maybe<ReminderPayLoad>>>;
  remindersByDateAndNames?: Maybe<Array<Maybe<ReminderPayLoad>>>;
  remindersSummaryByDate?: Maybe<RemindersSummaryByDatePayLoad>;
  sources?: Maybe<Array<Maybe<AppointmentSource>>>;
  uploadUrl?: Maybe<Scalars['String']>;
  validateBulkCampaign?: Maybe<BulkCampaignValidationPayLoad>;
};

export type QueryAppointmentByIdArgs = {
  appointmentId: Scalars['ID'];
};

export type QueryAppointmentByReminderIdArgs = {
  reminderId: Scalars['ID'];
};

export type QueryAppointmentPanelByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAppointmentStatusesArgs = {
  source?: InputMaybe<AppointmentSource>;
};

export type QueryAppointmentTypesArgs = {
  source?: InputMaybe<AppointmentSource>;
};

export type QueryBulkCampaignArgs = {
  id: Scalars['ID'];
};

export type QueryBulkCampaignPaymentInvoiceArgs = {
  bulkCampaignId: Scalars['ID'];
};

export type QueryBulkCampaignTemplateByTypeArgs = {
  type: BulkCampaignType;
};

export type QueryBulkCampaignsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sort?: InputMaybe<Sort>;
};

export type QueryBulkMessagingSummaryArgs = {
  bulkCampaignId: Scalars['ID'];
  channel?: InputMaybe<NotificationChannel>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};

export type QueryBulkPatientExecutionAccessByAccessTokenArgs = {
  accessToken: Scalars['String'];
};

export type QueryConfigurationArgs = {
  source?: InputMaybe<AppointmentSource>;
};

export type QueryDownloadUrlArgs = {
  attachmentId: Scalars['Int'];
};

export type QueryGetAttachmentLinkUrlArgs = {
  id: Scalars['ID'];
};

export type QueryGetPatientFormUrlArgs = {
  uuid: Scalars['String'];
};

export type QueryHpvVaccinationInfoSecuredContentByAccessTokenArgs = {
  accessToken: Scalars['String'];
};

export type QueryMessageTemplateByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMessageTemplatesArgs = {
  type?: InputMaybe<MessageTemplateType>;
};

export type QueryPanelByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPanelImportArgs = {
  id: Scalars['ID'];
};

export type QueryPanelImportsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sort?: InputMaybe<Sort>;
};

export type QueryPanelSummaryArgs = {
  filter?: InputMaybe<PatientExecutionFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sort?: InputMaybe<Sort>;
};

export type QueryPanelSummaryAlertArgs = {
  filter?: InputMaybe<HpvAlertType>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sort?: InputMaybe<Sort>;
};

export type QueryPatientAppointmentByAccessTokenArgs = {
  accessToken: Scalars['String'];
};

export type QueryPatientAppointmentsByRangeArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  options?: InputMaybe<Options>;
  providerId: Scalars['String'];
  to?: InputMaybe<Scalars['DateTime']>;
};

export type QueryPatientExecutionAccessByAccessTokenArgs = {
  accessToken: Scalars['String'];
};

export type QueryPatientExecutionDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryPatientFormByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPatientImportArgs = {
  id: Scalars['ID'];
};

export type QueryProvidersArgs = {
  source?: InputMaybe<AppointmentSource>;
};

export type QueryRemindersByAppointmentIdArgs = {
  appointmentId?: InputMaybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

export type QueryRemindersByDateAndNamesArgs = {
  from: Scalars['Date'];
  panelId?: InputMaybe<Scalars['ID']>;
  to: Scalars['Date'];
};

export type QueryRemindersSummaryByDateArgs = {
  from: Scalars['Date'];
  panelId?: InputMaybe<Scalars['ID']>;
  to: Scalars['Date'];
};

export type QueryUploadUrlArgs = {
  mimeType: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryValidateBulkCampaignArgs = {
  id: Scalars['ID'];
};

export type ReminderPayLoad = {
  __typename?: 'ReminderPayLoad';
  channel?: Maybe<Scalars['String']>;
  deliveryState?: Maybe<NotificationMessageDeliveryState>;
  id?: Maybe<Scalars['ID']>;
  panelName?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  patientAppointmentId?: Maybe<Scalars['ID']>;
  sentDate?: Maybe<Scalars['DateTime']>;
};

export type RemindersSummaryByDatePayLoad = {
  __typename?: 'RemindersSummaryByDatePayLoad';
  emailFailure?: Maybe<Scalars['Int']>;
  emailSuccess?: Maybe<Scalars['Int']>;
  smsFailure?: Maybe<Scalars['Int']>;
  smsSuccess?: Maybe<Scalars['Int']>;
  totalFailure?: Maybe<Scalars['Int']>;
  totalSuccess?: Maybe<Scalars['Int']>;
};

export type SecuredContent = BulkPatientExecutionAccess | BulkSecureMailContent;

export type Sort = {
  direction?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
};

export type TemplateAttachment = {
  __typename?: 'TemplateAttachment';
  description?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type UpdateAppointmentPanelInput = {
  appointmentConfirmation?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  confirmEmailMessageTemplateId?: InputMaybe<Scalars['ID']>;
  confirmSmsMessageTemplateId?: InputMaybe<Scalars['ID']>;
  covidScreening?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emailMessageTemplateId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  immediateReminder?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationPriority?: InputMaybe<NotificationChannel>;
  reminderInDays?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  securedMessageTemplateId?: InputMaybe<Scalars['ID']>;
  smsMessageTemplateId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<AppointmentPanelState>;
};

export type UpdateAppointmentPanelPayLoad = {
  __typename?: 'UpdateAppointmentPanelPayLoad';
  panel?: Maybe<AppointmentPanel>;
};

export type UpdateHpvVaccinationInfoInput = {
  doses?: InputMaybe<Array<InputMaybe<HpvVaccinationDoseInput>>>;
  patientResponse?: InputMaybe<HpvVaccinationInfoPatientResponse>;
  willNotCompleteReason?: InputMaybe<HpvVaccinationInfoWontCompleteReason>;
};

export type UpdateMessageTemplateInput = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MessageTemplateType>;
};

export type UpdatePanelInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePanelPayLoad = {
  __typename?: 'UpdatePanelPayLoad';
  panel?: Maybe<Panel>;
};

export type UpdatePatientAppointmentInput = {
  appointmentDateTime?: InputMaybe<Scalars['DateTime']>;
  clinicAddressId?: InputMaybe<Scalars['ID']>;
  patientAppointmentId: Scalars['ID'];
};

export type UpdatePatientAppointmentPayLoad = {
  __typename?: 'UpdatePatientAppointmentPayLoad';
  patientAppointment?: Maybe<PatientAppointment>;
};

export type UpdatePatientExecutionPatientDetailsInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sms?: InputMaybe<Scalars['String']>;
};

export type UpdatePatientFormInput = {
  content: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  activeClinic: Clinic;
  clinics: Array<Maybe<Clinic>>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  uuid: Scalars['String'];
};

export type ZoneId = {
  __typename?: 'ZoneId';
  id?: Maybe<Scalars['String']>;
};

export type PatientAppointmentsByRangeQueryVariables = Exact<{
  providerId: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  options: Options;
}>;

export type PatientAppointmentsByRangeQuery = {
  __typename?: 'Query';
  patientAppointmentsByRange?: Array<{
    __typename?: 'PatientAppointment';
    id?: string | null;
    appointmentId?: string | null;
    status?: string | null;
    patientAppointmentResponse?: PatientAppointmentResponse | null;
    responseDateTime?: any | null;
    responseReason?: string | null;
    viewedDateTime?: any | null;
    concerns?: string | null;
    startDate?: {
      __typename?: 'ApplicationDateTime';
      dateTime?: any | null;
      date?: string | null;
      time?: string | null;
    } | null;
    appointmentType?: {
      __typename?: 'AppointmentType';
      descriptor?: string | null;
      defaultDurationInMinutes?: number | null;
    } | null;
    patient?: {
      __typename?: 'Patient';
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      gender?: string | null;
      email?: string | null;
      sms?: string | null;
    } | null;
    provider?: { __typename?: 'Provider'; descriptor?: string | null } | null;
    clinic?: { __typename?: 'Clinic'; id: string; name: string } | null;
    reminders?: Array<{
      __typename?: 'ReminderPayLoad';
      sentDate?: any | null;
      panelName?: string | null;
      deliveryState?: NotificationMessageDeliveryState | null;
      channel?: string | null;
      patientAppointmentId?: string | null;
      id?: string | null;
    } | null> | null;
    covidScreening?: {
      __typename?: 'PatientFormPayload';
      form?: {
        __typename?: 'PatientForm';
        id?: string | null;
        url?: string | null;
        createdAt?: any | null;
        content?: string | null;
        submissionDate?: any | null;
        result?: number | null;
        reportUrl?: string | null;
      } | null;
    } | null;
    futureReminders?: Array<{
      __typename?: 'FutureReminderPayLoad';
      panelName?: string | null;
      channel?: string | null;
      scheduledDate?: {
        __typename?: 'ApplicationDateTime';
        dateTime?: any | null;
        date?: string | null;
        time?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type AppointmentTypesQueryVariables = Exact<{
  source: AppointmentSource;
}>;

export type AppointmentTypesQuery = {
  __typename?: 'Query';
  appointmentTypes?: Array<{
    __typename?: 'AppointmentType';
    id?: string | null;
    descriptor?: string | null;
    defaultDurationInMinutes?: number | null;
  } | null> | null;
};

export type AppointmentStatusesQueryVariables = Exact<{
  source: AppointmentSource;
}>;

export type AppointmentStatusesQuery = {
  __typename?: 'Query';
  appointmentStatuses?: Array<{
    __typename?: 'AppointmentStatus';
    status?: string | null;
  } | null> | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    id?: string | null;
    uuid: string;
    email?: string | null;
    activeClinic: {
      __typename?: 'Clinic';
      id: string;
      secureMailClinicId?: string | null;
      appointmentSource?: AppointmentSource | null;
      emrInstance?: { __typename?: 'EmrInstance'; instanceType?: EmrInstanceType | null } | null;
    };
    clinics: Array<{
      __typename?: 'Clinic';
      id: string;
      name: string;
      emrInstance?: { __typename?: 'EmrInstance'; instanceType?: EmrInstanceType | null } | null;
    } | null>;
  } | null;
};

export type CreateAppointmentPanelMutationVariables = Exact<{
  input: CreateAppointmentPanelInput;
}>;

export type CreateAppointmentPanelMutation = {
  __typename?: 'Mutation';
  createAppointmentPanel?: {
    __typename?: 'CreateAppointmentPanelPayLoad';
    panel?: { __typename?: 'AppointmentPanel'; id?: string | null } | null;
  } | null;
};

export type DeleteAppointmentPanelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAppointmentPanelMutation = {
  __typename?: 'Mutation';
  deleteAppointmentPanel?: string | null;
};

export type MessageTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type MessageTemplatesQuery = {
  __typename?: 'Query';
  messageTemplates?: Array<{
    __typename?: 'MessageTemplate';
    id: string;
    name?: string | null;
    subject?: string | null;
    type?: MessageTemplateType | null;
    content?: string | null;
    global?: boolean | null;
  } | null> | null;
};

export type UpdateConfigurationMutationVariables = Exact<{
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  emrDeclineAppointmentType?: InputMaybe<EmrDeclineAppointmentType>;
  status?: InputMaybe<Scalars['String']>;
  isPatientDeclineEnabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateConfigurationMutation = {
  __typename?: 'Mutation';
  updateConfiguration?: string | null;
};

export type ConfigurationQueryVariables = Exact<{
  source: AppointmentSource;
}>;

export type ConfigurationQuery = {
  __typename?: 'Query';
  configuration?: {
    __typename?: 'ConfigurationPayLoad';
    providersPayLoad?: {
      __typename?: 'ProvidersPayLoad';
      allProviders?: Array<{
        __typename?: 'Provider';
        id: string;
        descriptor?: string | null;
      } | null> | null;
      configuredProviders?: Array<{
        __typename?: 'Provider';
        id: string;
        descriptor?: string | null;
      } | null> | null;
    } | null;
    emrDeclineAppointmentConfiguration?: {
      __typename?: 'EmrDeclineAppointmentConfiguration';
      type?: EmrDeclineAppointmentType | null;
      isPatientDeclineEnabled?: boolean | null;
      status?: string | null;
    } | null;
  } | null;
};

export type ProvidersQueryVariables = Exact<{
  source: AppointmentSource;
}>;

export type ProvidersQuery = {
  __typename?: 'Query';
  providers?: {
    __typename?: 'ProvidersPayLoad';
    allProviders?: Array<{
      __typename?: 'Provider';
      id: string;
      descriptor?: string | null;
    } | null> | null;
    configuredProviders?: Array<{
      __typename?: 'Provider';
      id: string;
      descriptor?: string | null;
    } | null> | null;
  } | null;
};

export type MessageTemplateByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MessageTemplateByIdQuery = {
  __typename?: 'Query';
  messageTemplateById?: {
    __typename?: 'MessageTemplate';
    id: string;
    content?: string | null;
    global?: boolean | null;
    subject?: string | null;
    name?: string | null;
    type?: MessageTemplateType | null;
  } | null;
};

export type PatientFormsQueryVariables = Exact<{ [key: string]: never }>;

export type PatientFormsQuery = {
  __typename?: 'Query';
  patientForms?: Array<{
    __typename?: 'PatientForm';
    id?: string | null;
    createdAt?: any | null;
    content?: string | null;
  } | null> | null;
};

export type PatientFormByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PatientFormByIdQuery = {
  __typename?: 'Query';
  patientFormById?: {
    __typename?: 'PatientForm';
    id?: string | null;
    createdAt?: any | null;
    content?: string | null;
  } | null;
};

export type AppointmentPanelsQueryVariables = Exact<{ [key: string]: never }>;

export type AppointmentPanelsQuery = {
  __typename?: 'Query';
  appointmentPanels?: Array<{
    __typename?: 'AppointmentPanel';
    id?: string | null;
    createdAt?: any | null;
    name?: string | null;
    description?: string | null;
    state?: AppointmentPanelState | null;
    appointmentSource?: AppointmentSource | null;
    appointmentTypeIds?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    appointmentConfirmation?: boolean | null;
    reminderInDays?: Array<string | null> | null;
    immediateReminder?: boolean | null;
    emailMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
    } | null;
    securedMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
    } | null;
  } | null> | null;
};

export type AppointmentPanelByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AppointmentPanelByIdQuery = {
  __typename?: 'Query';
  appointmentPanelById?: {
    __typename?: 'AppointmentPanel';
    id?: string | null;
    createdAt?: any | null;
    name?: string | null;
    description?: string | null;
    state?: AppointmentPanelState | null;
    appointmentSource?: AppointmentSource | null;
    appointmentTypeIds?: Array<string | null> | null;
    appointmentConfirmation?: boolean | null;
    reminderInDays?: Array<string | null> | null;
    immediateReminder?: boolean | null;
    notificationPriority?: NotificationChannel | null;
    covidScreening?: boolean | null;
    providerIds?: Array<string | null> | null;
    emailMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
      type?: MessageTemplateType | null;
    } | null;
    confirmEmailMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
      type?: MessageTemplateType | null;
    } | null;
    securedMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
      type?: MessageTemplateType | null;
    } | null;
    smsMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
      type?: MessageTemplateType | null;
    } | null;
    confirmSmsMessageTemplate?: {
      __typename?: 'MessageTemplate';
      id: string;
      name?: string | null;
      subject?: string | null;
      content?: string | null;
      global?: boolean | null;
      type?: MessageTemplateType | null;
    } | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id?: string | null;
      uuid: string;
      filename: string;
      mimeType: string;
      size?: number | null;
    }> | null;
  } | null;
};

export type PatientAppointmentByAccessTokenQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type PatientAppointmentByAccessTokenQuery = {
  __typename?: 'Query';
  patientAppointmentByAccessToken?: {
    __typename?: 'PatientAppointment';
    id?: string | null;
    appointmentId?: string | null;
    status?: string | null;
    patientAppointmentResponse?: PatientAppointmentResponse | null;
    responseDateTime?: any | null;
    responseReason?: string | null;
    viewedDateTime?: any | null;
    securedContent?: string | null;
    isPatientDeclineEnabled?: boolean | null;
    emrDeclineAppointmentType?: EmrDeclineAppointmentType | null;
    startDate?: {
      __typename?: 'ApplicationDateTime';
      dateTime?: any | null;
      date?: string | null;
      time?: string | null;
    } | null;
    patient?: {
      __typename?: 'Patient';
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      gender?: string | null;
      email?: string | null;
      sms?: string | null;
    } | null;
    provider?: { __typename?: 'Provider'; id: string; descriptor?: string | null } | null;
    clinic?: { __typename?: 'Clinic'; id: string; name: string } | null;
    reminders?: Array<{
      __typename?: 'ReminderPayLoad';
      sentDate?: any | null;
      panelName?: string | null;
      deliveryState?: NotificationMessageDeliveryState | null;
      channel?: string | null;
      patientAppointmentId?: string | null;
      id?: string | null;
    } | null> | null;
    covidScreening?: {
      __typename?: 'PatientFormPayload';
      form?: {
        __typename?: 'PatientForm';
        id?: string | null;
        url?: string | null;
        createdAt?: any | null;
        content?: string | null;
        submissionDate?: any | null;
        result?: number | null;
        reportUrl?: string | null;
      } | null;
    } | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id?: string | null;
      uuid: string;
      filename: string;
      mimeType: string;
      size?: number | null;
    }> | null;
  } | null;
};

export type AppointmentByIdQueryVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;

export type AppointmentByIdQuery = {
  __typename?: 'Query';
  appointmentById?: {
    __typename?: 'PatientAppointment';
    id?: string | null;
    appointmentId?: string | null;
    status?: string | null;
    patientAppointmentResponse?: PatientAppointmentResponse | null;
    responseDateTime?: any | null;
    responseReason?: string | null;
    viewedDateTime?: any | null;
    concerns?: string | null;
    securedContent?: string | null;
    startDate?: {
      __typename?: 'ApplicationDateTime';
      dateTime?: any | null;
      date?: string | null;
      time?: string | null;
    } | null;
    appointmentType?: {
      __typename?: 'AppointmentType';
      id?: string | null;
      descriptor?: string | null;
      defaultDurationInMinutes?: number | null;
    } | null;
    patient?: {
      __typename?: 'Patient';
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      gender?: string | null;
      email?: string | null;
      sms?: string | null;
    } | null;
    provider?: { __typename?: 'Provider'; id: string; descriptor?: string | null } | null;
    clinic?: { __typename?: 'Clinic'; id: string; name: string } | null;
    reminders?: Array<{
      __typename?: 'ReminderPayLoad';
      sentDate?: any | null;
      panelName?: string | null;
      deliveryState?: NotificationMessageDeliveryState | null;
      channel?: string | null;
      patientAppointmentId?: string | null;
      id?: string | null;
    } | null> | null;
  } | null;
};

export type RemindersSummaryByDateQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  panelId?: InputMaybe<Scalars['ID']>;
}>;

export type RemindersSummaryByDateQuery = {
  __typename?: 'Query';
  remindersSummaryByDate?: {
    __typename?: 'RemindersSummaryByDatePayLoad';
    totalSuccess?: number | null;
    totalFailure?: number | null;
    smsSuccess?: number | null;
    smsFailure?: number | null;
    emailSuccess?: number | null;
    emailFailure?: number | null;
  } | null;
};

export type RemindersByDateAndNamesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  panelId?: InputMaybe<Scalars['ID']>;
}>;

export type RemindersByDateAndNamesQuery = {
  __typename?: 'Query';
  remindersByDateAndNames?: Array<{
    __typename?: 'ReminderPayLoad';
    id?: string | null;
    sentDate?: any | null;
    panelName?: string | null;
    deliveryState?: NotificationMessageDeliveryState | null;
    channel?: string | null;
    patientAppointmentId?: string | null;
    patient?: {
      __typename?: 'Patient';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null> | null;
};

export type RemindersByAppointmentIdQueryVariables = Exact<{
  panelId: Scalars['ID'];
  appointmentId?: InputMaybe<Scalars['String']>;
}>;

export type RemindersByAppointmentIdQuery = {
  __typename?: 'Query';
  remindersByAppointmentId?: Array<{
    __typename?: 'ReminderPayLoad';
    id?: string | null;
    sentDate?: any | null;
    panelName?: string | null;
    deliveryState?: NotificationMessageDeliveryState | null;
    channel?: string | null;
    patientAppointmentId?: string | null;
  } | null> | null;
};

export type UpdatePatientAppointmentResponseMutationVariables = Exact<{
  patientAppointmentId: Scalars['ID'];
  appointmentResponse?: InputMaybe<PatientAppointmentResponse>;
  reason: Scalars['String'];
}>;

export type UpdatePatientAppointmentResponseMutation = {
  __typename?: 'Mutation';
  updatePatientAppointmentResponse?: string | null;
};

export type UpdateAppointmentPanelMutationVariables = Exact<{
  input: UpdateAppointmentPanelInput;
}>;

export type UpdateAppointmentPanelMutation = {
  __typename?: 'Mutation';
  updateAppointmentPanel?: {
    __typename?: 'UpdateAppointmentPanelPayLoad';
    panel?: { __typename?: 'AppointmentPanel'; id?: string | null } | null;
  } | null;
};

export type CreatePatientFormMutationVariables = Exact<{
  input: CreatePatientFormInput;
}>;

export type CreatePatientFormMutation = {
  __typename?: 'Mutation';
  createPatientForm?: string | null;
};

export type UpdatePatientFormMutationVariables = Exact<{
  input: UpdatePatientFormInput;
}>;

export type UpdatePatientFormMutation = {
  __typename?: 'Mutation';
  updatePatientForm?: string | null;
};

export type CreateMessageTemplateMutationVariables = Exact<{
  input: CreateMessageTemplateInput;
}>;

export type CreateMessageTemplateMutation = {
  __typename?: 'Mutation';
  createMessageTemplate?: string | null;
};

export type UpdateMessageTemplateMutationVariables = Exact<{
  input: UpdateMessageTemplateInput;
}>;

export type UpdateMessageTemplateMutation = {
  __typename?: 'Mutation';
  updateMessageTemplate?: string | null;
};

export type RequestInviteMutationVariables = Exact<{
  accessToken: Scalars['String'];
  email: Scalars['String'];
}>;

export type RequestInviteMutation = { __typename?: 'Mutation'; requestInvite?: string | null };

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment?: string | null;
};

export type PaymentInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentInvoicesQuery = {
  __typename?: 'Query';
  paymentInvoices?: Array<{
    __typename?: 'PaymentInvoice';
    paymentDate?: any | null;
    remindersSuccess?: number | null;
    remindersFailed?: number | null;
    paymentTotal?: number | null;
    paymentStatus?: string | null;
    invoiceUrl?: string | null;
  } | null> | null;
};

export type BillingPeriodQueryVariables = Exact<{ [key: string]: never }>;

export type BillingPeriodQuery = {
  __typename?: 'Query';
  billingPeriod?: {
    __typename?: 'BillingPeriodPayLoad';
    startDate?: any | null;
    endDate?: any | null;
    currentUsage?: number | null;
    usageLimit?: number | null;
  } | null;
};

export type UploadUrlQueryVariables = Exact<{
  uuid: Scalars['String'];
  mimeType: Scalars['String'];
}>;

export type UploadUrlQuery = { __typename?: 'Query'; uploadUrl?: string | null };

export type DownloadUrlQueryVariables = Exact<{
  attachmentId: Scalars['Int'];
}>;

export type DownloadUrlQuery = { __typename?: 'Query'; downloadUrl?: string | null };

export type SetActiveClinicMutationVariables = Exact<{
  clinicId: Scalars['ID'];
}>;

export type SetActiveClinicMutation = { __typename?: 'Mutation'; setActiveClinic?: string | null };

export const PatientAppointmentsByRangeDocument = gql`
  query patientAppointmentsByRange(
    $providerId: String!
    $from: DateTime!
    $to: DateTime!
    $options: Options!
  ) {
    patientAppointmentsByRange(providerId: $providerId, from: $from, to: $to, options: $options) {
      id
      appointmentId
      startDate {
        dateTime
        date
        time
      }
      status
      appointmentType {
        descriptor
        defaultDurationInMinutes
      }
      patient {
        firstName
        lastName
        birthDate
        gender
        email
        sms
      }
      provider {
        descriptor
      }
      patientAppointmentResponse
      responseDateTime
      responseReason
      viewedDateTime
      clinic {
        id
        name
      }
      concerns
      reminders {
        sentDate
        panelName
        deliveryState
        channel
        patientAppointmentId
        id
      }
      covidScreening {
        form {
          id
          url
          createdAt
          content
          submissionDate
          result
          reportUrl
        }
      }
      futureReminders {
        scheduledDate {
          dateTime
          date
          time
        }
        panelName
        channel
      }
    }
  }
`;

/**
 * __usePatientAppointmentsByRangeQuery__
 *
 * To run a query within a React component, call `usePatientAppointmentsByRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppointmentsByRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppointmentsByRangeQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      options: // value for 'options'
 *   },
 * });
 */
export function usePatientAppointmentsByRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientAppointmentsByRangeQuery,
    PatientAppointmentsByRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientAppointmentsByRangeQuery, PatientAppointmentsByRangeQueryVariables>(
    PatientAppointmentsByRangeDocument,
    options,
  );
}
export function usePatientAppointmentsByRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientAppointmentsByRangeQuery,
    PatientAppointmentsByRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientAppointmentsByRangeQuery,
    PatientAppointmentsByRangeQueryVariables
  >(PatientAppointmentsByRangeDocument, options);
}
export type PatientAppointmentsByRangeQueryHookResult = ReturnType<
  typeof usePatientAppointmentsByRangeQuery
>;
export type PatientAppointmentsByRangeLazyQueryHookResult = ReturnType<
  typeof usePatientAppointmentsByRangeLazyQuery
>;
export type PatientAppointmentsByRangeQueryResult = Apollo.QueryResult<
  PatientAppointmentsByRangeQuery,
  PatientAppointmentsByRangeQueryVariables
>;
export const AppointmentTypesDocument = gql`
  query appointmentTypes($source: AppointmentSource!) {
    appointmentTypes(source: $source) {
      id
      descriptor
      defaultDurationInMinutes
    }
  }
`;

/**
 * __useAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentTypesQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAppointmentTypesQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentTypesQuery, AppointmentTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentTypesQuery, AppointmentTypesQueryVariables>(
    AppointmentTypesDocument,
    options,
  );
}
export function useAppointmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentTypesQuery, AppointmentTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentTypesQuery, AppointmentTypesQueryVariables>(
    AppointmentTypesDocument,
    options,
  );
}
export type AppointmentTypesQueryHookResult = ReturnType<typeof useAppointmentTypesQuery>;
export type AppointmentTypesLazyQueryHookResult = ReturnType<typeof useAppointmentTypesLazyQuery>;
export type AppointmentTypesQueryResult = Apollo.QueryResult<
  AppointmentTypesQuery,
  AppointmentTypesQueryVariables
>;
export const AppointmentStatusesDocument = gql`
  query appointmentStatuses($source: AppointmentSource!) {
    appointmentStatuses(source: $source) {
      status
    }
  }
`;

/**
 * __useAppointmentStatusesQuery__
 *
 * To run a query within a React component, call `useAppointmentStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentStatusesQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAppointmentStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentStatusesQuery, AppointmentStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentStatusesQuery, AppointmentStatusesQueryVariables>(
    AppointmentStatusesDocument,
    options,
  );
}
export function useAppointmentStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentStatusesQuery,
    AppointmentStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentStatusesQuery, AppointmentStatusesQueryVariables>(
    AppointmentStatusesDocument,
    options,
  );
}
export type AppointmentStatusesQueryHookResult = ReturnType<typeof useAppointmentStatusesQuery>;
export type AppointmentStatusesLazyQueryHookResult = ReturnType<
  typeof useAppointmentStatusesLazyQuery
>;
export type AppointmentStatusesQueryResult = Apollo.QueryResult<
  AppointmentStatusesQuery,
  AppointmentStatusesQueryVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      id
      uuid
      email
      activeClinic {
        id
        emrInstance {
          instanceType
        }
        secureMailClinicId
        appointmentSource
      }
      clinics {
        id
        name
        emrInstance {
          instanceType
        }
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const CreateAppointmentPanelDocument = gql`
  mutation createAppointmentPanel($input: CreateAppointmentPanelInput!) {
    createAppointmentPanel(input: $input) {
      panel {
        id
      }
    }
  }
`;
export type CreateAppointmentPanelMutationFn = Apollo.MutationFunction<
  CreateAppointmentPanelMutation,
  CreateAppointmentPanelMutationVariables
>;

/**
 * __useCreateAppointmentPanelMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentPanelMutation, { data, loading, error }] = useCreateAppointmentPanelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentPanelMutation,
    CreateAppointmentPanelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentPanelMutation,
    CreateAppointmentPanelMutationVariables
  >(CreateAppointmentPanelDocument, options);
}
export type CreateAppointmentPanelMutationHookResult = ReturnType<
  typeof useCreateAppointmentPanelMutation
>;
export type CreateAppointmentPanelMutationResult =
  Apollo.MutationResult<CreateAppointmentPanelMutation>;
export type CreateAppointmentPanelMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentPanelMutation,
  CreateAppointmentPanelMutationVariables
>;
export const DeleteAppointmentPanelDocument = gql`
  mutation deleteAppointmentPanel($id: ID!) {
    deleteAppointmentPanel(id: $id)
  }
`;
export type DeleteAppointmentPanelMutationFn = Apollo.MutationFunction<
  DeleteAppointmentPanelMutation,
  DeleteAppointmentPanelMutationVariables
>;

/**
 * __useDeleteAppointmentPanelMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentPanelMutation, { data, loading, error }] = useDeleteAppointmentPanelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentPanelMutation,
    DeleteAppointmentPanelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppointmentPanelMutation,
    DeleteAppointmentPanelMutationVariables
  >(DeleteAppointmentPanelDocument, options);
}
export type DeleteAppointmentPanelMutationHookResult = ReturnType<
  typeof useDeleteAppointmentPanelMutation
>;
export type DeleteAppointmentPanelMutationResult =
  Apollo.MutationResult<DeleteAppointmentPanelMutation>;
export type DeleteAppointmentPanelMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentPanelMutation,
  DeleteAppointmentPanelMutationVariables
>;
export const MessageTemplatesDocument = gql`
  query messageTemplates {
    messageTemplates {
      id
      name
      subject
      type
      content
      global
    }
  }
`;

/**
 * __useMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<MessageTemplatesQuery, MessageTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageTemplatesQuery, MessageTemplatesQueryVariables>(
    MessageTemplatesDocument,
    options,
  );
}
export function useMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MessageTemplatesQuery, MessageTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageTemplatesQuery, MessageTemplatesQueryVariables>(
    MessageTemplatesDocument,
    options,
  );
}
export type MessageTemplatesQueryHookResult = ReturnType<typeof useMessageTemplatesQuery>;
export type MessageTemplatesLazyQueryHookResult = ReturnType<typeof useMessageTemplatesLazyQuery>;
export type MessageTemplatesQueryResult = Apollo.QueryResult<
  MessageTemplatesQuery,
  MessageTemplatesQueryVariables
>;
export const UpdateConfigurationDocument = gql`
  mutation updateConfiguration(
    $providerIds: [String]
    $emrDeclineAppointmentType: EmrDeclineAppointmentType
    $status: String
    $isPatientDeclineEnabled: Boolean
  ) {
    updateConfiguration(
      providerIds: $providerIds
      emrDeclineAppointmentType: $emrDeclineAppointmentType
      status: $status
      isPatientDeclineEnabled: $isPatientDeclineEnabled
    )
  }
`;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      providerIds: // value for 'providerIds'
 *      emrDeclineAppointmentType: // value for 'emrDeclineAppointmentType'
 *      status: // value for 'status'
 *      isPatientDeclineEnabled: // value for 'isPatientDeclineEnabled'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigurationMutation,
    UpdateConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(
    UpdateConfigurationDocument,
    options,
  );
}
export type UpdateConfigurationMutationHookResult = ReturnType<
  typeof useUpdateConfigurationMutation
>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
>;
export const ConfigurationDocument = gql`
  query configuration($source: AppointmentSource!) {
    configuration(source: $source) {
      providersPayLoad {
        allProviders {
          id
          descriptor
        }
        configuredProviders {
          id
          descriptor
        }
      }
      emrDeclineAppointmentConfiguration {
        type
        isPatientDeclineEnabled
        status
      }
    }
  }
`;

/**
 * __useConfigurationQuery__
 *
 * To run a query within a React component, call `useConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options,
  );
}
export function useConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options,
  );
}
export type ConfigurationQueryHookResult = ReturnType<typeof useConfigurationQuery>;
export type ConfigurationLazyQueryHookResult = ReturnType<typeof useConfigurationLazyQuery>;
export type ConfigurationQueryResult = Apollo.QueryResult<
  ConfigurationQuery,
  ConfigurationQueryVariables
>;
export const ProvidersDocument = gql`
  query providers($source: AppointmentSource!) {
    providers(source: $source) {
      allProviders {
        id
        descriptor
      }
      configuredProviders {
        id
        descriptor
      }
    }
  }
`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useProvidersQuery(
  baseOptions: Apollo.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
}
export function useProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProvidersQuery, ProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
}
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = Apollo.QueryResult<ProvidersQuery, ProvidersQueryVariables>;
export const MessageTemplateByIdDocument = gql`
  query messageTemplateById($id: ID!) {
    messageTemplateById(id: $id) {
      id
      content
      global
      subject
      name
      type
    }
  }
`;

/**
 * __useMessageTemplateByIdQuery__
 *
 * To run a query within a React component, call `useMessageTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageTemplateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<MessageTemplateByIdQuery, MessageTemplateByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageTemplateByIdQuery, MessageTemplateByIdQueryVariables>(
    MessageTemplateByIdDocument,
    options,
  );
}
export function useMessageTemplateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageTemplateByIdQuery,
    MessageTemplateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageTemplateByIdQuery, MessageTemplateByIdQueryVariables>(
    MessageTemplateByIdDocument,
    options,
  );
}
export type MessageTemplateByIdQueryHookResult = ReturnType<typeof useMessageTemplateByIdQuery>;
export type MessageTemplateByIdLazyQueryHookResult = ReturnType<
  typeof useMessageTemplateByIdLazyQuery
>;
export type MessageTemplateByIdQueryResult = Apollo.QueryResult<
  MessageTemplateByIdQuery,
  MessageTemplateByIdQueryVariables
>;
export const PatientFormsDocument = gql`
  query patientForms {
    patientForms {
      id
      createdAt
      content
    }
  }
`;

/**
 * __usePatientFormsQuery__
 *
 * To run a query within a React component, call `usePatientFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientFormsQuery, PatientFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientFormsQuery, PatientFormsQueryVariables>(
    PatientFormsDocument,
    options,
  );
}
export function usePatientFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientFormsQuery, PatientFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientFormsQuery, PatientFormsQueryVariables>(
    PatientFormsDocument,
    options,
  );
}
export type PatientFormsQueryHookResult = ReturnType<typeof usePatientFormsQuery>;
export type PatientFormsLazyQueryHookResult = ReturnType<typeof usePatientFormsLazyQuery>;
export type PatientFormsQueryResult = Apollo.QueryResult<
  PatientFormsQuery,
  PatientFormsQueryVariables
>;
export const PatientFormByIdDocument = gql`
  query patientFormById($id: ID!) {
    patientFormById(id: $id) {
      id
      createdAt
      content
    }
  }
`;

/**
 * __usePatientFormByIdQuery__
 *
 * To run a query within a React component, call `usePatientFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientFormByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientFormByIdQuery(
  baseOptions: Apollo.QueryHookOptions<PatientFormByIdQuery, PatientFormByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientFormByIdQuery, PatientFormByIdQueryVariables>(
    PatientFormByIdDocument,
    options,
  );
}
export function usePatientFormByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientFormByIdQuery, PatientFormByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientFormByIdQuery, PatientFormByIdQueryVariables>(
    PatientFormByIdDocument,
    options,
  );
}
export type PatientFormByIdQueryHookResult = ReturnType<typeof usePatientFormByIdQuery>;
export type PatientFormByIdLazyQueryHookResult = ReturnType<typeof usePatientFormByIdLazyQuery>;
export type PatientFormByIdQueryResult = Apollo.QueryResult<
  PatientFormByIdQuery,
  PatientFormByIdQueryVariables
>;
export const AppointmentPanelsDocument = gql`
  query appointmentPanels {
    appointmentPanels {
      id
      createdAt
      name
      description
      state
      appointmentSource
      appointmentTypeIds
      providerIds
      appointmentConfirmation
      reminderInDays
      immediateReminder
      emailMessageTemplate {
        id
        name
        subject
        content
        global
      }
      securedMessageTemplate {
        id
        name
        subject
        content
        global
      }
    }
  }
`;

/**
 * __useAppointmentPanelsQuery__
 *
 * To run a query within a React component, call `useAppointmentPanelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentPanelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentPanelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentPanelsQuery(
  baseOptions?: Apollo.QueryHookOptions<AppointmentPanelsQuery, AppointmentPanelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentPanelsQuery, AppointmentPanelsQueryVariables>(
    AppointmentPanelsDocument,
    options,
  );
}
export function useAppointmentPanelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentPanelsQuery,
    AppointmentPanelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentPanelsQuery, AppointmentPanelsQueryVariables>(
    AppointmentPanelsDocument,
    options,
  );
}
export type AppointmentPanelsQueryHookResult = ReturnType<typeof useAppointmentPanelsQuery>;
export type AppointmentPanelsLazyQueryHookResult = ReturnType<typeof useAppointmentPanelsLazyQuery>;
export type AppointmentPanelsQueryResult = Apollo.QueryResult<
  AppointmentPanelsQuery,
  AppointmentPanelsQueryVariables
>;
export const AppointmentPanelByIdDocument = gql`
  query appointmentPanelById($id: ID!) {
    appointmentPanelById(id: $id) {
      id
      createdAt
      name
      description
      state
      appointmentSource
      appointmentTypeIds
      appointmentConfirmation
      reminderInDays
      immediateReminder
      notificationPriority
      covidScreening
      emailMessageTemplate {
        id
        name
        subject
        content
        global
        type
      }
      confirmEmailMessageTemplate {
        id
        name
        subject
        content
        global
        type
      }
      providerIds
      securedMessageTemplate {
        id
        name
        subject
        content
        global
        type
      }
      smsMessageTemplate {
        id
        name
        subject
        content
        global
        type
      }
      confirmSmsMessageTemplate {
        id
        name
        subject
        content
        global
        type
      }
      attachments {
        id
        uuid
        filename
        mimeType
        size
      }
    }
  }
`;

/**
 * __useAppointmentPanelByIdQuery__
 *
 * To run a query within a React component, call `useAppointmentPanelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentPanelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentPanelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentPanelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentPanelByIdQuery,
    AppointmentPanelByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentPanelByIdQuery, AppointmentPanelByIdQueryVariables>(
    AppointmentPanelByIdDocument,
    options,
  );
}
export function useAppointmentPanelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentPanelByIdQuery,
    AppointmentPanelByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentPanelByIdQuery, AppointmentPanelByIdQueryVariables>(
    AppointmentPanelByIdDocument,
    options,
  );
}
export type AppointmentPanelByIdQueryHookResult = ReturnType<typeof useAppointmentPanelByIdQuery>;
export type AppointmentPanelByIdLazyQueryHookResult = ReturnType<
  typeof useAppointmentPanelByIdLazyQuery
>;
export type AppointmentPanelByIdQueryResult = Apollo.QueryResult<
  AppointmentPanelByIdQuery,
  AppointmentPanelByIdQueryVariables
>;
export const PatientAppointmentByAccessTokenDocument = gql`
  query patientAppointmentByAccessToken($accessToken: String!) {
    patientAppointmentByAccessToken(accessToken: $accessToken) {
      id
      appointmentId
      startDate {
        dateTime
        date
        time
      }
      status
      patient {
        firstName
        lastName
        birthDate
        gender
        email
        sms
      }
      provider {
        id
        descriptor
      }
      patientAppointmentResponse
      responseDateTime
      responseReason
      viewedDateTime
      clinic {
        id
        name
      }
      reminders {
        sentDate
        panelName
        deliveryState
        channel
        patientAppointmentId
        id
      }
      securedContent
      covidScreening {
        form {
          id
          url
          createdAt
          content
          submissionDate
          result
          reportUrl
        }
      }
      attachments {
        id
        uuid
        filename
        mimeType
        size
      }
      isPatientDeclineEnabled
      emrDeclineAppointmentType
    }
  }
`;

/**
 * __usePatientAppointmentByAccessTokenQuery__
 *
 * To run a query within a React component, call `usePatientAppointmentByAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppointmentByAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppointmentByAccessTokenQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function usePatientAppointmentByAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientAppointmentByAccessTokenQuery,
    PatientAppointmentByAccessTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientAppointmentByAccessTokenQuery,
    PatientAppointmentByAccessTokenQueryVariables
  >(PatientAppointmentByAccessTokenDocument, options);
}
export function usePatientAppointmentByAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientAppointmentByAccessTokenQuery,
    PatientAppointmentByAccessTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientAppointmentByAccessTokenQuery,
    PatientAppointmentByAccessTokenQueryVariables
  >(PatientAppointmentByAccessTokenDocument, options);
}
export type PatientAppointmentByAccessTokenQueryHookResult = ReturnType<
  typeof usePatientAppointmentByAccessTokenQuery
>;
export type PatientAppointmentByAccessTokenLazyQueryHookResult = ReturnType<
  typeof usePatientAppointmentByAccessTokenLazyQuery
>;
export type PatientAppointmentByAccessTokenQueryResult = Apollo.QueryResult<
  PatientAppointmentByAccessTokenQuery,
  PatientAppointmentByAccessTokenQueryVariables
>;
export const AppointmentByIdDocument = gql`
  query appointmentById($appointmentId: ID!) {
    appointmentById(appointmentId: $appointmentId) {
      id
      appointmentId
      startDate {
        dateTime
        date
        time
      }
      status
      appointmentType {
        id
        descriptor
        defaultDurationInMinutes
      }
      patient {
        firstName
        lastName
        birthDate
        gender
        email
        sms
      }
      provider {
        id
        descriptor
      }
      patientAppointmentResponse
      responseDateTime
      responseReason
      viewedDateTime
      clinic {
        id
        name
      }
      concerns
      reminders {
        sentDate
        panelName
        deliveryState
        channel
        patientAppointmentId
        id
      }
      securedContent
    }
  }
`;

/**
 * __useAppointmentByIdQuery__
 *
 * To run a query within a React component, call `useAppointmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentByIdQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentByIdQuery, AppointmentByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentByIdQuery, AppointmentByIdQueryVariables>(
    AppointmentByIdDocument,
    options,
  );
}
export function useAppointmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentByIdQuery, AppointmentByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentByIdQuery, AppointmentByIdQueryVariables>(
    AppointmentByIdDocument,
    options,
  );
}
export type AppointmentByIdQueryHookResult = ReturnType<typeof useAppointmentByIdQuery>;
export type AppointmentByIdLazyQueryHookResult = ReturnType<typeof useAppointmentByIdLazyQuery>;
export type AppointmentByIdQueryResult = Apollo.QueryResult<
  AppointmentByIdQuery,
  AppointmentByIdQueryVariables
>;
export const RemindersSummaryByDateDocument = gql`
  query remindersSummaryByDate($from: Date!, $to: Date!, $panelId: ID) {
    remindersSummaryByDate(from: $from, to: $to, panelId: $panelId) {
      totalSuccess
      totalFailure
      smsSuccess
      smsFailure
      emailSuccess
      emailFailure
    }
  }
`;

/**
 * __useRemindersSummaryByDateQuery__
 *
 * To run a query within a React component, call `useRemindersSummaryByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersSummaryByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersSummaryByDateQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useRemindersSummaryByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemindersSummaryByDateQuery,
    RemindersSummaryByDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemindersSummaryByDateQuery, RemindersSummaryByDateQueryVariables>(
    RemindersSummaryByDateDocument,
    options,
  );
}
export function useRemindersSummaryByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemindersSummaryByDateQuery,
    RemindersSummaryByDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemindersSummaryByDateQuery, RemindersSummaryByDateQueryVariables>(
    RemindersSummaryByDateDocument,
    options,
  );
}
export type RemindersSummaryByDateQueryHookResult = ReturnType<
  typeof useRemindersSummaryByDateQuery
>;
export type RemindersSummaryByDateLazyQueryHookResult = ReturnType<
  typeof useRemindersSummaryByDateLazyQuery
>;
export type RemindersSummaryByDateQueryResult = Apollo.QueryResult<
  RemindersSummaryByDateQuery,
  RemindersSummaryByDateQueryVariables
>;
export const RemindersByDateAndNamesDocument = gql`
  query remindersByDateAndNames($from: Date!, $to: Date!, $panelId: ID) {
    remindersByDateAndNames(from: $from, to: $to, panelId: $panelId) {
      id
      sentDate
      panelName
      deliveryState
      channel
      patientAppointmentId
      patient {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useRemindersByDateAndNamesQuery__
 *
 * To run a query within a React component, call `useRemindersByDateAndNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersByDateAndNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersByDateAndNamesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useRemindersByDateAndNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemindersByDateAndNamesQuery,
    RemindersByDateAndNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemindersByDateAndNamesQuery, RemindersByDateAndNamesQueryVariables>(
    RemindersByDateAndNamesDocument,
    options,
  );
}
export function useRemindersByDateAndNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemindersByDateAndNamesQuery,
    RemindersByDateAndNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemindersByDateAndNamesQuery, RemindersByDateAndNamesQueryVariables>(
    RemindersByDateAndNamesDocument,
    options,
  );
}
export type RemindersByDateAndNamesQueryHookResult = ReturnType<
  typeof useRemindersByDateAndNamesQuery
>;
export type RemindersByDateAndNamesLazyQueryHookResult = ReturnType<
  typeof useRemindersByDateAndNamesLazyQuery
>;
export type RemindersByDateAndNamesQueryResult = Apollo.QueryResult<
  RemindersByDateAndNamesQuery,
  RemindersByDateAndNamesQueryVariables
>;
export const RemindersByAppointmentIdDocument = gql`
  query remindersByAppointmentId($panelId: ID!, $appointmentId: String) {
    remindersByAppointmentId(panelId: $panelId, appointmentId: $appointmentId) {
      id
      sentDate
      panelName
      deliveryState
      channel
      patientAppointmentId
    }
  }
`;

/**
 * __useRemindersByAppointmentIdQuery__
 *
 * To run a query within a React component, call `useRemindersByAppointmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersByAppointmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersByAppointmentIdQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useRemindersByAppointmentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemindersByAppointmentIdQuery,
    RemindersByAppointmentIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemindersByAppointmentIdQuery, RemindersByAppointmentIdQueryVariables>(
    RemindersByAppointmentIdDocument,
    options,
  );
}
export function useRemindersByAppointmentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemindersByAppointmentIdQuery,
    RemindersByAppointmentIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemindersByAppointmentIdQuery, RemindersByAppointmentIdQueryVariables>(
    RemindersByAppointmentIdDocument,
    options,
  );
}
export type RemindersByAppointmentIdQueryHookResult = ReturnType<
  typeof useRemindersByAppointmentIdQuery
>;
export type RemindersByAppointmentIdLazyQueryHookResult = ReturnType<
  typeof useRemindersByAppointmentIdLazyQuery
>;
export type RemindersByAppointmentIdQueryResult = Apollo.QueryResult<
  RemindersByAppointmentIdQuery,
  RemindersByAppointmentIdQueryVariables
>;
export const UpdatePatientAppointmentResponseDocument = gql`
  mutation updatePatientAppointmentResponse(
    $patientAppointmentId: ID!
    $appointmentResponse: PatientAppointmentResponse
    $reason: String!
  ) {
    updatePatientAppointmentResponse(
      patientAppointmentId: $patientAppointmentId
      appointmentResponse: $appointmentResponse
      reason: $reason
    )
  }
`;
export type UpdatePatientAppointmentResponseMutationFn = Apollo.MutationFunction<
  UpdatePatientAppointmentResponseMutation,
  UpdatePatientAppointmentResponseMutationVariables
>;

/**
 * __useUpdatePatientAppointmentResponseMutation__
 *
 * To run a mutation, you first call `useUpdatePatientAppointmentResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientAppointmentResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientAppointmentResponseMutation, { data, loading, error }] = useUpdatePatientAppointmentResponseMutation({
 *   variables: {
 *      patientAppointmentId: // value for 'patientAppointmentId'
 *      appointmentResponse: // value for 'appointmentResponse'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useUpdatePatientAppointmentResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientAppointmentResponseMutation,
    UpdatePatientAppointmentResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientAppointmentResponseMutation,
    UpdatePatientAppointmentResponseMutationVariables
  >(UpdatePatientAppointmentResponseDocument, options);
}
export type UpdatePatientAppointmentResponseMutationHookResult = ReturnType<
  typeof useUpdatePatientAppointmentResponseMutation
>;
export type UpdatePatientAppointmentResponseMutationResult =
  Apollo.MutationResult<UpdatePatientAppointmentResponseMutation>;
export type UpdatePatientAppointmentResponseMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientAppointmentResponseMutation,
  UpdatePatientAppointmentResponseMutationVariables
>;
export const UpdateAppointmentPanelDocument = gql`
  mutation updateAppointmentPanel($input: UpdateAppointmentPanelInput!) {
    updateAppointmentPanel(input: $input) {
      panel {
        id
      }
    }
  }
`;
export type UpdateAppointmentPanelMutationFn = Apollo.MutationFunction<
  UpdateAppointmentPanelMutation,
  UpdateAppointmentPanelMutationVariables
>;

/**
 * __useUpdateAppointmentPanelMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentPanelMutation, { data, loading, error }] = useUpdateAppointmentPanelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentPanelMutation,
    UpdateAppointmentPanelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentPanelMutation,
    UpdateAppointmentPanelMutationVariables
  >(UpdateAppointmentPanelDocument, options);
}
export type UpdateAppointmentPanelMutationHookResult = ReturnType<
  typeof useUpdateAppointmentPanelMutation
>;
export type UpdateAppointmentPanelMutationResult =
  Apollo.MutationResult<UpdateAppointmentPanelMutation>;
export type UpdateAppointmentPanelMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentPanelMutation,
  UpdateAppointmentPanelMutationVariables
>;
export const CreatePatientFormDocument = gql`
  mutation createPatientForm($input: CreatePatientFormInput!) {
    createPatientForm(input: $input)
  }
`;
export type CreatePatientFormMutationFn = Apollo.MutationFunction<
  CreatePatientFormMutation,
  CreatePatientFormMutationVariables
>;

/**
 * __useCreatePatientFormMutation__
 *
 * To run a mutation, you first call `useCreatePatientFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientFormMutation, { data, loading, error }] = useCreatePatientFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatientFormMutation,
    CreatePatientFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatientFormMutation, CreatePatientFormMutationVariables>(
    CreatePatientFormDocument,
    options,
  );
}
export type CreatePatientFormMutationHookResult = ReturnType<typeof useCreatePatientFormMutation>;
export type CreatePatientFormMutationResult = Apollo.MutationResult<CreatePatientFormMutation>;
export type CreatePatientFormMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientFormMutation,
  CreatePatientFormMutationVariables
>;
export const UpdatePatientFormDocument = gql`
  mutation updatePatientForm($input: UpdatePatientFormInput!) {
    updatePatientForm(input: $input)
  }
`;
export type UpdatePatientFormMutationFn = Apollo.MutationFunction<
  UpdatePatientFormMutation,
  UpdatePatientFormMutationVariables
>;

/**
 * __useUpdatePatientFormMutation__
 *
 * To run a mutation, you first call `useUpdatePatientFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientFormMutation, { data, loading, error }] = useUpdatePatientFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientFormMutation,
    UpdatePatientFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePatientFormMutation, UpdatePatientFormMutationVariables>(
    UpdatePatientFormDocument,
    options,
  );
}
export type UpdatePatientFormMutationHookResult = ReturnType<typeof useUpdatePatientFormMutation>;
export type UpdatePatientFormMutationResult = Apollo.MutationResult<UpdatePatientFormMutation>;
export type UpdatePatientFormMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientFormMutation,
  UpdatePatientFormMutationVariables
>;
export const CreateMessageTemplateDocument = gql`
  mutation createMessageTemplate($input: CreateMessageTemplateInput!) {
    createMessageTemplate(input: $input)
  }
`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(
    CreateMessageTemplateDocument,
    options,
  );
}
export type CreateMessageTemplateMutationHookResult = ReturnType<
  typeof useCreateMessageTemplateMutation
>;
export type CreateMessageTemplateMutationResult =
  Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;
export const UpdateMessageTemplateDocument = gql`
  mutation updateMessageTemplate($input: UpdateMessageTemplateInput!) {
    updateMessageTemplate(input: $input)
  }
`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageTemplateMutation,
    UpdateMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(
    UpdateMessageTemplateDocument,
    options,
  );
}
export type UpdateMessageTemplateMutationHookResult = ReturnType<
  typeof useUpdateMessageTemplateMutation
>;
export type UpdateMessageTemplateMutationResult =
  Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>;
export const RequestInviteDocument = gql`
  mutation requestInvite($accessToken: String!, $email: String!) {
    requestInvite(accessToken: $accessToken, email: $email)
  }
`;
export type RequestInviteMutationFn = Apollo.MutationFunction<
  RequestInviteMutation,
  RequestInviteMutationVariables
>;

/**
 * __useRequestInviteMutation__
 *
 * To run a mutation, you first call `useRequestInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInviteMutation, { data, loading, error }] = useRequestInviteMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestInviteMutation, RequestInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestInviteMutation, RequestInviteMutationVariables>(
    RequestInviteDocument,
    options,
  );
}
export type RequestInviteMutationHookResult = ReturnType<typeof useRequestInviteMutation>;
export type RequestInviteMutationResult = Apollo.MutationResult<RequestInviteMutation>;
export type RequestInviteMutationOptions = Apollo.BaseMutationOptions<
  RequestInviteMutation,
  RequestInviteMutationVariables
>;
export const CancelAppointmentDocument = gql`
  mutation cancelAppointment($id: ID!) {
    cancelAppointment(id: $id)
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
    CancelAppointmentDocument,
    options,
  );
}
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const PaymentInvoicesDocument = gql`
  query paymentInvoices {
    paymentInvoices {
      paymentDate
      remindersSuccess
      remindersFailed
      paymentTotal
      paymentStatus
      invoiceUrl
    }
  }
`;

/**
 * __usePaymentInvoicesQuery__
 *
 * To run a query within a React component, call `usePaymentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>(
    PaymentInvoicesDocument,
    options,
  );
}
export function usePaymentInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>(
    PaymentInvoicesDocument,
    options,
  );
}
export type PaymentInvoicesQueryHookResult = ReturnType<typeof usePaymentInvoicesQuery>;
export type PaymentInvoicesLazyQueryHookResult = ReturnType<typeof usePaymentInvoicesLazyQuery>;
export type PaymentInvoicesQueryResult = Apollo.QueryResult<
  PaymentInvoicesQuery,
  PaymentInvoicesQueryVariables
>;
export const BillingPeriodDocument = gql`
  query billingPeriod {
    billingPeriod {
      startDate
      endDate
      currentUsage
      usageLimit
    }
  }
`;

/**
 * __useBillingPeriodQuery__
 *
 * To run a query within a React component, call `useBillingPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingPeriodQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingPeriodQuery, BillingPeriodQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPeriodQuery, BillingPeriodQueryVariables>(
    BillingPeriodDocument,
    options,
  );
}
export function useBillingPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingPeriodQuery, BillingPeriodQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingPeriodQuery, BillingPeriodQueryVariables>(
    BillingPeriodDocument,
    options,
  );
}
export type BillingPeriodQueryHookResult = ReturnType<typeof useBillingPeriodQuery>;
export type BillingPeriodLazyQueryHookResult = ReturnType<typeof useBillingPeriodLazyQuery>;
export type BillingPeriodQueryResult = Apollo.QueryResult<
  BillingPeriodQuery,
  BillingPeriodQueryVariables
>;
export const UploadUrlDocument = gql`
  query uploadUrl($uuid: String!, $mimeType: String!) {
    uploadUrl(uuid: $uuid, mimeType: $mimeType)
  }
`;

/**
 * __useUploadUrlQuery__
 *
 * To run a query within a React component, call `useUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadUrlQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<UploadUrlQuery, UploadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UploadUrlQuery, UploadUrlQueryVariables>(UploadUrlDocument, options);
}
export function useUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UploadUrlQuery, UploadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UploadUrlQuery, UploadUrlQueryVariables>(UploadUrlDocument, options);
}
export type UploadUrlQueryHookResult = ReturnType<typeof useUploadUrlQuery>;
export type UploadUrlLazyQueryHookResult = ReturnType<typeof useUploadUrlLazyQuery>;
export type UploadUrlQueryResult = Apollo.QueryResult<UploadUrlQuery, UploadUrlQueryVariables>;
export const DownloadUrlDocument = gql`
  query downloadUrl($attachmentId: Int!) {
    downloadUrl(attachmentId: $attachmentId)
  }
`;

/**
 * __useDownloadUrlQuery__
 *
 * To run a query within a React component, call `useDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadUrlQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadUrlQuery, DownloadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadUrlQuery, DownloadUrlQueryVariables>(DownloadUrlDocument, options);
}
export function useDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadUrlQuery, DownloadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadUrlQuery, DownloadUrlQueryVariables>(
    DownloadUrlDocument,
    options,
  );
}
export type DownloadUrlQueryHookResult = ReturnType<typeof useDownloadUrlQuery>;
export type DownloadUrlLazyQueryHookResult = ReturnType<typeof useDownloadUrlLazyQuery>;
export type DownloadUrlQueryResult = Apollo.QueryResult<
  DownloadUrlQuery,
  DownloadUrlQueryVariables
>;
export const SetActiveClinicDocument = gql`
  mutation setActiveClinic($clinicId: ID!) {
    setActiveClinic(clinicId: $clinicId)
  }
`;
export type SetActiveClinicMutationFn = Apollo.MutationFunction<
  SetActiveClinicMutation,
  SetActiveClinicMutationVariables
>;

/**
 * __useSetActiveClinicMutation__
 *
 * To run a mutation, you first call `useSetActiveClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveClinicMutation, { data, loading, error }] = useSetActiveClinicMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSetActiveClinicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetActiveClinicMutation,
    SetActiveClinicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetActiveClinicMutation, SetActiveClinicMutationVariables>(
    SetActiveClinicDocument,
    options,
  );
}
export type SetActiveClinicMutationHookResult = ReturnType<typeof useSetActiveClinicMutation>;
export type SetActiveClinicMutationResult = Apollo.MutationResult<SetActiveClinicMutation>;
export type SetActiveClinicMutationOptions = Apollo.BaseMutationOptions<
  SetActiveClinicMutation,
  SetActiveClinicMutationVariables
>;
