import ms from 'milliseconds';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import CommonUtils from '../util/CommonUtils';

const RENEW_URL = `${process.env.REACT_APP_AUTH_URL}/token`;

let renewInProcess = false;

export const clearAuth = () => {
  localStorage.removeItem('auth');
  localStorage.removeItem('offset');
};

export const getToken = (renewIfOutdated: boolean) => {
  let auth = localStorage.getItem('auth');
  const offset = parseInt(localStorage.getItem('offset') || '0');

  // check token expiry
  // trigger renew if last refresh > 15 mins ago
  if (auth) {
    const tokenObject = JSON.parse(auth);
    if (tokenObject && tokenObject.access_token) {
      const token = tokenObject.access_token;
      const decodedToken: JwtPayload = jwt_decode(token);
      const now = Date.now();
      const expiry = ms.seconds(Number(decodedToken.exp));
      if (expiry < now - offset) {
        clearAuth();
        window.location.reload();
      } else if (renewIfOutdated && isRenewNeeded(decodedToken) && tokenObject.refresh_token) {
        renewTokenAsync(tokenObject.refresh_token);
      }
      return token;
    }
  }
  return null;
};
const isRenewNeeded = (decodedToken: JwtPayload) => {
  const issuedTime: number = ms.seconds(Number(decodedToken.iat));
  const offset = parseInt(localStorage.getItem('offset') || '0');
  const now = Date.now();
  if (now - offset - issuedTime > ms.minutes(10)) {
    return true;
  }
  return false;
};

const renewTokenAsync = (token: string) => {
  if (renewInProcess) {
    return;
  }
  renewInProcess = true;

  const payload = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    grant_type: 'refresh_token',
    refresh_token: token,
  };

  fetch(RENEW_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: CommonUtils.toUrlEncoded(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      renewInProcess = false;
      if (data?.access_token) {
        window.localStorage.setItem('auth', JSON.stringify(data));
      }
    })
    .catch(() => {
      renewInProcess = false;
    });
};

export const logout = async (
  shouldEndSession: boolean = true,
  errorMsg?: string,
): Promise<boolean> => {
  window.localStorage.removeItem('pkce');
  window.localStorage.removeItem('auth');

  const clientId = process.env.REACT_APP_CLIENT_ID!;

  const provider = process.env.REACT_APP_AUTH_URL!;
  const redirectUri = process.env.REACT_APP_REDIRECT_URL!;

  if (shouldEndSession) {
    const query = {
      client_id: clientId,
      post_logout_redirect_uri: `${redirectUri}/logout`,
    };
    const url = `${provider}/logout?${CommonUtils.toUrlEncoded(query)}`;
    window.location.replace(url);
    return true;
  } else {
    window.location.replace(errorMsg ? '/logout?error=' + errorMsg : '/logout');
    return true;
  }
};
