import { AppsSwitcher } from '@brightsquid/bsqwidget';
import styled from '@emotion/styled';
import {
  Email,
  History,
  MonetizationOn,
  Schedule,
  SettingsOutlined,
  Update,
} from '@mui/icons-material';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../context/AppContext';

const drawerWidth = screen.width < 600 ? 40 : 260;

const StyledList = styled(List)(() => ({
  background: '#eef0f2',
}));

const StyledListItem = styled(ListItemButton)(() => ({
  display: 'flex',
  gap: 12,
  padding: 8,
}));

const StyledDivider = styled(Divider)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
}));

const styles = {
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    background: '#eef0f2',
    color: '#555',
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    width: 7,
  },
  title: {
    flexGrow: 1,
    color: '#555',
  },
};
const SideBar = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          ...styles.drawerPaper,
          ...(!open ? styles.drawerPaperClose : {}),
        },
      }}
      open={true}
    >
      <Box mt={2} pl={1} pr={2}>
        <Box mr={1} display="flex" alignItems={'center'} fontSize={'1rem'} width="100%" gap={0.5}>
          <AppsSwitcher orgId={currentUser?.activeClinic?.secureMailClinicId!} />
          <Box flex={1} display="flex" alignItems={'center'}>
            <img
              src="https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/brightsquid.svg"
              title="Brightsquid"
              alt="Brightsquid"
            />
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '4px 0px 16px 32px',
          }}
        >
          <img
            src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/Reminders_InAppLogo.png"
            title="Reminders"
            alt="Reminders"
          />
        </Box>
      </Box>
      <StyledDivider />
      <StyledList>
        <div>
          <StyledListItem
            onClick={() => {
              navigate('/appointment/list');
            }}
          >
            <Schedule /> <ListItemText primary="Appointment Reminders" />
          </StyledListItem>
        </div>
      </StyledList>
      <StyledDivider />
      <StyledList>
        <div>
          <StyledListItem
            onClick={() => {
              navigate('/templates');
            }}
          >
            <Email /> <ListItemText primary="Templates" />
          </StyledListItem>
        </div>
      </StyledList>
      <StyledDivider />
      <StyledList>
        <StyledListItem
          onClick={() => {
            navigate('/history');
          }}
        >
          <History /> <ListItemText primary="Logs" />
        </StyledListItem>
        <StyledListItem
          onClick={() => {
            navigate('/upcoming');
          }}
        >
          <Update /> <ListItemText primary="Upcoming Appointments" />
        </StyledListItem>
        <StyledListItem
          onClick={() => {
            navigate('/billing');
          }}
        >
          <MonetizationOn /> <ListItemText primary="Payment History" />
        </StyledListItem>
      </StyledList>
      <StyledDivider />
      <StyledList>
        <StyledListItem
          onClick={() => {
            navigate('/settings');
          }}
        >
          <SettingsOutlined /> <ListItemText primary="Clinic Settings" />
        </StyledListItem>
      </StyledList>
    </Drawer>
  );
};
export default SideBar;
