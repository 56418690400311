import { makeStyles } from '@mui/material/styles';
import { Box, Paper, Typography, TextField, Button } from '@mui/material';

import { useParams } from 'react-router';
import { FormEvent, useState } from 'react';
import { useSnackbar } from 'notistack';
import { SuccessPanel } from '../SuccessPanel';
import { Loading } from '../Loading';
import { useRequestInviteMutation } from '../../generated/graphql';

const styles = {
  paper: {
    marginTop: 2,
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'theme.palette.primary.main',
    color: 'white',
  },
  textboxParent: {
    background: 'white',
    padding: '5px',
    borderRadius: '4px',
  },
  textbox: {
    marginRight: '10px',
  },
};

export default function RequestInvite({
  userEmail,
  clinicName,
}: {
  userEmail: string;
  clinicName: string;
}) {
  const { accessToken } = useParams<any>();
  const [email, setEmail] = useState(userEmail);
  const [invited, setInvited] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [requestInvite, { loading }] = useRequestInviteMutation();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    requestInvite({
      variables: {
        accessToken: accessToken!,
        email,
      },
    })
      .then(() => {
        setInvited(true);
      })
      .catch(() => {
        enqueueSnackbar(
          'There was an error while submitting response, please try again or contact support.',
          {
            variant: 'error',
          },
        );
      });
  };

  let component = null;
  if (invited) {
    component = <SuccessPanel message="Please check your email for the details." />;
  } else if (loading) {
    component = <Loading message="Processing.." />;
  } else {
    component = (
      <Box ml={1}>
        <Typography variant="body2" component="p" gutterBottom>
          {clinicName} trusts Brightsquid to protect your private appointment details.
        </Typography>
        <br />
        <Typography variant="body2" component="p" gutterBottom>
          For secure communication with {clinicName} and other members of your healthcare community,
          request a free Brightsquid Secure-Mail account by entering your email address.
        </Typography>
        <br />
        <form onSubmit={handleSubmit}>
          <Box sx={styles.textboxParent} display="flex">
            <TextField
              sx={styles.textbox}
              required
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
            />
            <Button variant="outlined" type="submit">
              Request
            </Button>
          </Box>
        </form>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'theme.palette.primary.main',
        color: 'white',
      }}
    >
      {component}
    </Paper>
  );
}
