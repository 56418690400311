import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Box, TextField } from '@mui/material';

export default function DeclineAppointmentModal({
  open,
  setOpen,
  cancelAppointment,
}: {
  open: boolean;
  setOpen: Function;
  cancelAppointment: Function;
}) {
  const theme = useTheme();
  const [reason, setReason] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(null);
  };

  if (!open) {
    return null;
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={!!open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Are you sure you want to request cancellation ?
        </DialogTitle>
        <Box m={2}>
          <TextField
            label="Please provide a reason/note for cancellation (Optional)"
            fullWidth
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            variant="outlined"
          />
        </Box>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
            }}
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              cancelAppointment(reason);
            }}
            color="primary"
            autoFocus
          >
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
