import { PanelOrgSwitcher, LouIdentifier } from '@brightsquid/bsqwidget';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import { useSetActiveClinicMutation } from '../generated/graphql';

const drawerWidth = screen.width < 600 ? 40 : 260;

const styles = {
  appBar: {
    zIndex: 1,
    height: '54px !important',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    flexDirection: 'row-reverse',
    height: '54px !important',
    minHeight: '54px !important',
  },

  menuButton: {
    marginRight: 36,
  },

  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
};

const TopBar = () => {
  const { currentUser } = useContext(AppContext);
  const [setActiveClinic] = useSetActiveClinicMutation({
    onCompleted: () => {
      window.location.reload();
    },
  });

  return (
    <AppBar
      position="absolute"
      sx={{
        ...styles.appBar,
        ...styles.appBarShift,
      }}
    >
      <Toolbar sx={styles.toolbar}>
        <PanelOrgSwitcher
          clinics={(currentUser?.clinics || []).map((c) => {
            return { id: c!.id, name: c!.name };
          })}
          activeClinicId={currentUser.activeClinic.id}
          onClinicChange={(clinicId) => {
            setActiveClinic({
              variables: {
                clinicId,
              },
            });
          }}
        />
      </Toolbar>
      <LouIdentifier />
    </AppBar>
  );
};
export default TopBar;
