import { useState } from 'react';
import { Box, Container, TextField, Typography, Select, MenuItem } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import DashboardUtilities from '../../util/DashboardUtilities';
import { format } from 'date-fns';
import { Loading } from '../Loading';
import { Chart } from 'react-google-charts';
import { ErrorPanel } from '../ErrorPanel';
import { VisibilityOutlined } from '@mui/icons-material';
import AppointmentDetailModal from './AppointmentDetailModal';
import { FullScreenLoading } from '../FullScreenLoading';
import {
  Maybe,
  ReminderPayLoad,
  useAppointmentByIdQuery,
  useAppointmentPanelsQuery,
  useRemindersByDateAndNamesQuery,
  useRemindersSummaryByDateQuery,
} from '../../generated/graphql';

function Logs() {
  const dateRangeOptions = DashboardUtilities.getPastDateRangeFilter();
  const [search, setSearch] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const [selectedAppointmentId, setSelectedAppointment] = useState(queryParams.get('id') || null);

  const [selectedDateRange, setSelectedDateRange] = useState(
    localStorage.getItem('LOGS_TIME_RANGE') || 'Last 30 days',
  );
  const [selectedAppointmentPanelId, setSelectedAppointmentPanelId] = useState<string>(
    localStorage.getItem('SELECTED_PANEL') || '',
  );
  const { gte, lt } = dateRangeOptions.find((r) => r.value === selectedDateRange)!.dateRange;

  const { data: appointmentPanels, loading: appointmentPanelsLoading } =
    useAppointmentPanelsQuery();

  const { data: logsData, loading: logsLoading } = useRemindersByDateAndNamesQuery({
    fetchPolicy: 'network-only',
    variables: {
      from: format(gte, 'yyyy-MM-dd'),
      to: format(lt, 'yyyy-MM-dd'),
      panelId: selectedAppointmentPanelId,
    },
    skip: !selectedDateRange || !selectedAppointmentPanelId || !gte || !lt,
  });

  const { data: summaryData } = useRemindersSummaryByDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      from: format(gte, 'yyyy-MM-dd'),
      to: format(lt, 'yyyy-MM-dd'),
      panelId: selectedAppointmentPanelId,
    },
    skip: !selectedDateRange || !selectedAppointmentPanelId || !gte || !lt,
  });

  const { data: appointment, loading: appointmentLoading } = useAppointmentByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      appointmentId: selectedAppointmentId!,
    },
    skip: !selectedAppointmentId,
  });

  const columns: GridColDef[] = [
    { field: 'patientAppointmentId', headerName: 'Appointment Id', hide: true, flex: 1 },
    { field: 'sentDate', headerName: 'Date/Time', flex: 1 },
    { field: 'panelName', headerName: 'Appointment Reminder Name', hide: true, flex: 1 },
    { field: 'deliveryState', headerName: 'Delivery Status', flex: 1 },
    { field: 'patientName', headerName: 'Patient Name', flex: 1 },
    { field: 'channel', headerName: 'Email/SMS', flex: 1 },
    {
      field: 'view',
      headerName: ' ',
      width: 120,
      // eslint-disable-next-line react/display-name
      renderCell: (params: any) => {
        return (
          <>
            <VisibilityOutlined
              style={{ cursor: 'pointer', marginRight: '5px' }}
              color="action"
              titleAccess="View Details"
              onClick={() => {
                setSelectedAppointment(params.row.patientAppointmentId);
              }}
            ></VisibilityOutlined>
          </>
        );
      },
    },
  ];

  let rows =
    logsData?.remindersByDateAndNames?.map((r: Maybe<ReminderPayLoad>) => {
      const { id, sentDate, panelName, deliveryState, channel, patientAppointmentId, patient } = r!;
      return {
        id,
        patientAppointmentId,
        sentDate: sentDate && format(new Date(sentDate), 'yyyy-MM-dd HH:mm a'),
        panelName,
        deliveryState,
        channel,
        patientName: `${patient?.firstName || ''} ${patient?.lastName || ''}`,
      };
    }) || [];

  if (search) {
    rows = rows.filter((row) => {
      for (const v of Object.values(row)) {
        if (v && v.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return true;
        }
      }
      return false;
    });
  }

  if (appointmentPanelsLoading) {
    return <Loading message="Loading Appointment Panels..." />;
  }

  let charts = null;
  if (summaryData) {
    const { emailFailure, emailSuccess, smsFailure, smsSuccess } =
      summaryData?.remindersSummaryByDate!;

    charts = (
      <Box display="flex">
        <Chart
          width={'33%'}
          height="200px"
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Type', 'Count'],
            ['Email', (emailFailure || 0) + (emailSuccess || 0)],
            ['SMS', (smsFailure || 0) + (smsSuccess || 0)],
          ]}
          options={{
            pieSliceText: 'label',
            title: 'Email vs SMS',
            pieStartAngle: 100,
            backgroundColor: '#fafafa',
            style: {
              border: '2px solid #fff',
            },
          }}
        />
        <Chart
          width={'33%'}
          height="200px"
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Type', 'Count'],
            ['Success', smsSuccess],
            ['Failure', smsFailure],
          ]}
          options={{
            pieSliceText: 'label',
            title: 'SMS : Delivered vs Undelivered',
            pieStartAngle: 100,
            backgroundColor: '#fafafa',
            style: {
              border: '2px solid #fff',
            },
          }}
        />
        <Chart
          width={'33%'}
          height="200px"
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Type', 'Count'],
            ['Success', emailSuccess],
            ['Failure', emailFailure],
          ]}
          options={{
            pieSliceText: 'label',
            title: 'Email : Delivered vs Undelivered',
            pieStartAngle: 100,
            backgroundColor: '#fafafa',
            style: {
              border: '2px solid #fff',
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Box width={'100%'}>
      {!selectedAppointmentPanelId && <ErrorPanel message="Please select an Appointment Panel" />}
      <Typography component="h1" variant="h4" align="left">
        Logs
      </Typography>
      <br />
      <Box display="flex" mt={1} mb={1}>
        <Box flex="1" display="flex">
          <Select
            style={{ marginRight: '1rem' }}
            variant="outlined"
            margin="dense"
            color="primary"
            autoWidth
            value={selectedDateRange}
            onChange={(e) => {
              const id = e.target.value as string;
              localStorage.setItem('LOGS_TIME_RANGE', id);
              setSelectedDateRange(id);
            }}
          >
            {dateRangeOptions.map((o, i) => {
              return (
                <MenuItem key={i} value={o.value}>
                  {o.value}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            variant="outlined"
            margin="dense"
            displayEmpty
            color="primary"
            value={selectedAppointmentPanelId}
            onChange={(e) => {
              const id = e.target.value as string;
              localStorage.setItem('SELECTED_PANEL', id);
              setSelectedAppointmentPanelId(id);
            }}
          >
            <MenuItem value="">Select Appointment Panel</MenuItem>
            {appointmentPanels?.appointmentPanels?.map((r) => {
              return (
                <MenuItem key={r?.id!} value={r?.id!}>
                  {r?.name!}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <TextField
          type="search"
          margin="none"
          size="small"
          placeholder="Search..."
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        {charts}
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooterPagination
          hideFooter
          autoHeight
          loading={!!logsLoading}
        />
      </Box>

      {appointmentLoading ? (
        <FullScreenLoading />
      ) : (
        <AppointmentDetailModal
          appointment={appointment?.appointmentById!}
          onHide={() => setSelectedAppointment(null)}
        />
      )}
    </Box>
  );
}

export default Logs;
