import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

export const FullScreenLoading = ({ message }: { message?: String }) => {
  return (
    <Backdrop style={{ zIndex: 1 }} open>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <CircularProgress color="inherit" />
        </Box>
        <Typography variant="h6">{message || 'Loading...'}</Typography>
      </Box>
    </Backdrop>
  );
};
