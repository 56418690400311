import { Box, FormLabel, makeStyles, Typography } from '@mui/material';
import { differenceInYears, format, parseISO } from 'date-fns';
import { Patient } from '../../generated/graphql';

export default function PatientDetails({ patient }: { patient?: Patient | null }) {
  if (!patient) return null;
  const { birthDate, gender, email, sms } = patient;

  let genderStr = '';
  if (gender) {
    switch (patient.gender) {
      case 'M':
        genderStr = 'Male';
        break;
      case 'F':
        genderStr = 'Female';
        break;
      case 'U':
        genderStr = 'Unspecified';
        break;
      default:
        break;
    }
  }
  return (
    <Box mr={1} mt={1}>
      <Typography>
        {birthDate && (
          <Box>
            <FormLabel sx={{ minWidth: '60px', display: 'inline-block' }}>DOB:</FormLabel>{' '}
            {format(parseISO(birthDate), 'MMM d, yyyy')} (
            {differenceInYears(new Date(), new Date(birthDate))}Y)
          </Box>
        )}
        {genderStr && (
          <Box>
            <FormLabel sx={{ minWidth: '60px', display: 'inline-block' }}>Gender:</FormLabel>{' '}
            {genderStr}
          </Box>
        )}
        {email && (
          <Box>
            <FormLabel sx={{ minWidth: '60px', display: 'inline-block' }}>Email:</FormLabel> {email}
          </Box>
        )}
        {sms && (
          <Box>
            <FormLabel sx={{ minWidth: '60px', display: 'inline-block' }}>SMS:</FormLabel> {sms}
          </Box>
        )}
      </Typography>
    </Box>
  );
}
