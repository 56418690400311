import { Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useAuth } from '@brightsquid/react-oauth2-pkce';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { Loading } from './Loading';

export default function LandingPage() {
  const { authService } = useAuth();

  const { search } = useLocation();
  const hasCode = new URLSearchParams(search).has('code');

  useEffect(() => {
    if (!hasCode) {
      authService.authorize();
    }

    return () => {};
  }, [authService, hasCode]);

  return (
    <Container component="main" maxWidth="sm" style={{ padding: 48, textAlign: 'center' }}>
      <Loading message={'Redirecting ...'} />
      <Typography style={{ marginTop: 48 }}>
        Click{' '}
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.location.reload();
          }}
        >
          here
        </Link>{' '}
        to refresh if its taking too much time.
      </Typography>
      <Footer />
    </Container>
  );
}
