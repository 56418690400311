import { Box, Chip, Container, Grid, LinearProgress, Typography, Paper } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Alert } from '@mui/lab';

import { format } from 'date-fns';
import { usePaymentInvoicesQuery, useBillingPeriodQuery } from '../../generated/graphql';

function Billing() {
  const { data: paymentInvoicesData, loading } = usePaymentInvoicesQuery();
  const { data: billingPeriod } = useBillingPeriodQuery();

  const dollarRenderer = (params: GridCellParams) => <>${Number(params.value) / 100}</>;

  const renderChip = (params: GridCellParams) => {
    return (
      <Chip
        size="small"
        label={String(params.value)?.toUpperCase()}
        clickable
        color={params.value === 'paid' ? 'primary' : 'secondary'}
      />
    );
  };

  const columns: GridColDef[] = [
    { field: 'paymentDate', headerName: 'Payment Date', flex: 1 },
    { field: 'paymentTotal', headerName: 'Total', flex: 1, renderCell: dollarRenderer },
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 1,
      renderCell: renderChip,
      align: 'right',
      headerAlign: 'right',
      cellClassName: 'mr-5',
    },
  ];

  const rows =
    paymentInvoicesData?.paymentInvoices?.map((invoice) => {
      const {
        paymentDate,
        remindersSuccess,
        remindersFailed,
        paymentTotal,
        paymentStatus,
        invoiceUrl,
      } = invoice!;

      return {
        id: paymentDate,
        paymentDate: format(new Date(paymentDate), 'MMM dd, yyyy'),
        remindersSuccess,
        remindersFailed,
        paymentTotal,
        paymentStatus,
        invoiceUrl,
      };
    }) || [];

  let usage = 0;
  let limit = null;
  let alert = null;

  let billingPeriodString = null;
  if (billingPeriod?.billingPeriod?.startDate) {
    const { startDate, endDate, currentUsage, usageLimit } = billingPeriod?.billingPeriod;
    const startDateStr = format(new Date(startDate), 'MMM dd');
    const endDateStr = format(new Date(endDate), 'MMM dd');
    billingPeriodString = `(${startDateStr} - ${endDateStr})`;
    if (currentUsage) {
      usage = currentUsage;
    }
    limit = usageLimit;
    if (limit) {
      const diff = limit - usage;
      if (usage >= limit) {
        alert = (
          <Alert severity="error">
            You have reached your limit for appointment reminders this month. Reminders are paused
            until {endDateStr}. To unpause reminders, increase your allotment by contacting
            support@brightsquid.com.
          </Alert>
        );
      } else if (usage / limit >= 0.95) {
        alert = (
          <Alert severity="warning">
            You have {diff} appointment reminders left this month. Your allotment will reset on{' '}
            {endDateStr}. Once your limit is reached, appointment reminders will be paused for this
            account. You may also increase your allotment by contacting support@brightsquid.com.
          </Alert>
        );
      } else if (usage / limit >= 0.9) {
        alert = (
          <Alert severity="warning">
            You have {diff} appointment reminders left this month. Your allotment will reset on{' '}
            {endDateStr}. Once your limit is reached, no more reminders will be sent. You may also
            increase your allotment by contacting support@brightsquid.com.
          </Alert>
        );
      } else if (usage / limit >= 0.85) {
        alert = (
          <Alert severity="warning">
            You have {diff} reminders left this month. Your allotment will reset on {endDateStr}.
          </Alert>
        );
      }
    }
  }

  return (
    <Box width={'100%'}>
      {limit && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {alert}
            <br />
            <Paper style={{ padding: '2rem' }}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Current Usage {billingPeriodString}
              </Typography>
              <LinearProgress value={(usage * 100) / limit} variant="determinate" />
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Sent {usage} of {limit} reminders for the billing period
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <Typography component="h1" variant="h4" align="center">
        Payment History
      </Typography>

      <Box mt={1}>
        <DataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          hideFooterPagination
          hideFooter
          autoHeight
          onRowClick={(params) => {
            window.open(`${params.row.invoiceUrl}`);
          }}
        />
      </Box>
    </Box>
  );
}

export default Billing;
