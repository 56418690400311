import { ServerError } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PatientAppointmentResponse,
  usePatientAppointmentByAccessTokenQuery,
  useUpdatePatientAppointmentResponseMutation,
} from '../../generated/graphql';
import { PanelError } from '../../types/patient/PanelErrors';
import { Loading } from '../Loading';
import AppointmentPage from './AppointmentPage';
import DOBComponent from './DOBComponent';

export default function PatientPage({ dob, setDob }: { dob: string; setDob: Function }) {
  const { accessToken } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data,
    loading: loadingSecuredContent,
    refetch,
  } = usePatientAppointmentByAccessTokenQuery({
    variables: {
      accessToken: accessToken!,
    },
    fetchPolicy: 'network-only',
    skip: !dob,
    onCompleted: () => {},
    onError: (error) => {
      const errors = (error?.networkError as ServerError)?.result?.errors || error.graphQLErrors;
      if (errors) {
        errors.map((e: PanelError) => {
          const { errorCode } = e?.extensions;
          if (errorCode === 10002) {
            enqueueSnackbar(
              'You have exceeded maximum number of tries. Please contact clinic or support.',
              {
                variant: 'error',
              },
            );
          } else if (errorCode === 10003) {
            enqueueSnackbar(
              'Unable to validate Date of Birth, Please provide correct Date of Birth.',
              {
                variant: 'error',
              },
            );
          } else if (errorCode === 10104) {
            setExpired(true);
          } else {
            enqueueSnackbar('An error occured, please contact Support', {
              variant: 'error',
            });
          }
        });
      } else {
        enqueueSnackbar('An error occured, please contact Support', {
          variant: 'error',
        });
      }
    },
  });
  const [updatePatientResponse] = useUpdatePatientAppointmentResponseMutation({});
  const [expired, setExpired] = useState(false);

  const submitDob = (dob: String) => {
    if (dob && accessToken) {
      setDob(dob);
      refetch();
    }
  };

  const submitAppointmentResponse = (
    patientAppointmentId: string,
    appointmentResponse: PatientAppointmentResponse,
    reason: string,
  ) => {
    updatePatientResponse({
      variables: {
        patientAppointmentId,
        appointmentResponse,
        reason,
      },
      onCompleted: () => {
        refetch();
        enqueueSnackbar('Response submitted successfully.');
      },
      onError: () => {
        enqueueSnackbar(
          'There was an error while submitting response, please try again or contact support.',
          {
            variant: 'error',
          },
        );
      },
    });
  };

  const confirmAppointment = () => {
    submitAppointmentResponse(
      data?.patientAppointmentByAccessToken?.id!,
      PatientAppointmentResponse.Confirmed,
      '',
    );
  };
  const cancelAppointment = (reason: string) => {
    submitAppointmentResponse(
      data?.patientAppointmentByAccessToken?.id!,
      PatientAppointmentResponse.Declined,
      reason,
    );
  };

  if (loadingSecuredContent && !data) {
    return (
      <Box p={15}>
        <Loading message="Loading Appointment Details" />
      </Box>
    );
  }

  let component = null;

  if (data) {
    component = (
      <AppointmentPage
        patientAppointment={data.patientAppointmentByAccessToken!}
        confirmAppointment={confirmAppointment}
        cancelAppointment={cancelAppointment}
      />
    );
  } else {
    component = <DOBComponent submitDob={submitDob} />;
  }

  if (expired) {
    component = (
      <Box mt={10} display="flex" alignItems="center" flexDirection="column">
        <ErrorOutlineRounded style={{ color: red[500], fontSize: '5rem' }} />
        <Typography variant="h6" gutterBottom align="center">
          Appointment has either expired or has been cancelled. Please contact your clinic to
          reschedule.
        </Typography>
      </Box>
    );
  }

  return (
    <Container component="main">
      {component}
      <br />
    </Container>
  );
}
