import { Editor } from '@tinymce/tinymce-react';
import { Placeholder } from './forms/types/enum/Placeholder';

export default function RTEditor({
  value,
  onEditorChange,
  outputFormat,
  disabled = false,
}: {
  value: string;
  disabled?: boolean;
  outputFormat: 'html' | 'text';
  onEditorChange?: (content: any) => void;
}) {
  return (
    <Editor
      disabled={disabled}
      apiKey="jwqx9kl9vszhcpos7d0sbeppm5e1qygiv0vckhmu9f5o46qo"
      value={value || ''}
      onEditorChange={onEditorChange}
      outputFormat={outputFormat}
      init={{
        selector: 'textarea' as any,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount autoresize ',
        ],

        toolbar:
          'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | placeholders',
        setup: (editor) => {
          editor.ui.registry.addMenuButton('placeholders', {
            text: 'Placeholders',
            fetch: (success) => {
              var items = Object.entries(Placeholder).map((obj) => {
                return {
                  type: 'menuitem',
                  text: obj[0],
                  onAction: () => {
                    editor.insertContent(obj[1]);
                  },
                };
              });
              success(items as any);
            },
          });
        },
      }}
    />
  );
}
