import { Select, MenuItem } from '@mui/material';
import { addMinutes, differenceInMinutes } from 'date-fns';

import { PatientAppointment } from '../../generated/graphql';

const items = [
  { key: 'apple', value: 'Apple Calendar' },
  { key: 'google', value: 'Google Calendar' },
  { key: 'outlook', value: 'Outlook' },
  { key: 'outlookcom', value: 'Outlook.com' },
  { key: 'yahoo', value: 'Yahoo' },
];

const getRandomKey = () => {
  let n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + '_' + n;
};
const formatTime = (date: string) => {
  return new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, '');
};

const calculateDuration = (startTime: string, endTime: string) => {
  return differenceInMinutes(new Date(endTime), new Date(startTime));
};

const buildUrl = (event: any, type: string) => {
  let calendarUrl = '';
  const duration = calculateDuration(event.startTime, event.endTime);

  // allow mobile browsers to open the gmail data URI within native calendar app
  // type = (type == "google" && this.isMobile()) ? "outlook" : type;

  switch (type) {
    case 'google':
      calendarUrl = 'https://calendar.google.com/calendar/render';
      calendarUrl += '?action=TEMPLATE';
      calendarUrl += '&dates=' + formatTime(event.startTime);
      calendarUrl += '/' + formatTime(event.endTime);
      calendarUrl += '&location=' + encodeURIComponent(event.location);
      calendarUrl += '&text=' + encodeURIComponent(event.title);
      calendarUrl += '&details=' + encodeURIComponent(event.description);
      break;

    case 'yahoo':
      // yahoo doesn't utilize endTime so we need to calulate duration
      calendarUrl = 'https://calendar.yahoo.com/?v=60&view=d&type=20';
      calendarUrl += '&title=' + encodeURIComponent(event.title);
      calendarUrl += '&st=' + formatTime(event.startTime);
      calendarUrl += '&dur=' + duration;
      calendarUrl += '&desc=' + encodeURIComponent(event.description);
      calendarUrl += '&in_loc=' + encodeURIComponent(event.location);
      break;

    case 'outlookcom':
      calendarUrl = 'https://outlook.live.com/owa/?rru=addevent';
      calendarUrl += '&startdt=' + formatTime(event.startTime);
      calendarUrl += '&enddt=' + formatTime(event.endTime);
      calendarUrl += '&subject=' + encodeURIComponent(event.title);
      calendarUrl += '&location=' + encodeURIComponent(event.location);
      calendarUrl += '&body=' + encodeURIComponent(event.description);
      calendarUrl += '&allday=false';
      calendarUrl += '&uid=' + getRandomKey();
      calendarUrl += '&path=/calendar/view/Month';
      break;

    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + formatTime(event.startTime),
        'DTEND:' + formatTime(event.endTime),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        'LOCATION:' + event.location,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      calendarUrl = encodeURI('data:text/calendar;charset=utf8,' + calendarUrl);
  }

  return calendarUrl;
};

export default function AddToCalendarButton({ appointment }: { appointment: PatientAppointment }) {
  return (
    <Select
      variant="outlined"
      size="small"
      margin="dense"
      color="primary"
      autoWidth
      displayEmpty
      value=""
      onChange={(e) => {
        const type = e.target.value as string;
        if (type) {
          window.open(
            buildUrl(
              {
                title: `${appointment.provider?.descriptor},  ${appointment.clinic?.name}`,
                description: '',
                location: appointment.clinic?.name,
                startTime: appointment.startDate?.dateTime,
                endTime: addMinutes(
                  new Date(appointment.startDate?.dateTime),
                  (appointment.appointmentType?.defaultDurationInMinutes as number) || 0,
                ),
              },
              type,
            ),
          );
        }
      }}
    >
      <MenuItem value="">Add to my Calendar</MenuItem>
      {items.map((i) => (
        <MenuItem key={i.key} value={i.key}>
          {i.value}
        </MenuItem>
      ))}
    </Select>
  );
}
