export const DEFAULT_EMAIL_TEMPLATE =
  '<p>Dear ${patient_first_name},</p><p>This is a friendly reminder for your upcoming appointment with Dr. ${provider_name}.</p><p><strong>Date: ${appointment_date}</strong> <br /><strong>Time: ${appointment_time}</strong></p><p>At the:</p><p>${clinic_name} <br />${clinic_address} <br />${clinic_phone} <br />${clinic_url}</p>';
export const DEFAULT_SMS_TEMPLATE =
  'Hi ${patient_first_name}, your appointment on ${appointment_date} at ${appointment_time} with ${clinic_name} is coming up.';
export const DEFAULT_CONTENT_TEMPLATE =
  '<p>${patient_first_name},</p><p>Your appointment with Dr. ${provider_name} is on:</p><p><strong>Date: ${appointment_date}</strong> <br /><strong>Time: ${appointment_time}</strong></p><p>${clinic_name}<br />${clinic_address}<br />${clinic_phone}<br />${clinic_url}</p><p>Please confirm your appointment above, if you have not already done so.</p><p>If you need to cancel your appointment, please decline the appointment or call ${clinic_phone} during our regular clinic hours as soon as possible (at least 48 hours in advance).</p>';

export const DEFAULT_EMAIL_HEADER =
  '<img alt="" src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/brightsquid.png" style="height:25px; width:200px" /><br/><br/>';
export const DEFAULT_EMAIL_FOOTER =
  '<div class="mceNonEditable"><p>${clinic_name} trust Brightsquid to communicate your&nbsp;appointment reminder.&nbsp; As part of the appointment, your provider may require some forms to be completed and have pre-appointment procedures and instructions for you.&nbsp; To view full appointment details or make changes, go to the following link.</p><p><a style="font-weight:bold;background-color: #55c9f4;color: white;text-decoration: none;padding: 10px;line-height: 50px;border-radius: 6px;" >View or Confirm Appointment</a></p><p>Sincerely,<br />Your Brightsquid Support Team </p><table align="left" border="0" cellspacing="0" style="width:100%"><tbody><tr><td style="width:1%"><img alt="" src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/logo.png" style="height:50px; width:50px" /></td><td style="vertical-align:middle"><div><span style="font-size:12px">Questions? Visit <a href="https://support.brightsquid.com" target="_blank">https://support.brightsquid.com</a>. </span></div><address><span style="font-size:10px">Please do not reply to this message as it was sent using Brightsquid Reminders</span></address></td></tr></tbody></table></div>';
export const DEFAULT_SMS_FOOTER =
  'More details about the appointment are Secure by Brightsquid here [secured link].';
