import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box style={{ marginTop: '4em' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        Powered by
        <br />
        <img alt="" src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/brightsquid.png" />
      </Typography>
    </Box>
  );
}

export default Footer;
