import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { Clear, CloudDownloadOutlined } from '@mui/icons-material';
import { isEqual } from 'lodash-es';
import { Attachment, useDownloadUrlLazyQuery, useUploadUrlQuery } from '../../generated/graphql';
import CommonUtils from '../../util/CommonUtils';

export const bytesToSize = (bytes: number) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

function FileUploader({
  value,
  onChange,
  readOnly,
}: {
  value: any[];
  onChange: (_f: Attachment[]) => void;
  readOnly: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [tempFiles, setTempFiles] = useState<any>([]);
  const [files, setFiles] = useState<any>(value || []);

  useEffect(() => {
    if (!isEqual(value?.map((u: any) => u.uuid).sort(), files?.map((u: any) => u.uuid).sort())) {
      setFiles(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    onChange(files);
  }, [onChange, files]);

  const { refetch } = useUploadUrlQuery({
    skip: true,
  });

  const [downloadUrl] = useDownloadUrlLazyQuery({
    onCompleted: (res) => {
      CommonUtils.downloadUrl(res?.downloadUrl!);
    },
  });

  const removeFile = useCallback(
    (file: any) => {
      setFiles((prev: any) => [...prev.filter((f: any) => f.uuid !== file.uuid)]);
    },
    [setFiles],
  );

  const addFile = useCallback(
    (file: any) => {
      const { uuid, name, type, size } = file;
      setTempFiles((prev: any) => prev.filter((f: any) => f.uuid !== uuid));

      setFiles((prev: any) => [
        ...(prev || []),
        {
          uuid,
          filename: name,
          mimeType: type,
          size: size,
        },
      ]);
    },
    [setFiles],
  );

  const uploadedFile = useCallback(
    (uploadUrl: string, file: any) => {
      fetch(uploadUrl, {
        method: 'PUT',
        body: file,
      })
        .then((res) => {
          if (!res.ok) {
            throw res.statusText;
          }
          addFile(file);
        })
        .catch(() => {
          enqueueSnackbar('Unable to upload file(s), please try again or contact support.');
          setTempFiles((files: any) => {
            return [...files.filter((f: any) => f?.uuid !== file?.uuid)];
          });
        });
    },
    [addFile, enqueueSnackbar],
  );

  const getUploadUrl = useCallback(
    (file: any) => {
      refetch({
        uuid: file.uuid,
        mimeType: file.type,
      }).then((res) => {
        if (res?.data?.uploadUrl) {
          uploadedFile(res.data.uploadUrl, file);
        }
      });
    },
    [refetch, uploadedFile],
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const filesListWithUuid = acceptedFiles.map((file: any) => {
        file.uuid = uuidv4();
        file.uploading = true;
        return file;
      });
      setTempFiles((f: any) => [...f, ...filesListWithUuid]);
      filesListWithUuid.forEach((file: any) => {
        getUploadUrl(file);
      });
    },
    [getUploadUrl],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadedFiles = [...tempFiles, ...(files || [])]?.map((file: any, i: any) => {
    let downloadButton = null;

    if (file.id) {
      downloadButton = (
        <IconButton
          color="primary"
          onClick={() => {
            downloadUrl({
              variables: {
                attachmentId: file.id,
              },
            });
          }}
        >
          <CloudDownloadOutlined />
        </IconButton>
      );
    }
    return (
      <div key={i}>
        {!readOnly && (
          <IconButton
            color="error"
            onClick={() => {
              removeFile(file);
            }}
          >
            {file.uploading ? <CircularProgress /> : <Clear />}
          </IconButton>
        )}
        <b>
          {downloadButton}
          {file.name || file.filename}
        </b>{' '}
        ({bytesToSize(file.size)})
      </div>
    );
  });

  let attachmentUploader = (
    <div
      id="dropzone"
      {...getRootProps()}
      style={{
        border: '2px dashed #0087f7',
        borderRadius: '5px',
        background: '#fff',
        padding: '30px',
        marginTop: '20px',
        textAlign: 'center',
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Upload attachments here to send along with the secured message.</p>
      )}
    </div>
  );

  if (readOnly) {
    attachmentUploader = <div></div>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography component="h4" variant="subtitle1">
        Secured Attachments
      </Typography>
      {attachmentUploader}
      <Box sx={{ mt: 2 }}>{uploadedFiles}</Box>
      {/* {file && <FileViewerModal file={file} onClosed={() => setFile(null)} />} */}
    </Box>
  );
}

export default FileUploader;
