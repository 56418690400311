import { CopyAllOutlined, DeleteForever, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  AppointmentPanelState,
  useAppointmentPanelsQuery,
  useDeleteAppointmentPanelMutation,
} from '../../generated/graphql';

function ListAppointmentReminders() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: appointmentPanels, refetch: refreshPanels } = useAppointmentPanelsQuery({
    fetchPolicy: 'network-only',
  });
  const [deleteAppointmentPanel] = useDeleteAppointmentPanelMutation({
    onCompleted: () => {
      refreshPanels();
    },
    onError: () => {
      enqueueSnackbar(
        'Unable to delete appointment reminder configuration, please contact support.',
        { variant: 'error' },
      );
    },
  });

  const actionRenderer = (params: GridCellParams) => {
    return (
      <>
        <Tooltip title="Clone">
          <IconButton
            color="primary"
            onClick={() => {
              navigate(`/appointments/add/?clone=${params.row.id}`);
            }}
          >
            <CopyAllOutlined></CopyAllOutlined>
          </IconButton>
        </Tooltip>
        {params.row.state === AppointmentPanelState.Inactive && (
          <Tooltip title="Delete Panel">
            <IconButton
              color="primary"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this Appointment Reminder configuration?',
                  )
                ) {
                  deleteAppointmentPanel({ variables: { id: params.row.id!.toString() } });
                }
              }}
            >
              <DeleteForever />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Edit Details">
          <IconButton
            color="primary"
            onClick={() => {
              navigate(`/appointments/add/${params.row.id}`);
            }}
          >
            <Edit></Edit>
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'appointmentSource', headerName: 'Source', flex: 1 },
    { field: 'state', headerName: 'State', flex: 1 },
    {
      field: 'edit',
      headerName: ' ',
      width: 140,
      renderCell: actionRenderer,
      align: 'right',
    },
  ];

  const rows =
    appointmentPanels?.appointmentPanels?.map((r: any) => {
      const {
        id,
        name,
        description,
        appointmentSource,
        appointmentTypeIds,
        state,
        appointmentConfirmation,
      } = r;
      return {
        id,
        name,
        description,
        appointmentSource,
        appointmentTypeIds,
        state,
        appointmentConfirmation,
      };
    }) || [];

  return (
    <Box width={'100%'}>
      <Typography component="h1" variant="h4" align="left">
        Appointment Reminders
      </Typography>
      <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/appointments/add');
          }}
        >
          Setup Appointment Reminder
        </Button>
      </Box>
      <Box>
        <DataGrid rows={rows} columns={columns} hideFooterPagination hideFooter autoHeight />
      </Box>
    </Box>
  );
}

export default ListAppointmentReminders;
