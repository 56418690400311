export enum Placeholder {
  PATIENT_FIRST_NAME = '${patient_first_name}',
  PROVIDER_NAME = '${provider_name}',
  APPOINTMENT_DATE = '${appointment_date}',
  APPOINTMENT_TIME = '${appointment_time}',
  CLINIC_NAME = '${clinic_name}',
  CLINIC_ADDRESS = '${clinic_address}',
  CLINIC_PHONE = '${clinic_phone}',
  CLINIC_URL = '${clinic_url}',
}
