import { Box, Button, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid';

import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Loading';
import { useMessageTemplatesQuery } from '../../generated/graphql';
import { startCase } from 'lodash';

function TemplatesListPage() {
  const navigate = useNavigate();
  const { loading, data } = useMessageTemplatesQuery({ fetchPolicy: 'network-only' });
  const renderEditButton = (params: any) => (
    <>
      <IconButton
        color="primary"
        onClick={(e) => {
          navigate(`/template/${params.row.id}`);
          e.stopPropagation();
        }}
      >
        <Edit titleAccess="Edit"></Edit>
      </IconButton>
    </>
  );
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'subject', headerName: 'Subject (Email only)', flex: 1 },
    { field: 'type', headerName: 'Source', width: 200 },
    {
      field: 'edit',
      headerName: ' ',
      width: 80,
      renderCell: renderEditButton,
    },
  ];

  const rows =
    data?.messageTemplates?.map((r: any) => {
      const { id, name, subject, content, type } = r;
      return {
        id,
        name,
        subject,
        content,
        type: startCase(type?.toLowerCase()),
      };
    }) || [];
  return (
    <Box width={'100%'}>
      {loading && <Loading />}
      <Button
        style={{ float: 'right' }}
        variant="contained"
        color="primary"
        onClick={() => {
          navigate('/template/');
        }}
      >
        Create Template
      </Button>
      <Typography component="h1" variant="h4" gutterBottom>
        Templates
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={(params) => {
          navigate(`/template/${params.id}`);
        }}
        hideFooterPagination
        hideFooter
        autoHeight
        sortModel={[
          {
            field: 'name',
            sort: 'asc' as GridSortDirection,
          },
        ]}
      />
    </Box>
  );
}

export default TemplatesListPage;
