import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AuthProvider, AuthService } from '@brightsquid/react-oauth2-pkce';
import { useSnackbar } from 'notistack';
import { HTTP_LINK, UPLOAD_LINK, USER_AUTH_LINK } from '../ApolloLinks';
import Dashboard from './Dashboard';

export default function DashboardApolloWrapper() {
  const { enqueueSnackbar } = useSnackbar();

  const ERROR_LINK = onError((e) => {
    if (e.networkError?.message === 'Failed to fetch') {
      enqueueSnackbar(
        `There was an error reaching the application, please contact support or try again later.`,
        {
          variant: 'error',
          preventDuplicate: false,
          persist: true,
        },
      );
    }
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([ERROR_LINK, USER_AUTH_LINK, UPLOAD_LINK as any, HTTP_LINK]),
  });

  const authService = new AuthService({
    clientId: process.env.REACT_APP_CLIENT_ID!,
    location: window.location,
    provider: process.env.REACT_APP_AUTH_URL!,
    redirectUri: process.env.REACT_APP_REDIRECT_URL!,
    scopes: [],
  });
  return (
    <ApolloProvider client={client}>
      <AuthProvider authService={authService}>
        <Dashboard />
      </AuthProvider>
    </ApolloProvider>
  );
}
