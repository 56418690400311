import React from 'react';
import { Fade, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { CheckCircleOutlineRounded } from '@mui/icons-material';

export const SuccessPanel = ({
  message,
  children,
}: {
  message?: String | Element;
  children?: React.ReactNode;
}) => {
  return (
    <Fade in={true}>
      <div>
        <Typography variant="h1" align="center">
          <CheckCircleOutlineRounded
            style={{
              color: green[500],
              marginRight: '0.5rem',
              verticalAlign: 'middle',
              fontSize: '1em',
            }}
          />
        </Typography>

        <Typography variant="h6" gutterBottom align="center">
          <>
            {message}
            {children}
          </>
        </Typography>
      </div>
    </Fade>
  );
};
