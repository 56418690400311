import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '.';
import { getToken } from '../service/auth';
// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    loggingIn: false,
    loggedIn: !!getToken(true),
    currentUser: null,
    loginError: null,
    currentUserError: null,
    mfa: null,
    mfaError: null,
  },
  reducers: {
    currentUserSuccess: (state, action) => {
      state.currentUserError = null;
      state.currentUser = action.payload;
    },
    currentUserError: (state, action) => {
      state.currentUserError = action.payload?.err?.error || 'Unable to get current user';
    },
    verifyStart: (state) => {
      state.mfaError = null;
      state.loggingIn = true;
    },
    mfaFailed: (state, action) => {
      state.mfaError =
        action.payload?.err?.error || 'Invalid verification token. Please try again.';
      state.loggingIn = false;
    },
  },
});
export default slice.reducer;
// Actions
const { currentUserSuccess } = slice.actions;

export const setCurrentUser = (currentUser: any) => async (dispatch: any) => {
  dispatch(currentUserSuccess(currentUser));
};

const userSelector = (state: RootState) => {
  return state.user;
};

export const getCurrentUser = createSelector(userSelector, (user) => user.currentUser);
