import { ServerError } from '@apollo/client';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { setInterval } from 'timers';
import { AppProvider } from '../context/AppContext';
import { useCurrentUserLazyQuery } from '../generated/graphql';
import { RootState } from '../redux';
import { setCurrentUser } from '../redux/user';
import { getToken, logout } from '../service/auth';
import AddAppointmentReminder from './appointments/AddAppointmentReminder';
import Billing from './appointments/Billing';
import ListAppointmentReminders from './appointments/ListAppointmentReminders';
import Logs from './appointments/Logs';
import UpcomingAppointments from './appointments/UpcomingAppointments';

import LandingPage from './LandingPage';
import { Loading } from './Loading';
import Settings from './Settings';
import SideBar from './SideBar';
import CreateTemplate from './templates/CreateTemplate';
import TemplatesListPage from './templates/TemplatesListPage';
import TopBar from './TopBar';

export default function Dashboard() {
  const disptach = useDispatch();

  const { loggedIn, currentUser }: any = useSelector((state: RootState) => state.user);
  const [getCurrentUser] = useCurrentUserLazyQuery({
    onCompleted: (data) => {
      disptach(setCurrentUser(data.currentUser));
    },
    onError: (error) => {
      if ((error?.networkError as ServerError)?.statusCode === 401) {
        logout(false, 'You do not have access to Panel. Please talk to your clinic administrator');
      }
    },
  });

  useEffect(() => {
    if (loggedIn) {
      getCurrentUser();
    }
  }, [getCurrentUser, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      const intervalId = setInterval(() => {
        const token = getToken(false);
        if (!token) {
          window.location.reload();
        }
      }, 30000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [loggedIn]);

  let component = null;
  if (!loggedIn) {
    component = <LandingPage />;
  } else if (!currentUser) {
    component = (
      <Box m={5}>
        <Loading />
      </Box>
    );
  } else {
    component = (
      <Box display="flex">
        <SideBar />
        <TopBar />
        <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
          <Box sx={{ minHeight: 64 }} />
          <Box
            width={'100%'}
            sx={{
              p: 4,
            }}
          >
            <Routes>
              <Route path="/appointment/list" element={<ListAppointmentReminders />} />
              <Route path="/appointments/add/:id?" element={<AddAppointmentReminder />} />
              <Route path="/template/:id?" element={<CreateTemplate />} />
              <Route path="/templates" element={<TemplatesListPage />} />
              <Route path="/history" element={<Logs />} />
              <Route path="/upcoming" element={<UpcomingAppointments />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/" element={<ListAppointmentReminders />} />
            </Routes>
          </Box>
        </main>
      </Box>
    );
  }

  return <AppProvider value={{ currentUser: currentUser }}>{component}</AppProvider>;
}
