class CommonUtils {
  static downloadUrl(url) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.click();
    document.body.removeChild(a);
  }

  static toSnakeCase(str) {
    return str
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }

  static toUrlEncoded(obj) {
    return Object.keys(obj)
      .map((k) => encodeURIComponent(this.toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
      .join('&');
  }
}

export default CommonUtils;
