import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default function TimeWidget({ time }: { time: string }) {
  return (
    <Time>
      <Typography variant="h6" align="center">
        {time}
      </Typography>
    </Time>
  );
}
