import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import DashboardApolloWrapper from './components/DashboardApolloWrapper';
import LogoutPage from './components/LogoutPage';
import PatientPageApolloWrapper from './components/patient/PatientPageApolloWrapper';

const themeLight = createTheme({
  palette: {
    background: {
      default: '#f5f5f5',
    },
    primary: {
      main: '#2985c9',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  return (
    <SnackbarProvider
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/form/:accessToken" element={<PatientPageApolloWrapper />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="*" element={<DashboardApolloWrapper />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
