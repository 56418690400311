import { FormEvent, useEffect, useState } from 'react';
import { Button, TextField, MenuItem, Box, makeStyles, Typography, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import startCase from 'lodash.startcase';
import RTEditor from '../RTEditor';
import { useSnackbar } from 'notistack';
import {
  DEFAULT_CONTENT_TEMPLATE,
  DEFAULT_EMAIL_FOOTER,
  DEFAULT_EMAIL_HEADER,
  DEFAULT_EMAIL_TEMPLATE,
  DEFAULT_SMS_TEMPLATE,
  DEFAULT_SMS_FOOTER,
} from './TemplateText';
import { Placeholder } from '../forms/types/enum/Placeholder';
import {
  MessageTemplateType,
  useCreateMessageTemplateMutation,
  useMessageTemplateByIdQuery,
  useUpdateMessageTemplateMutation,
} from '../../generated/graphql';
import { blue } from '@mui/material/colors';
import { ApolloError } from '@apollo/client';

const styles = {
  button: {
    marginBottom: 5,
    display: 'flex',
    width: '100%',
    maxWidth: '150px',
  },
  paper: {
    marginTop: 2,
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'theme.palette.primary.main',
    color: 'white',
  },
  outlinedDiv: {
    marginTop: 2,
  },
};

const InputComponent = ({ ...other }) => <Box width={1} {...other} />;

const OutlinedDiv = ({ children, label }: { children: any; label: any }) => {
  return (
    <TextField
      style={{ marginTop: '16px' }}
      fullWidth
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent as any,
      }}
      inputProps={{ children: children }}
    />
  );
};

function CreateTemplate() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [type, setType] = useState<MessageTemplateType>();
  const [content, setContent] = useState('');
  const [readonly, setReadonly] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [createMessageTemplate] = useCreateMessageTemplateMutation({
    onError: (error: ApolloError) => {
      if (error.message) {
        enqueueSnackbar(error.message);
      } else {
        enqueueSnackbar('Error while creating template, Please try again or contact support.', {
          variant: 'error',
        });
      }
    },
    onCompleted: (data) => {
      navigate(`/template/${data?.createMessageTemplate}`);
      enqueueSnackbar('Template Created Successfully.');
      navigate('/templates');
    },
  });
  const [updateMessageTemplate] = useUpdateMessageTemplateMutation({
    onError: (error: ApolloError) => {
      if (error.message) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Error while updating template, Please try again or contact support.', {
          variant: 'error',
        });
      }
    },
    onCompleted: () => {
      enqueueSnackbar('Template Updated Successfully.');
      navigate('/templates');
    },
  });
  const { data } = useMessageTemplateByIdQuery({
    variables: {
      id: id!,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (id && data) {
      const template = data?.messageTemplateById;
      if (template) {
        setName(template.name!);
        setType(template.type!);
        setSubject(template.subject!);
        setContent(template.content!);
        setReadonly(template?.global!);
      }
    }
  }, [id, data]);

  const handleEditorChange = (content: any) => {
    setContent(content);
  };

  const submit = (e: FormEvent) => {
    e.preventDefault();
    if (!id) {
      createMessageTemplate({
        variables: {
          input: {
            name,
            type,
            subject,
            content: content,
          },
        },
      });
    } else {
      updateMessageTemplate({
        variables: {
          input: { name, subject, type, content: content, id: id },
        },
      });
    }
  };

  const isSMS = type === MessageTemplateType.Sms;
  const isEmail = type === MessageTemplateType.Email;
  const isSecureContent = type === MessageTemplateType.SecureContent;
  return (
    <>
      <form onSubmit={submit}>
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/templates');
            }}
          >
            Cancel
          </Button>
          {!readonly && (
            <Button variant="contained" color="primary" type="submit" disabled={!type}>
              {id ? 'Update Template' : 'Save Template'}
            </Button>
          )}
        </Box>
        <TextField
          required
          disabled={readonly}
          onChange={(e) => {
            const value = e.target.value;
            if (value === MessageTemplateType.Email) {
              setType(MessageTemplateType.Email);
              setContent(DEFAULT_EMAIL_TEMPLATE);
            } else if (value === MessageTemplateType.Sms) {
              setType(MessageTemplateType.Sms);
              setContent(DEFAULT_SMS_TEMPLATE);
            } else if (value === MessageTemplateType.SecureContent) {
              setType(MessageTemplateType.SecureContent);
              setContent(DEFAULT_CONTENT_TEMPLATE);
            }
          }}
          variant="outlined"
          margin="normal"
          size="small"
          select
          fullWidth
          id="appointmentSource"
          label="Template Type"
          value={`${type}`}
        >
          {Object.values(MessageTemplateType)
            .filter((t) => t != MessageTemplateType.SecureMail)
            .map((v) => (
              <MenuItem key={v} value={v}>
                {startCase(v.toLowerCase())}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="name"
          name="name"
          margin="normal"
          size="small"
          label="Template name"
          value={name}
          disabled={readonly}
          onChange={(e) => setName(e.target.value)}
        />

        {isSMS && (
          <OutlinedDiv label="SMS Message">
            <Box display="flex">
              <Box flex="1">
                <Box
                  color={blue[600]}
                  fontWeight="fontWeightMedium"
                  fontSize={16}
                  fontStyle="italic"
                >
                  Place your custom message here (up to a maximum of 300 characters):
                </Box>
                <TextField
                  size="small"
                  variant="outlined"
                  style={{ margin: '8px 0 8px 0' }}
                  fullWidth
                  multiline
                  disabled={readonly}
                  inputProps={{ style: { height: '175px' }, maxLength: 300 }}
                  margin="normal"
                  id="content"
                  name="content"
                  label=""
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: DEFAULT_SMS_FOOTER,
                  }}
                />
              </Box>
              <Box m={2}>
                <Box width="150px">
                  <Typography gutterBottom align="center">
                    Automatically insert any of this data:
                  </Typography>
                </Box>
                {Object.entries(Placeholder)
                  .filter((o) => !['CLINIC_ADDRESS', 'CLINIC_URL'].includes(o[0]))
                  .map((obj, i) => {
                    return (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (`${content}${obj[1]}`.length <= 300) {
                            setContent(`${content}${obj[1]}`);
                          }
                        }}
                        sx={styles.button}
                        key={i}
                      >
                        {obj[0]}
                      </Button>
                    );
                  })}
              </Box>
            </Box>
          </OutlinedDiv>
        )}
        {isEmail && (
          <>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              margin="normal"
              id="subject"
              name="subject"
              label="Subject"
              disabled={readonly}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />

            <OutlinedDiv label="Email Body">
              <div dangerouslySetInnerHTML={{ __html: DEFAULT_EMAIL_HEADER }} />
              <Box color={blue[600]} fontWeight="fontWeightMedium" fontSize={16} fontStyle="italic">
                Place your custom message here :
              </Box>
              <RTEditor
                outputFormat="html"
                value={content}
                onEditorChange={handleEditorChange}
                disabled={readonly}
              />
              <div dangerouslySetInnerHTML={{ __html: DEFAULT_EMAIL_FOOTER }} />
            </OutlinedDiv>
          </>
        )}
        {isSecureContent && (
          <>
            <br />
            <br />
            <RTEditor
              outputFormat="html"
              value={content}
              onEditorChange={handleEditorChange}
              disabled={readonly}
            />
          </>
        )}
        <Paper sx={styles.paper}>
          <Typography>
            Have you invited your patients to use Secure-Mail? Clinics save staff hours each week
            using Secure-Mail for appointment prep documents, follow up instructions, lab reqs, and
            more.{' '}
            <a
              style={{ color: 'white' }}
              target="_blank"
              href="https://support.brightsquid.com"
              rel="noreferrer"
            >
              Contact Brightsquid Support
            </a>{' '}
            for help inviting all patients at once.
          </Typography>
        </Paper>
      </form>
    </>
  );
}

export default CreateTemplate;
