import { format } from 'date-fns';

class DashboardUtilities {
  static getPastDateRangeFilter() {
    var date = new Date();
    var currentYear = date.getFullYear();
    var currentMonth = date.getMonth();
    var currentDay = date.getDate();

    // Predefined date range, searches from gte (greaterThanEqualTo) to lt (lessThan) datetime ranges
    const dateRangeFilter = [
      {
        value: 'Current Month',
        dateRange: {
          gte: new Date(currentYear, currentMonth, 1),
          lt: new Date(currentYear, currentMonth + 1, 1),
        },
      },
      {
        value: 'Previous Month',
        dateRange: {
          gte: new Date(currentYear, currentMonth - 1, 1),
          lt: new Date(currentYear, currentMonth, 1),
        },
      },
      {
        value: format(new Date(currentYear, currentMonth - 2, 1), 'MMMM yyyy'),
        dateRange: {
          gte: new Date(currentYear, currentMonth - 2, 1),
          lt: new Date(currentYear, currentMonth - 1, 1),
        },
      },
      {
        value: format(new Date(currentYear, currentMonth - 3, 1), 'MMMM yyyy'),
        dateRange: {
          gte: new Date(currentYear, currentMonth - 3, 1),
          lt: new Date(currentYear, currentMonth - 2, 1),
        },
      },
      {
        value: format(new Date(currentYear, currentMonth - 4, 1), 'MMMM yyyy'),
        dateRange: {
          gte: new Date(currentYear, currentMonth - 4, 1),
          lt: new Date(currentYear, currentMonth - 3, 1),
        },
      },
      {
        value: format(new Date(currentYear, currentMonth - 5, 1), 'MMMM yyyy'),
        dateRange: {
          gte: new Date(currentYear, currentMonth - 5, 1),
          lt: new Date(currentYear, currentMonth - 4, 1),
        },
      },
      {
        value: format(new Date(currentYear, currentMonth - 6, 1), 'MMMM yyyy'),
        dateRange: {
          gte: new Date(currentYear, currentMonth - 6, 1),
          lt: new Date(currentYear, currentMonth - 5, 1),
        },
      },
      {
        value: 'Last 30 days',
        dateRange: {
          gte: new Date(currentYear, currentMonth, currentDay - 30),
          lt: new Date(currentYear, currentMonth, currentDay + 1),
        },
      },
      {
        value: 'Last 60 days',
        dateRange: {
          gte: new Date(currentYear, currentMonth, currentDay - 60),
          lt: new Date(currentYear, currentMonth, currentDay + 1),
        },
      },
      {
        value: 'Last 90 days',
        dateRange: {
          gte: new Date(currentYear, currentMonth, currentDay - 90),
          lt: new Date(currentYear, currentMonth, currentDay + 1),
        },
      },
    ];
    return dateRangeFilter;
  }
  static getFutureDateRangeFilter() {
    var date = new Date();
    var currentYear = date.getFullYear();
    var currentMonth = date.getMonth();
    var currentDay = date.getDate();

    // Predefined date range, searches from gte (greaterThanEqualTo) to lt (lessThan) datetime ranges
    const dateRangeFilter = [
      {
        value: 'Next 7 days',
        dateRange: {
          gte: new Date(),
          lt: new Date(currentYear, currentMonth, currentDay + 7),
        },
      },
      {
        value: 'Next 14 days',
        dateRange: {
          gte: new Date(),
          lt: new Date(currentYear, currentMonth, currentDay + 14),
        },
      },

      {
        value: 'Next 3 Weeks',
        dateRange: {
          gte: new Date(),
          lt: new Date(currentYear, currentMonth, currentDay + 21),
        },
      },
      {
        value: 'Next 4 Weeks',
        dateRange: {
          gte: new Date(),
          lt: new Date(currentYear, currentMonth, currentDay + 28),
        },
      },
    ];
    return dateRangeFilter;
  }
}

export default DashboardUtilities;
