import { createUploadLink } from 'apollo-upload-client';
import { createHttpLink } from '@apollo/client';
import { getToken } from './service/auth';
import { setContext } from '@apollo/client/link/context';

export const UPLOAD_LINK = createUploadLink({
  uri: process.env.REACT_APP_PANEL_URI,
});

export const HTTP_LINK = createHttpLink({
  uri: process.env.REACT_APP_PANEL_URI,
});

export const USER_AUTH_LINK = setContext((_, { headers }) => {
  const token = getToken(true);
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});
