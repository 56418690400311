import { Security } from '@mui/icons-material';
import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FormEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function DOBComponent({ submitDob }: { submitDob: Function }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (dateOfBirth) {
      submitDob(format(dateOfBirth, 'yyyy-MM-dd'));
    } else {
      enqueueSnackbar('Please enter valid date of birth.');
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Paper
          sx={{
            marginTop: theme.spacing(4),
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box width="100%" mb={1}>
            <Box display="inline-flex" alignItems="flex-end">
              <Security color="primary" fontSize="large" /> {'  '}
              <Typography variant="h5" component="h1" color="primary">
                Secured By Brightsquid
              </Typography>
            </Box>
          </Box>
          <Divider />
          <br />
          <br />
          <Typography variant="body1" component="h2" color="textPrimary">
            Your healthcare provider has sent some information regarding your upcoming appointment.
          </Typography>
          <br />
          <Typography variant="body1" component="h2" color="textPrimary">
            To view your private appointment details, please confirm your Date of Birth.
          </Typography>
          <br />
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              disableFuture
              openTo="year"
              inputFormat="MM/dd/yyyy"
              label="Date of birth"
              views={['year', 'month', 'day']}
              value={dateOfBirth}
              renderInput={(params: any) => <TextField {...params} />}
              onChange={(e: any) => setDateOfBirth(e)}
              autoFocus
            />
          </LocalizationProvider>

          <br />
          <br />
          <Button type="submit" variant="contained" color="primary" size="large">
            Submit
          </Button>
          <br />
          <Grid container alignItems="center" style={{ fontSize: 12, justifyContent: 'center' }}>
            <Grid item>
              By clicking Submit, you agree to our{' '}
              <Link
                href="https://brightsquid.com/pages/brightsquid-application-terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'Terms of service'}
              </Link>
              {' and '}
              <Link
                href="https://brightsquid.com/pages/brightsquid-application-privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'Privacy statement'}
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
}

export default DOBComponent;
