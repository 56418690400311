import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

export const Loading = ({ message }: { message?: String }) => {
  return (
    <Typography variant="h6" gutterBottom align="center" style={{}}>
      <CircularProgress style={{ margin: '0.5rem', verticalAlign: 'middle' }} />
      {message || 'Loading...'}
    </Typography>
  );
};
