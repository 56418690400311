import { AppFooter } from '@brightsquid/bsqwidget';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function LogoutPage() {
  const { search } = useLocation();
  const errorMsg = new URLSearchParams(search).get('error');
  const message = new URLSearchParams(search).get('message');

  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.removeItem('auth');
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#f3f5f7',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            flex: 1,
            padding: 18,
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            marginBottom: '8rem',
            width: '100%',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 36,
            }}
          >
            <img
              src="https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/brightsquid.svg"
              alt="Brightsquid"
              style={{ maxWidth: 500, width: '100%' }}
            />
            <img
              src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/AppointmentReminders_Logo.png"
              alt="Appointment Reminders"
              style={{ maxWidth: 500, width: '100%' }}
            />

            {errorMsg && (
              <div style={{ color: 'red', fontSize: '16px', fontWeight: 500 }}>{errorMsg}</div>
            )}
            {message && (
              <div style={{ color: 'green', fontSize: '16px', fontWeight: 500 }}>{message}</div>
            )}

            <button
              type="button"
              style={{
                width: '100%',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                backgroundColor: '#2985c9',
                boxShadow: 'none',
                color: '#ffffff',
                padding: '8px 12px',
                fontSize: '15px',
                lineHeight: 1.4,
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => navigate('/')}
            >
              Sign In with your Brightsquid Account
            </button>
          </div>
        </div>
      </div>
      <AppFooter />
    </Container>
  );
}
