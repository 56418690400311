import * as React from 'react';

import {
  DoubleArrowOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSnackbar } from 'notistack';
import AppContext from '../context/AppContext';
import {
  EmrDeclineAppointmentType,
  EmrInstanceType,
  useAppointmentStatusesQuery,
  useConfigurationQuery,
  useProvidersQuery,
  useUpdateConfigurationMutation,
} from '../generated/graphql';
import { Loading } from './Loading';

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function Settings() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = React.useContext(AppContext);
  const { refetch: refetchProviders } = useProvidersQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
  });
  const { data: configuration, refetch: refetchConfiguration } = useConfigurationQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
    onCompleted: () => {
      if (configuration?.configuration?.emrDeclineAppointmentConfiguration) {
        setPatientDeclineType(
          configuration?.configuration?.emrDeclineAppointmentConfiguration?.type!,
        );
        setPatientDeclineResponseStatus(
          configuration?.configuration?.emrDeclineAppointmentConfiguration?.status!,
        );
        setIsDeclineEnabled(
          configuration?.configuration?.emrDeclineAppointmentConfiguration
            ?.isPatientDeclineEnabled!,
        );
      }
      setLeft(
        not(
          configuration?.configuration?.providersPayLoad?.allProviders?.map(
            (provider) => provider!.id,
          ) || [],
          configuration?.configuration?.providersPayLoad?.configuredProviders?.map(
            (provider) => provider!.id,
          ) || [],
        ),
      );
      setRight(
        configuration?.configuration?.providersPayLoad?.configuredProviders?.map(
          (provider) => provider!.id,
        ) || [],
      );
    },
    onError: () =>
      enqueueSnackbar('Error while loading clinic settings, Please try again or contact support.', {
        variant: 'error',
      }),
  });

  const [updateConfiguration] = useUpdateConfigurationMutation({
    onCompleted: () => {
      enqueueSnackbar('Clinic settings updated.');
      refetchConfiguration();
      refetchProviders();
    },
  });

  const { data: statuses } = useAppointmentStatusesQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
    onError: () =>
      enqueueSnackbar('Error while loading statuses, Please try again or contact support.', {
        variant: 'error',
      }),
  });

  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState<string[]>([]);
  const [right, setRight] = React.useState<string[]>([]);
  const [patientDeclineType, setPatientDeclineType] = React.useState<EmrDeclineAppointmentType>(
    EmrDeclineAppointmentType.NoCancel,
  );
  const [isDeclineEnabled, setIsDeclineEnabled] = React.useState<boolean>(true);
  const [patientDeclineResponseStatus, setPatientDeclineResponseStatus] =
    React.useState<string>('Cancelled');

  const emrInstanceType = currentUser.activeClinic?.emrInstance?.instanceType;

  if (
    !configuration?.configuration ||
    !configuration?.configuration?.providersPayLoad?.configuredProviders
  ) {
    return <Loading message={'Loading providers...'} />;
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: string[]) => (
    <Paper style={{ width: 300, height: 400, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{}}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  configuration?.configuration?.providersPayLoad?.allProviders?.find(
                    (p) => p?.id === value,
                  )?.descriptor
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Box width={'100%'}>
      <Typography component="h1" variant="h4" align="left" style={{ marginBottom: 16 }}>
        Clinic Settings
      </Typography>
      <Card variant="outlined">
        <Box margin={5}>
          <Typography variant="h5">Providers</Typography>
          <Typography paragraph>
            <br />
            Select from the available providers in your EMR to configure in Appointment Reminders
            and display in Upcoming Appointments.
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography paragraph>
                <b>Available Providers in EMR</b>
              </Typography>
              {customList(left)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <IconButton
                  size="small"
                  title="Move all right"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                >
                  <DoubleArrowOutlined />
                </IconButton>
                <IconButton
                  title="Move selected right"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                >
                  <KeyboardArrowRightOutlined />
                </IconButton>
                <IconButton
                  title="Move selected left"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                >
                  <KeyboardArrowLeftOutlined />
                </IconButton>
                <IconButton
                  title="Move all left"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                >
                  <DoubleArrowOutlined style={{ transform: 'rotateY(180deg)' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Typography paragraph>
                <b>Selected Providers</b>
              </Typography>
              {customList(right)}
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br />
      <Card variant="outlined">
        <Box margin={5}>
          <Typography variant="h5">Patient Behaviour</Typography>
          <Typography paragraph>
            <br />
            <Checkbox
              checked={isDeclineEnabled}
              onChange={(e) => setIsDeclineEnabled(e.target.checked)}
            ></Checkbox>
            Allow patients to decline appointments online through the appointment reminder
          </Typography>
          <br />
          {isDeclineEnabled && (
            <>
              <Typography paragraph>
                <b>When patient declines an appointment:</b>
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <RadioGroup
                  value={patientDeclineType}
                  onChange={(e) =>
                    setPatientDeclineType(e.target.value as EmrDeclineAppointmentType)
                  }
                >
                  <FormControlLabel
                    value={EmrDeclineAppointmentType.NoCancel}
                    control={<Radio />}
                    label="Set Patient Response in Upcoming Appointments view to Declined and send notification my clinic via Secure-Mail. This option will not update the appointment status in the EMR."
                  />
                  <FormControlLabel
                    value={EmrDeclineAppointmentType.CancelInEmr}
                    control={<Radio />}
                    label="Set appointment status in EMR to Cancel and appointment is automatically removed from the EMR day sheet. Send notification to my clinic via Secure-Mail."
                  />

                  {emrInstanceType === EmrInstanceType.Ma && (
                    <div>
                      {' '}
                      <FormControlLabel
                        value={EmrDeclineAppointmentType.UpdateStateInEmr}
                        control={<Radio style={{ marginTop: '-22px' }} />}
                        label={
                          <div>
                            <Typography>
                              Set appointment status in EMR to{' '}
                              <Select
                                variant="outlined"
                                margin="dense"
                                color="primary"
                                id="provider"
                                native
                                required
                                value={patientDeclineResponseStatus}
                                onChange={(e: any) => {
                                  const providerId = e.target.value as string;
                                  setPatientDeclineResponseStatus(providerId);
                                }}
                              >
                                {statuses?.appointmentStatuses?.map((appointmentStatus, i) => (
                                  <option key={i} value={appointmentStatus?.status!}>
                                    {appointmentStatus?.status}
                                  </option>
                                ))}
                              </Select>{' '}
                              and send notification to my clinic via Secure-Mail.
                              <br />
                              NOTE: When choosing a custom status ensure that the status in your EMR
                              is appropriate for patient&apos;s indication they wish to decline
                              their appointment time.
                            </Typography>
                          </div>
                        }
                      />
                    </div>
                  )}
                </RadioGroup>
              </Grid>
            </>
          )}
        </Box>
      </Card>
      <Box display={'flex'} justifyContent="space-between" mb={4} pt={5}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            updateConfiguration({
              variables: {
                providerIds: right,
                emrDeclineAppointmentType: patientDeclineType,
                status: patientDeclineResponseStatus,
                isPatientDeclineEnabled: isDeclineEnabled,
              },
            });
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
