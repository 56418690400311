import { CancelScheduleSend, CancelSharp, CloudDownload, Done, Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import { useState } from 'react';
import {
  EmrDeclineAppointmentType,
  PatientAppointment,
  PatientAppointmentResponse,
  useDownloadUrlLazyQuery,
} from '../../generated/graphql';
import CommonUtils from '../../util/CommonUtils';
import AddToCalendarButton from './AddToCalendarButton';
import AppointmentWidget from './AppointmentWidget';
import DeclineAppointmentModal from './DeclineAppointmentModal';
import RequestInvite from './RequestInvite';

const styles = {
  paper: {
    marginTop: 2,
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  green: {
    background: '#43a047',
    color: '#ffffff',
  },
  red: {
    background: '#c62828',
    color: '#ffffff',
  },
  yellow: {
    background: '#ffeb3b',
    color: '#000',
  },
  appointmentStatus: {
    fontWeight: 200,
  },
  floatRight: {
    float: 'right',
  },
  mr10: {
    marginRight: '10px',
  },
};

function AppointmentPage({
  patientAppointment,
  confirmAppointment,
  cancelAppointment,
}: {
  patientAppointment: PatientAppointment;
  confirmAppointment: Function;
  cancelAppointment: Function;
}) {
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [downloadUrl] = useDownloadUrlLazyQuery({
    onCompleted: (res) => {
      CommonUtils.downloadUrl(res?.downloadUrl!);
    },
  });
  const {
    patientAppointmentResponse,
    clinic,
    securedContent,
    status,
    attachments,
    startDate,
    isPatientDeclineEnabled,
    emrDeclineAppointmentType,
  } = patientAppointment;

  const isDisabled = new Date(startDate?.dateTime) < new Date();

  let statusComponent = null;

  if (isDisabled) {
    statusComponent = (
      <Paper sx={{ ...styles.paper, ...styles.yellow }}>
        <Box display="inline-flex" alignItems="flex-end">
          <Warning />
          <Box ml={1}>
            <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
              {patientAppointment?.patient?.firstName}, your appointment date has already passed.
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  } else if (!patientAppointmentResponse && status !== 'Confirmed') {
    statusComponent = (
      <Paper sx={{ ...styles.paper, ...styles.yellow }}>
        <Box display="inline-flex" alignItems="flex-end">
          <Warning />
          <Box ml={1}>
            <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
              {patientAppointment?.patient?.firstName}, please confirm your appointment.{' '}
              {!isPatientDeclineEnabled &&
                ' Please call the clinic to cancel or reschedule your appointment.'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  } else if (
    (patientAppointmentResponse === PatientAppointmentResponse.Declined &&
      emrDeclineAppointmentType === EmrDeclineAppointmentType.UpdateStateInEmr) ||
    (status && 'Cancelled' === status)
  ) {
    statusComponent = (
      <Paper sx={{ ...styles.paper, ...styles.red }}>
        <Box display="inline-flex" alignItems="flex-end">
          <CancelSharp />
          <Box ml={1}>
            <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
              {patientAppointment?.patient?.firstName}, your appointment has been cancelled. Please
              call the clinic to rebook your appointment.
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  } else if (
    patientAppointmentResponse &&
    'DECLINED' === patientAppointmentResponse &&
    (emrDeclineAppointmentType === null ||
      emrDeclineAppointmentType === EmrDeclineAppointmentType.NoCancel) &&
    (status === null || (status && 'Cancelled' !== status))
  ) {
    statusComponent = (
      <Paper sx={{ ...styles.paper, ...styles.yellow }}>
        <Box display="inline-flex" alignItems="flex-end">
          <CancelScheduleSend />
          <Box ml={1}>
            <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
              {patientAppointment?.patient?.firstName}, your request to cancel the appointment has
              been sent to the clinic.
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  } else if (
    (patientAppointmentResponse && 'CONFIRMED' === patientAppointmentResponse) ||
    (!patientAppointmentResponse && status === 'Confirmed')
  ) {
    statusComponent = (
      <Paper sx={{ ...styles.paper, ...styles.green }}>
        <Box display="inline-flex" alignItems="flex-end">
          <Done />
          <Box ml={1}>
            <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
              {patientAppointment?.patient?.firstName}, your appointment has been confirmed.{' '}
              {!isPatientDeclineEnabled &&
                ' Please call the clinic to cancel or reschedule your appointment.'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      {statusComponent}
      <Paper sx={styles.paper}>
        <Box ml={0}>
          <AppointmentWidget appointment={patientAppointment} />
        </Box>
        <br />
        <Divider />
        <br />
        {'Cancelled' !== status &&
          !(
            patientAppointmentResponse === PatientAppointmentResponse.Declined &&
            emrDeclineAppointmentType === EmrDeclineAppointmentType.UpdateStateInEmr
          ) && (
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box display="inline-flex">
                  <Box>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={isDisabled}
                      color={
                        'DECLINED' !== patientAppointmentResponse &&
                        ('CONFIRMED' === patientAppointmentResponse || 'Confirmed' === status)
                          ? 'primary'
                          : 'inherit'
                      }
                      onClick={() => {
                        confirmAppointment();
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                  {isPatientDeclineEnabled && (
                    <Box ml={1}>
                      <Button
                        variant="contained"
                        size="large"
                        disabled={isDisabled}
                        color={'DECLINED' === patientAppointmentResponse ? 'primary' : 'inherit'}
                        onClick={() => {
                          setShowDeclinePopup(true);
                        }}
                      >
                        Decline
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display="flex" flex={1} ml={1} flexDirection="row-reverse">
                  <AddToCalendarButton appointment={patientAppointment} />
                </Box>
              </Grid>
            </Grid>
          )}
        <br />
        <Divider />
        <br />
        {patientAppointment.covidScreening?.form?.url && (
          <Box mr={1}>
            <FormLabel sx={styles.mr10}>
              Please complete your COVID-19 Screening prior to your appointment
            </FormLabel>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={() => {
                window.open(patientAppointment.covidScreening?.form?.url!);
              }}
            >
              Start
            </Button>
          </Box>
        )}
      </Paper>
      <Paper sx={styles.paper}>
        <Grid>
          <Grid container>
            <Box width={'100%'} display={'flex'} justifyContent="space-between">
              <Typography variant="h6" component="h1" color="primary" style={{ flex: 1 }}>
                Your Secured Message from {clinic?.name}
              </Typography>
              <Box display="flex" pb={1} alignItems="flex-end" sx={styles.floatRight}>
                <Box mr={1}>
                  <Typography color="textSecondary">Powered by </Typography>
                </Box>
                <img
                  src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/brightsquid.png"
                  title="Brightsquid"
                />
              </Box>
            </Box>
          </Grid>
          <Divider />
          <br />
          <Typography variant="body1" component="h1" sx={styles.appointmentStatus}>
            {securedContent && <div dangerouslySetInnerHTML={{ __html: securedContent! }} />}
          </Typography>
        </Grid>
      </Paper>

      {attachments && attachments.length > 0 && (
        <Paper sx={styles.paper}>
          <Box p={1}>
            <Typography variant="h6" component="h1" color="primary" style={{ flex: 1 }}>
              Attachments
            </Typography>
          </Box>
          <Divider />

          {attachments.map((attachment) => (
            <Box
              display="flex"
              p={1}
              className="attachment"
              alignItems="center"
              key={attachment.id}
              borderBottom="1px solid rgba(0, 0, 0, 0.12)"
            >
              <IconButton
                color="primary"
                onClick={() => {
                  downloadUrl({
                    variables: {
                      attachmentId: Number(attachment.id),
                    },
                  });
                }}
              >
                <CloudDownload />
              </IconButton>
              <Box ml={1}>
                <Typography>{attachment.filename}</Typography>
              </Box>
            </Box>
          ))}
        </Paper>
      )}

      <RequestInvite
        userEmail={patientAppointment?.patient?.email || ''}
        clinicName={clinic?.name!}
      />
      <DeclineAppointmentModal
        open={showDeclinePopup}
        setOpen={setShowDeclinePopup}
        cancelAppointment={cancelAppointment}
      />
    </Container>
  );
}

export default AppointmentPage;
