import { Box, FormLabel, Typography } from '@mui/material';
import { PatientAppointment } from '../../generated/graphql';
import AppointmentDate from './AppointmentDate';
import AppointmentTime from './AppointmentTime';
export default function AppointmentWidget({ appointment }: { appointment: PatientAppointment }) {
  const { startDate, appointmentType, provider, concerns } = appointment;

  let appointmentTypeDesc = null;
  if (appointmentType) {
    appointmentTypeDesc = (
      <Typography variant="body1" component="span" gutterBottom>
        {appointmentType && `${appointmentType.descriptor}`}
        {appointmentType?.defaultDurationInMinutes &&
          ` - ${appointmentType.defaultDurationInMinutes} minutes`}
      </Typography>
    );
  }
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        {startDate?.date && <AppointmentDate date={new Date(startDate?.date)} />}
        <br />
        {startDate?.time && <AppointmentTime time={startDate?.time} />}
      </Box>
      <Box ml={4} display="flex">
        <Box>
          <Typography variant="h6">
            <FormLabel style={{ fontSize: '1.25rem' }}>Appointment With: </FormLabel>
            {provider && `${provider.descriptor}`}{' '}
          </Typography>
          {appointmentTypeDesc}
          <Box fontStyle="italic">
            <Typography variant="body1" component="span">
              {concerns}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
