import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router';
import { HTTP_LINK } from '../../ApolloLinks';
import PatientPage from './PatientPage';

export default function PatientPageApolloWrapper() {
  const { accessToken } = useParams<any>();
  const [dob, setDob] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (dob) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: `PanelPatient ${accessToken}:${dob}`,
        },
      }));

      return forward(operation);
    } else {
      return null;
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),

    uri: process.env.REACT_APP_PANEL_URI,

    link: authMiddleware
      .concat(
        onError((e) => {
          if (e.networkError?.message === 'Failed to fetch') {
            enqueueSnackbar(
              `There was an error reaching the application, please contact support or try again later.`,
              {
                variant: 'error',
                preventDuplicate: false,
                persist: true,
              },
            );
          }
        }),
      )
      .concat(HTTP_LINK),
  });

  return (
    <ApolloProvider client={client}>
      <PatientPage dob={dob} setDob={setDob} />
    </ApolloProvider>
  );
}
