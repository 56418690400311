import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';
import { format } from 'date-fns';

const DateCalendar = styled.div`
  display: inline-table;
  height: 80px;
  text-align: center;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
`;

const Month = styled.div`
  background: ${blue[600]};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px;
  font-size: 14px;
  font-weight: bold;
`;
const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6e6e6;
  font-size: 32px;
`;
const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-size: 14px;
  font-weight: bold;
`;

export default function DateWidget({ date }: { date: Date }) {
  return (
    <DateCalendar>
      <Month>{format(date, 'MMMM')}</Month>
      <Date>{format(date, 'd')}</Date>
      <Day>{format(date, 'EEEE')}</Day>
    </DateCalendar>
  );
}
