import { CloudDownloadRounded, ScheduleRounded, Visibility } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { differenceInYears, format } from 'date-fns';
import { useContext, useState } from 'react';
import AppContext from '../../context/AppContext';
import {
  PatientAppointment,
  PatientAppointmentResponse,
  usePatientAppointmentsByRangeQuery,
  useProvidersQuery,
} from '../../generated/graphql';
import CommonUtils from '../../util/CommonUtils';
import DashboardUtilities from '../../util/DashboardUtilities';
import AppointmentDetailModal from './AppointmentDetailModal';
const dateRangeOptions = DashboardUtilities.getFutureDateRangeFilter();

function UpcomingAppointments() {
  const UA_TIME_RANGE = 'UA_TIME_RANGE';
  const UA_PROVIDER_ID = 'UA_PROVIDER_ID';
  const LOCAL_STORAGE_KEY = 'upcoming_appts_columns';
  const { currentUser } = useContext(AppContext);

  const [page, setPage] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(
    localStorage.getItem(UA_TIME_RANGE) || 'Next 7 days',
  );

  const [search, setSearch] = useState('');
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [providerId, setProviderId] = useState<string | null>(null);
  const dateRangeOption = dateRangeOptions.find((r) => r.value === selectedDateRange);

  const { data: providersList, loading: providersLoading } = useProvidersQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
    onCompleted: (data) => {
      if (
        data.providers?.configuredProviders?.length &&
        data.providers?.configuredProviders[0]?.id
      ) {
        if (
          data.providers?.configuredProviders.find(
            (p) => p?.id === localStorage.getItem(UA_PROVIDER_ID),
          )
        ) {
          setProviderId(localStorage.getItem(UA_PROVIDER_ID));
        } else {
          setProviderId(data.providers?.configuredProviders[0].id);
        }
      } else if (data.providers?.allProviders?.length && data.providers?.allProviders[0]?.id) {
        if (
          data.providers?.allProviders.find((p) => p?.id === localStorage.getItem(UA_PROVIDER_ID))
        ) {
          setProviderId(localStorage.getItem(UA_PROVIDER_ID));
        } else {
          setProviderId(data.providers?.allProviders[0].id);
        }
      }
    },
  });

  const {
    data: patientAppointmentsResult,
    loading: patientAppointmentsLoading,
    refetch: refreshAppointments,
  } = usePatientAppointmentsByRangeQuery({
    variables: {
      from: dateRangeOption?.dateRange?.gte,
      to: dateRangeOption?.dateRange?.lt,
      providerId: providerId!,
      options: {},
    },
    fetchPolicy: 'no-cache',
    skip: !dateRangeOption || !providerId,
    onCompleted: () => setPage(0),
  });

  const selectedAppointment = patientAppointmentsResult?.patientAppointmentsByRange?.find(
    (a) => selectedAppointmentId === a?.appointmentId,
  );

  const renderCovidScreeningResult = (params: any) => {
    const submissionDate = params.row.covidScreeningSubmissionDate;
    const result = params.row.covidScreeningResult;
    const reportUrl = params.row.covidScreeningReportUrl;
    return (
      submissionDate && (
        <Chip
          color={Number(result) === 0 ? 'primary' : 'secondary'}
          label={`${Number(result) === 0 ? 'Passed' : 'Failed'} - ${format(
            new Date(submissionDate),
            'yyyy-MM-dd',
          )}`}
          onDelete={() => CommonUtils.downloadUrl(reportUrl!)}
          deleteIcon={<CloudDownloadRounded />}
        />
      )
    );
  };

  const renderPatientResponse = (params: any) => {
    const patientAppointmentResponse = params.row.patientAppointmentResponse;

    return (
      patientAppointmentResponse && (
        <>
          <Chip
            color={
              patientAppointmentResponse === PatientAppointmentResponse.Confirmed
                ? 'primary'
                : 'secondary'
            }
            label={patientAppointmentResponse}
          />
        </>
      )
    );
  };

  const scheduledStatusCell = (params: any) => {
    const futureReminders = params.row.futureReminders;
    const hasSmsOrEmail = params.row.hasSmsOrEmail;
    const status = params.row.status;

    if (status && status.includes('Cancelled')) {
      return null;
    }
    return (
      <>
        {hasSmsOrEmail ? (
          <>
            <Tooltip title={`Cannot Schedule with patient without sms/e-mail`} color={'secondary'}>
              <ScheduleRounded color="secondary" />
            </Tooltip>
            ,
          </>
        ) : (
          <>
            {futureReminders?.length && (
              <Tooltip
                title={`Scheduled to be sent at
              ${futureReminders[0].scheduledDate.date} ${futureReminders[0].scheduledDate.time}
              `}
              >
                <ScheduleRounded color="action" />
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  const renderViewDetailCell = (params: any) => {
    return (
      <>
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedAppointmentId(params.row.appointmentId);
          }}
        >
          <Visibility titleAccess="View Details" />
        </IconButton>
      </>
    );
  };
  const persistedDisabledColumnsList = localStorage.getItem(LOCAL_STORAGE_KEY);
  const disabledColumnsList = persistedDisabledColumnsList
    ? JSON.parse(persistedDisabledColumnsList)
    : {
        appointmentId: true,
        patientName: false,
        date: false,
        appointmentType: false,
        viewedDateTime: false,
        status: false,
        patientAppointmentResponse: false,
        covidScreening: false,
        scheduled: false,
        view: false,
      };

  const columns: GridColDef[] = [
    {
      field: 'appointmentId',
      headerName: 'Appointment Id',
      hide: disabledColumnsList.appointmentId,
      flex: 1,
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      width: 200,
      hide: disabledColumnsList.patientName,
    },
    { field: 'date', headerName: 'Time', width: 250, hide: disabledColumnsList.date },
    {
      field: 'appointmentType',
      headerName: 'Type',
      flex: 1,
      hide: disabledColumnsList.appointmentType,
    },
    {
      field: 'viewedDateTime',
      headerName: 'Viewed Details',
      width: 175,
      hide: disabledColumnsList.viewedDateTime,
    },
    { field: 'status', headerName: 'Status', width: 150, hide: disabledColumnsList.status },
    {
      field: 'patientAppointmentResponse',
      headerName: 'Patient Response',
      hide: disabledColumnsList.patientAppointmentResponse,
      renderCell: renderPatientResponse,
      flex: 1,
    },
    {
      field: 'covidScreening',
      headerName: 'Covid Screening',
      width: 175,
      hide: disabledColumnsList.covidScreening,
      renderCell: renderCovidScreeningResult,
    },
    {
      field: 'scheduled',
      headerName: ' ',
      width: 60,
      hide: disabledColumnsList.scheduled,
      renderCell: scheduledStatusCell,
    },
    {
      field: 'view',
      headerName: ' ',
      width: 60,
      hide: disabledColumnsList.view,
      renderCell: renderViewDetailCell,
    },
  ];

  let rows =
    patientAppointmentsResult?.patientAppointmentsByRange?.map((r: any) => {
      const {
        appointmentId,
        startDate,
        status,
        appointmentType,
        patient,
        provider,
        patientAppointmentResponse,
        viewedDateTime,
        id,
        covidScreening,
        futureReminders,
      } = r;

      let patientName = '';
      if (patient) {
        patientName = `${patient.firstName} ${patient.lastName} (${
          patient.birthDate && differenceInYears(new Date(), new Date(patient.birthDate))
        }Y/${patient.gender})`;
      }

      let covidScreeningSubmissionDate, covidScreeningResult, covidScreeningReportUrl;

      if (covidScreening?.form?.submissionDate) {
        const { submissionDate, result, reportUrl } = covidScreening?.form;
        covidScreeningSubmissionDate = format(new Date(submissionDate), 'yyyy-MM-dd');
        covidScreeningResult = result;
        covidScreeningReportUrl = reportUrl;
      }

      return {
        id: appointmentId,
        appointmentId: appointmentId,
        internalId: id,
        date: `${startDate.date} ${startDate.time}`,
        patientName,
        providerName: provider?.descriptor,
        appointmentType: appointmentType?.descriptor,
        viewedDateTime: viewedDateTime && format(new Date(viewedDateTime), 'yyyy-MM-dd HH:mm a'),
        status,
        patientAppointmentResponse,
        covidScreeningSubmissionDate,
        covidScreeningResult,
        covidScreeningReportUrl,
        futureReminders: futureReminders,
        hasSmsOrEmail: !patient?.email && !patient?.sms,
      };
    }) || [];

  if (search) {
    rows = rows.filter((row) => {
      for (const v of Object.values(row)) {
        if (v?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return true;
        }
      }

      return false;
    });
  }

  let providers;

  if (providersList?.providers?.configuredProviders?.length) {
    providers = providersList?.providers?.configuredProviders;
  } else {
    providers = providersList?.providers?.allProviders;
  }

  return (
    <Box width={'100%'}>
      <Typography component="h1" variant="h4" align="left" style={{ marginBottom: 16 }}>
        Upcoming Appointments
      </Typography>
      <Box display="flex" mt={1} mb={1}>
        <Box flex="1" display="flex" style={{ gap: 5 }}>
          <Select
            variant="outlined"
            margin="dense"
            color="primary"
            id="provider"
            native
            required
            value={providerId}
            onChange={(e: any) => {
              const providerId = e.target.value as string;
              localStorage.setItem(UA_PROVIDER_ID, providerId);
              setProviderId(providerId);
            }}
          >
            {providers?.map((provider, i) => (
              <option key={i} value={provider?.id}>
                {provider?.descriptor}
              </option>
            ))}
          </Select>

          <Select
            variant="outlined"
            margin="dense"
            color="primary"
            autoWidth
            value={selectedDateRange}
            onChange={(e) => {
              const id = e.target.value as string;
              localStorage.setItem(UA_TIME_RANGE, id);
              setSelectedDateRange(id);
            }}
          >
            {dateRangeOptions.map((o, i) => {
              return (
                <MenuItem key={i} value={o.value}>
                  {o.value}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <TextField
          type="search"
          margin="none"
          size="small"
          placeholder="Search..."
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <DataGrid
          getRowClassName={(params) => params.row.patientAppointmentResponse?.toString() || ''}
          page={page}
          onPageChange={(param) => setPage(param)}
          rows={patientAppointmentsLoading ? [] : rows}
          columns={columns}
          autoHeight
          getRowId={(row) => row.appointmentId}
          loading={patientAppointmentsLoading || providersLoading}
          onColumnVisibilityChange={(model) => {
            disabledColumnsList[model.colDef.field] = model.colDef.hide;
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(disabledColumnsList));
          }}
        />
      </Box>

      <AppointmentDetailModal
        appointment={selectedAppointment as PatientAppointment}
        onHide={() => {
          setSelectedAppointmentId(null);
        }}
        refreshAppointments={refreshAppointments}
      />
    </Box>
  );
}

export default UpcomingAppointments;
