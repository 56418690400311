import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Maybe } from 'graphql/jsutils/Maybe';
import { startCase } from 'lodash';
import AppContext from '../../context/AppContext';
import {
  AppointmentPanelState,
  AppointmentSource,
  AttachmentInput,
  CreateAppointmentPanelInput,
  EmrInstanceType,
  MessageTemplateType,
  NotificationChannel,
  Provider,
  useAppointmentPanelByIdQuery,
  useAppointmentTypesQuery,
  useCreateAppointmentPanelMutation,
  useMessageTemplatesQuery,
  useProvidersQuery,
  useUpdateAppointmentPanelMutation,
} from '../../generated/graphql';
import FileUploader from '../attachments/FileUploader';
import { Loading } from '../Loading';

function AddAppointmentReminder() {
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useContext(AppContext);

  const [searchParams] = useSearchParams();
  const cloneId = searchParams.get('clone');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue } = useForm();

  const [createAppointmentPanel] = useCreateAppointmentPanelMutation();
  const [updateAppointmentPanel] = useUpdateAppointmentPanelMutation();
  const { data: providersList } = useProvidersQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
  });
  const { data: appointmentTypesList } = useAppointmentTypesQuery({
    variables: {
      source: currentUser.activeClinic?.appointmentSource!,
    },
  });
  const { data: templateList } = useMessageTemplatesQuery();
  const { data: appointmentPanel, refetch } = useAppointmentPanelByIdQuery({
    variables: {
      id: id!,
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  useEffect(() => {
    if (cloneId) {
      refetch({ id: cloneId }).then((res) => {
        const clonedPanel = res.data?.appointmentPanelById;
        if (clonedPanel) {
          const clonedData = {
            name: ``,
            description: '',
            state: 'INACTIVE',
            appointmentSource: clonedPanel.appointmentSource,
            appointmentTypeIds: [],
            providerIds: [],
            appointmentConfirmation: clonedPanel.appointmentConfirmation,
            reminderInDays: clonedPanel.reminderInDays,
            emailMessageTemplateId: clonedPanel.emailMessageTemplate?.id,
            confirmEmailMessageTemplateId: clonedPanel.confirmEmailMessageTemplate?.id,
            smsMessageTemplateId: clonedPanel.smsMessageTemplate?.id,
            confirmSmsMessageTemplateId: clonedPanel.confirmSmsMessageTemplate?.id,
            securedMessageTemplateId: clonedPanel.securedMessageTemplate?.id,
            notificationPriority: clonedPanel.notificationPriority,
            covidScreening: clonedPanel.covidScreening,
            attachments: clonedPanel.attachments,
            immediateReminder: clonedPanel.immediateReminder,
          } as CreateAppointmentPanelInput;

          setValue('state', 'INACTIVE');
          setValue('appointmentSource', clonedPanel.appointmentSource);
          setValue('appointmentConfirmation', clonedPanel.appointmentConfirmation);
          setValue('reminderInDays', clonedPanel.reminderInDays);
          setValue('emailMessageTemplateId', clonedPanel.emailMessageTemplate?.id);
          setValue('confirmEmailMessageTemplateId', clonedPanel.confirmEmailMessageTemplate?.id);
          setValue('smsMessageTemplateId', clonedPanel.smsMessageTemplate?.id);
          setValue('confirmSmsMessageTemplateId', clonedPanel.confirmSmsMessageTemplate?.id);
          setValue('securedMessageTemplateId', clonedPanel.securedMessageTemplate?.id);
          setValue('notificationPriority', clonedPanel.notificationPriority);
          setValue('covidScreening', clonedPanel.covidScreening);
          setValue('attachments', clonedPanel.attachments);
          setValue('immediateReminder', clonedPanel.immediateReminder);
        }
      });
    }
  }, [cloneId, appointmentPanel, setValue, refetch]);

  useEffect(() => {
    if (appointmentPanel) {
      const {
        name,
        description,
        state,
        appointmentSource,
        appointmentTypeIds,
        providerIds,
        appointmentConfirmation,
        reminderInDays,
        emailMessageTemplate,
        confirmEmailMessageTemplate,
        securedMessageTemplate,
        smsMessageTemplate,
        confirmSmsMessageTemplate,
        notificationPriority,
        covidScreening,
        attachments,
        immediateReminder,
      } = appointmentPanel.appointmentPanelById!;

      reset({
        name,
        description,
        state,
        appointmentSource,
        appointmentTypeIds,
        providerIds,
        appointmentConfirmation,
        reminderInDays,
        emailMessageTemplateId: Number(emailMessageTemplate?.id),
        confirmEmailMessageTemplateId: Number(confirmEmailMessageTemplate?.id),
        smsMessageTemplateId: Number(smsMessageTemplate?.id),
        confirmSmsMessageTemplateId: Number(confirmSmsMessageTemplate?.id),
        securedMessageTemplateId: Number(securedMessageTemplate?.id),
        notificationPriority,
        covidScreening,
        attachments,
        immediateReminder,
      });
    }
  }, [appointmentPanel, reset, appointmentTypesList, providersList]);

  const onSubmit = async (data: any) => {
    data.appointmentSource = currentUser.activeClinic?.appointmentSource!;
    data.attachments = data.attachments.map((a: AttachmentInput) => {
      const { uuid, filename, mimeType, size } = a;
      return { uuid, filename, mimeType, size };
    });
    data.appointmentConfirmation = true;
    if (id) {
      const {
        name,
        description,
        state,
        reminderInDays,
        appointmentConfirmation,
        emailMessageTemplateId,
        confirmEmailMessageTemplateId,
        smsMessageTemplateId,
        confirmSmsMessageTemplateId,
        securedMessageTemplateId,
        notificationPriority,
        covidScreening,
        attachments,
        immediateReminder,
      } = data;
      updateAppointmentPanel({
        variables: {
          input: {
            id,
            name,
            description,
            state,
            reminderInDays,
            appointmentConfirmation,
            emailMessageTemplateId,
            confirmEmailMessageTemplateId,
            smsMessageTemplateId,
            confirmSmsMessageTemplateId,
            securedMessageTemplateId,
            notificationPriority,
            covidScreening,
            attachments,
            immediateReminder,
          },
        },
      })
        .then(() => {
          enqueueSnackbar('Panel updated successfully.');
          navigate('/');
        })
        .catch(() => {
          enqueueSnackbar('Error updating launcher, please try again or contact support.', {
            variant: 'error',
          });
        });
    } else {
      createAppointmentPanel({
        variables: {
          input: data,
        },
      })
        .then(() => {
          enqueueSnackbar('Panel created successfully.');
          navigate('/');
        })
        .catch((e) => {
          enqueueSnackbar(
            e?.message ||
              'Error creating Appointment Reminder, please try again or contact support.',
            {
              variant: 'error',
            },
          );
        });
    }
  };

  let appointmentStatusHelperText: string = '',
    emailHelperText: string = '',
    smsHelperText: string = '';

  const emrInstanceType = currentUser.activeClinic?.emrInstance?.instanceType;

  if (emrInstanceType === EmrInstanceType.Ma) {
    appointmentStatusHelperText =
      'With MedAccess appointment statuses in (Booked, Left Message, Email Reminder Sent, Confirmed)';
    emailHelperText = 'MedAccess "Personal - Email" field will be used to send email notifications';
    smsHelperText = 'MedAccess "Personal - Cell" field will be used to send SMS notifications';
  } else if (emrInstanceType === EmrInstanceType.Pss) {
    appointmentStatusHelperText = '';
    emailHelperText = 'PSS "Email" field will be used to send email notifications';
    smsHelperText = 'PSS "Mobile" field will be used to send SMS notifications';
  }

  let providers: Maybe<Provider>[] | undefined;

  if (providersList?.providers?.configuredProviders?.length) {
    providers = providersList?.providers?.configuredProviders;
  } else if (providersList?.providers?.allProviders?.length) {
    providers = providersList?.providers?.allProviders;
  }

  const smsEnabled = currentUser?.activeClinic?.appointmentSource !== AppointmentSource.Emd;

  return (
    <Box width={'100%'}>
      <Typography component="h1" variant="h5" align="center">
        Setup Appointment Reminder
      </Typography>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" width={'100%'} gap={1}>
          <Controller
            control={control}
            name="name"
            render={({ onChange, value }) => (
              <TextField
                required
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Name"
                InputLabelProps={{ shrink: true }}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ onChange, value }) => (
              <TextField
                required
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Description"
                InputLabelProps={{ shrink: true }}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="state"
            render={({ onChange, value }) => {
              return (
                <TextField
                  required
                  onChange={(e) => onChange(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                  label="State"
                  value={value || ''}
                  InputLabelProps={{ shrink: true }}
                >
                  {Object.values(AppointmentPanelState).map((state) => {
                    return (
                      <MenuItem key={state} value={state}>
                        {startCase(state?.toLowerCase())}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />
          <Typography component="h4" variant="h6" gutterBottom>
            Send reminders to patients
          </Typography>
          {appointmentStatusHelperText && (
            <Typography component="h6" variant="subtitle2" gutterBottom>
              {appointmentStatusHelperText}
            </Typography>
          )}
          <Box>
            <Typography component="h6" variant="subtitle2" gutterBottom>
              {appointmentStatusHelperText ? 'and' : ''} with appointment type(s) *
            </Typography>
            {appointmentPanel ? (
              <List
                style={{
                  outline: '1px solid #c0c0c0',
                  borderRadius: 4,
                  padding: 0,
                  marginTop: 8,
                }}
              >
                {appointmentTypesList?.appointmentTypes ? (
                  appointmentPanel.appointmentPanelById?.appointmentTypeIds?.map(
                    (id, index, arr) => {
                      return (
                        <ListItem
                          key={id}
                          style={{ padding: '4px 16px', margin: 0 }}
                          divider={index !== arr.length - 1}
                        >
                          <ListItemText>
                            {
                              appointmentTypesList?.appointmentTypes?.find((a) => a?.id === id)
                                ?.descriptor
                            }
                          </ListItemText>
                        </ListItem>
                      );
                    },
                  )
                ) : (
                  <Loading message=" " />
                )}
              </List>
            ) : (
              <Controller
                control={control}
                name="appointmentTypeIds"
                render={({ onChange, value }) => (
                  <Select
                    variant="outlined"
                    sx={{ width: '100%' }}
                    multiple
                    native
                    required
                    value={value?.map((v: string | null) => v || '')}
                    disabled={!!appointmentPanel}
                    onChange={(e: any) => {
                      let value = Array.from(e.target.selectedOptions, (option: any) =>
                        option.value === '' ? null : option.value,
                      );
                      onChange(value);
                    }}
                    fullWidth
                  >
                    {appointmentTypesList?.appointmentTypes && (
                      <option value="">No Appointment Type</option>
                    )}
                    {appointmentTypesList?.appointmentTypes?.map((appointmentType) => {
                      return (
                        <option key={appointmentType?.id} value={appointmentType?.id!}>
                          {appointmentType?.descriptor}
                        </option>
                      );
                    })}
                  </Select>
                )}
              />
            )}
          </Box>
          <Box>
            <Typography component="h6" variant="subtitle2" gutterBottom>
              and provider is *
            </Typography>
            {appointmentPanel ? (
              <List
                style={{
                  outline: '1px solid #c0c0c0',
                  borderRadius: 4,
                  padding: 0,
                  marginTop: 8,
                }}
              >
                {providersList?.providers?.allProviders ? (
                  appointmentPanel.appointmentPanelById?.providerIds?.map((id, index, arr) => {
                    return (
                      <ListItem
                        key={id}
                        style={{ padding: '4px 16px', margin: 0 }}
                        divider={index !== arr.length - 1}
                      >
                        <ListItemText>
                          {
                            providersList?.providers?.allProviders?.find((a) => a?.id === id)
                              ?.descriptor
                          }
                        </ListItemText>
                      </ListItem>
                    );
                  })
                ) : (
                  <Loading message=" " />
                )}
              </List>
            ) : (
              <Controller
                control={control}
                name="providerIds"
                render={({ onChange, value }) => (
                  <Select
                    variant="outlined"
                    sx={{ width: '100%' }}
                    multiple
                    id="provider"
                    native
                    required
                    value={value}
                    disabled={!!appointmentPanel}
                    onChange={(e: any) => {
                      let value = Array.from(
                        e.target.selectedOptions,
                        (option: any) => option.value,
                      );
                      onChange(value);
                    }}
                  >
                    {providers?.map((provider, i) => (
                      <option key={i} value={provider?.id}>
                        {provider?.descriptor}
                      </option>
                    ))}
                  </Select>
                )}
              />
            )}
          </Box>
          <Box>
            <Typography component="h6" variant="subtitle2" gutterBottom>
              When to Send Appointment Reminders (maximum 3 selections)*
            </Typography>
            <Controller
              control={control}
              name="reminderInDays"
              render={({ onChange, value }) => (
                <Select
                  variant="outlined"
                  sx={{ width: '100%' }}
                  multiple
                  id="reminderInDays"
                  required
                  native
                  value={value}
                  onChange={(event: any) => {
                    const { options } = event.target;

                    const selectedList = [];
                    for (let i = 0, l = options.length; i < l; i += 1) {
                      if (options[i].selected) {
                        selectedList.push(options[i].value);
                      }
                    }
                    if (selectedList.length > 3) {
                      onChange(value);
                    } else {
                      onChange(selectedList);
                    }
                  }}
                >
                  <option value="90" key="90">
                    3 months prior to appointment
                  </option>
                  <option value="60" key="60">
                    2 months prior to appointment
                  </option>
                  <option value="30" key="30">
                    1 month prior to appointment
                  </option>
                  <option value="21" key="21">
                    3 weeks prior to appointment
                  </option>
                  <option value="14" key="14">
                    2 weeks prior to appointment
                  </option>
                  <option value="7" key="7">
                    1 week prior to appointment
                  </option>
                  <option value="6" key="6">
                    6 days prior to appointment
                  </option>
                  <option value="5" key="5">
                    5 days prior to appointment
                  </option>
                  <option value="4" key="4">
                    4 days prior to appointment
                  </option>
                  <option value="3" key="3">
                    3 days prior to appointment
                  </option>
                  <option value="2" key="2">
                    2 days prior to appointment
                  </option>
                  <option value="1" key="1">
                    1 days prior to appointment
                  </option>
                </Select>
              )}
            />
          </Box>
          <Controller
            control={control}
            name="immediateReminder"
            render={({ onChange, value }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(value)}
                      onChange={(e) => onChange(e.target.checked)}
                      name="immediateReminder"
                    />
                  }
                  label="Send reminder immediately for appointments booked within 48 hours"
                />
              );
            }}
          />
          <Typography component="h4" variant="h6" gutterBottom>
            Message Templates
          </Typography>
          <Box>
            <Typography component="h4" variant="subtitle1">
              <strong>Confirmation requests</strong> - These templates will be sent to patients who
              have not yet confirmed or declined their appointment
            </Typography>
            <Controller
              control={control}
              name="emailMessageTemplateId"
              render={({ onChange, value }) => (
                <TextField
                  required
                  onChange={(e) => onChange(Number(e.target.value))}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                  label="Select Email Notification Template"
                  helperText={emailHelperText}
                  value={value || ''}
                >
                  {templateList?.messageTemplates
                    ?.filter((mt) => mt?.type === MessageTemplateType.Email)
                    .map((mt) => (
                      <MenuItem key={mt?.id!} value={mt?.id!}>
                        {`${mt?.name} [ Subject: ${mt?.subject} ]`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </Box>
          <Box>
            {smsEnabled && (
              <Controller
                control={control}
                name="smsMessageTemplateId"
                render={({ onChange, value }) => (
                  <TextField
                    required
                    onChange={(e) => onChange(Number(e.target.value))}
                    variant="outlined"
                    margin="normal"
                    select
                    fullWidth
                    label="Select SMS Notification Template"
                    value={value || ''}
                    helperText={smsHelperText}
                  >
                    {templateList?.messageTemplates
                      ?.filter((mt) => mt?.type === MessageTemplateType.Sms)
                      .map((mt) => (
                        <MenuItem key={mt?.id!} value={mt?.id!}>
                          {`${mt?.name}`}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            )}
          </Box>
          <Box>
            <Typography component="h4" variant="subtitle1">
              <strong>Reminders</strong> - These templates will be sent to patients who have
              confirmed their appointment
            </Typography>
            <Controller
              control={control}
              name="confirmEmailMessageTemplateId"
              render={({ onChange, value }) => (
                <TextField
                  required
                  onChange={(e) => onChange(Number(e.target.value))}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                  label="Select Email Notification Template"
                  helperText={emailHelperText}
                  value={value || ''}
                >
                  {templateList?.messageTemplates
                    ?.filter((mt) => mt?.type === MessageTemplateType.Email)
                    .map((mt) => (
                      <MenuItem key={mt?.id!} value={mt?.id!}>
                        {`${mt?.name} [ Subject: ${mt?.subject} ]`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </Box>
          <Box>
            {smsEnabled && (
              <Controller
                control={control}
                name="confirmSmsMessageTemplateId"
                render={({ onChange, value }) => (
                  <TextField
                    required
                    onChange={(e) => onChange(Number(e.target.value))}
                    variant="outlined"
                    margin="normal"
                    select
                    fullWidth
                    label="Select SMS Notification Template"
                    value={value || ''}
                    helperText={smsHelperText}
                  >
                    {templateList?.messageTemplates
                      ?.filter((mt) => mt?.type === MessageTemplateType.Sms)
                      .map((mt) => (
                        <MenuItem key={mt?.id!} value={mt?.id!}>
                          {`${mt?.name}`}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            )}
          </Box>
          <Box>
            <Typography component="h4" variant="h6">
              Secured Content Message
            </Typography>
            <Controller
              control={control}
              name="securedMessageTemplateId"
              render={({ onChange, value }) => (
                <TextField
                  required
                  onChange={(e) => onChange(Number(e.target.value))}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                  label="Select Secure Message Template"
                  value={value || ''}
                >
                  {templateList?.messageTemplates
                    ?.filter((mt) => mt?.type === MessageTemplateType.SecureContent)
                    .map((mt) => (
                      <MenuItem key={mt?.id!} value={mt?.id!}>
                        {`${mt?.name}`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </Box>
          {smsEnabled && (
            <Box>
              <Typography component="h4" variant="h6">
                Notification Priority
              </Typography>
              <Controller
                control={control}
                name="notificationPriority"
                render={({ onChange, value }) => (
                  <TextField
                    required
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    select
                    fullWidth
                    label="Notification Channel which is used if SMS and EMAIL both are available for the patient."
                    value={value || ''}
                  >
                    {Object.values(NotificationChannel).map((v) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
          )}
          <Box>
            <Typography component="h4" variant="h6">
              Patient Forms
            </Typography>
            <Controller
              control={control}
              name="covidScreening"
              render={({ onChange, value }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(value)}
                        onChange={(e) => onChange(e.target.checked)}
                        name="covidScreening"
                      />
                    }
                    label="Covid Screening"
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Typography component="h4" variant="h6">
              Attachments
            </Typography>
            <Controller
              control={control}
              name="attachments"
              render={({ onChange, value }) => {
                return <FileUploader value={value || []} onChange={onChange} readOnly={false} />;
              }}
            />
          </Box>
          <Box display={'flex'} gap={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: '24px',
                marginLeft: '8px',
              }}
            >
              {id ? 'UPDATE' : 'CREATE'}
            </Button>
            <Button
              variant="text"
              sx={{
                marginTop: '24px',
                marginLeft: '8px',
              }}
              onClick={() => {
                navigate('/appointment/list');
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default AddAppointmentReminder;
